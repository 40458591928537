<template>
    <div id="PorductP" ref="PorductP" :style="`background-image: url( ${bgImg} )`">
          <!-- 容器 -->
          <div class="wrap">
            <!-- 头部 -->
            <div class="hd">
                <h1 class="title">{{$t('swiperShow.productP.title')}}</h1>
                <div class="bm"></div>
                <div class="subtitle">Product advantages</div>
            </div>
  
            <!-- 主体 -->
            <div class="bd mobile-max-height">
                <ul class="products pubw">
                    <li class="new-item" v-for="item in pageData" :key="item.meritPageId" @click="handlerDetails(item)">
                        <div class="top">
                            <img :src="`${baseUrl + item.pageImg}`">
                        </div>
                        <div class="bottom">
                            <div class="border"></div>
                            <p class="title">{{item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']}}</p>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PorductP',
    components: {
    },
    data(){
      return {
        //   背景图片
        bgImg:process.env.BASE_URL + "bgImg/news.png",
        // 页面数据
        pageData:[]
      }
    },
    methods: {
                // 获取页面数据
        async getPageData(){
            const res = await this.$http.get("/web/merit/page")
            console.log(res, "产品优点窗帘页面数据")
            this.pageData = res.data
        },
        // 监听用户点击查看详情
        handlerDetails(data){
            this.$router.push({
                name: 'ProductPDetails',
                params:{pageData: [data]}
            })
        }
    },
    mounted() {

        // 修改nav页面背景
        this.$bus.$emit("setNavBg",this.bgImg)

    },
    // 生命周期==>创建完成
    created() {
        this.getPageData()
    },
  }
  </script>
  
  <style lang="less">
    #PorductP{
        width: 100%;
        height: 100%;
        color: #fff;
        background-color: black;
        background-repeat: no-repeat;
        background-size: cover;
        .wrap{
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            .hd{
                text-align: center;
                .title{
                font-size: .6rem;
                }
                .bm{
                    width: 2.725rem;
                    height: .1125rem;
                    background: @colorMain;
                    border-radius: .05rem;
                    margin: .0625rem auto;
                    margin-top: -0.1rem;
                }
                .subtitle{
                    color: #B3B3B3;
                    font-size: .225rem;
                    font-weight: 400;
                    color: rgba(179, 179, 179, 1);
            }
            }
            .bd{
                .flexb();
                margin-top: .875rem;
                height: 6.925rem;
                text-align: left;
                .products{
                    display: flex;
                    justify-content: space-between;
                    .new-item{
                        width: 3.75rem;
                        .heffect();
                        height: 470px;
                        .top{
                            height: 80%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .bottom{
                            position: relative;
                            padding: .1875rem;
                            height: 30%;
                            font-weight: 560;
                            .title{
                                position: relative;
                                font-size: .325rem;
                                padding-left: 2px;
                                text-align: left;
                                line-height: 30px;
                                &::before{
                                    content: '';
                                    display: block;
                                    height: .375rem;
                                    width: .0625rem;
                                    left: -14px;
                                    position: absolute;
                                    background-color: @colorMain;
                                    border-radius: 6px;
                                    width: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mask-right,.mask-left{
            position: fixed;
            top: 0;
            height: 100%;
            z-index: 20;
            width: 1.875rem;
        }
    }
  </style>
  