<template>
    <div id="Aboutus">
      <div class="banner" id="banner" ref="banner">
        <div class="imgMask"></div>
        <!-- <img src="../../../../public/bgImg/details.png" alt=""> -->
        <video id="video" :src="`${baseUrl + pageData.video}`"></video>
        <div class="options" :class=" !pageLazy ? 'active':'passive'">
            <img src="../../../assets/imgs/play.png" alt="play" @click="playVideo()" v-if="videoStatus == 'paused'">
            <img src="../../../assets/imgs/pause.png" alt="pause" @click="pauseVideo()" v-else-if="videoStatus == 'playing'">
            <img src="../../../assets/imgs/replay.png" alt="replay"  @click="replayVideo()" v-else-if="videoStatus == 'ended'">
        </div>
    </div>
        <!-- 容器 -->
        <div class="wrap pubw ">
  
            <!-- 主体 -->
            <div class="bd">
                <ul>
                    <li v-for="(item, index) in pageData.tdCompanyPages" :key="index">
                        <div class="title">{{item[_i18n.locale == 'en' ? 'titleEn' : 'titleCn']}}</div>
                        <div class="html render-html" v-html="item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
  name: 'Aboutus',
  data () {
    return {
        // 页面数据
        pageData: {},
        // 视频状态
        videoStatus: 'paused',
        // 页面状态
        pageLazy: false,
    }
  },
  methods: {
    async getPageData(){
        const res = await this.$http.get('/web/company')
        this.pageData = res.data
        console.log(res, '关于公司页面数据')
    },
     // 播放视频
     playVideo(){
        this.videoStatus = 'playing'
        document.getElementById("video").play()
    },
    // 暂停视频
    pauseVideo(){
        this.videoStatus = 'paused'
        document.getElementById("video").pause()
    },
    // 重播视频
    replayVideo(){
        document.getElementById("video").load()
        document.getElementById("video").play()
        this.videoStatus = 'playing'
    },
    // 监听视频播放完毕
    videoEnded(){
        console.log('播放完毕')
        this.videoStatus = 'ended'
    },
     // 监听鼠标滑动改变页面展示状态
     handlerMouseMove(el){
            let valve = false
            let tiemr = undefined 
            let that = this
            function pageAction(){
              if(valve) return
                valve = true
                that.pageLazy = false
                setTimeout(() => {
                    that.pageLazy = false
                    valve = false
                    tiemr = setTimeout(()=>{
                      if(!that.pageLazy){
                        that.pageLazy = true
                      }
                    },1000)
                },1000)
            }
            (el || window).addEventListener('mousemove', pageAction)
            if(this.isMobile){ 
              window.addEventListener('touchstart', pageAction)
            }
          },
  },
    created() {
      this.getPageData()
    },
    mounted() {
        console.log(this.$refs.banner)
        
        this.handlerMouseMove(this.$refs.banner)
    },
    // 生命周期销毁了
    beforeDestroy() {
    },
  }
  </script>
  
  <style lang="less">
    #Aboutus{
      padding-bottom: 150px;
      overflow-x: hidden;
        .banner{
              position: relative;
              width: 100%;
              height: 800px;
              background-repeat: no-repeat;
              img{
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
              }
              video{
                width: 100%;
                height: 100%;
                background: #000000;
              }
              .options{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    img{
                        width: 1.25rem;
                        height: 1.25rem;
                        .heffect();
                    }
                }
                .options.active{
                    animation: show .6s forwards;
                }
                .options.passive{
                    animation: close .6s forwards;
                }
          }
        .wrap{
            padding-top: 0;
            .bd{
                margin-top:1.25rem;
                ul{
                    div.title{
                        position: relative;
                        font-size: 30px;
                        height: fit-content;
                        padding-left: 20px;
                        text-align: left;
                        margin-top: 55px;
                        &::before{
                            position: absolute;
                            left: 0px;
                            display: block;
                            content: "";
                            height: 80%;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 6px;
                            background-color: @colorMain;
                        }
                    }
                    .html{
                        text-align: left;
                        *{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
  </style>
  