// 中文简体文件


module.exports = {
    // 主页
    home: {

    },
    // 头部
    header: {
        navList: {
            shop: '在线商店',
            home: '首页',
            product: '产品',
            window: '橱窗',
            member: '会员中心'
        },
        navBlock: {
            freeMeasure: '免费上门度尺',
            productP: '产品优点',
            intellect: '智能窗帘',
            news:'最新消息',
            freeConsultion: '免费咨询'
        }
    },
    // 商城
    shop:{
        coupon: {
            label: '優惠券',
            success: '査詢成功',
            error: '無法査詢到此程式碼相關產品'
        },
        choice: {
            title: "在線選擇",
            tips: '圖片僅供參考，圖片因螢幕設定不同可能存在色差，以實際商品顏色為準。',
            options: {
                plan: '服務計劃',
                curenv: '參考環境',
                color: '選擇顏色'
            },
            tabel: {
                location: '安裝位置',
                type: '類型',
                color: '顏色',
                si: '產品規格'
            },
            palnNodata: '该服务计划无数据',
            planErr: '获取套餐信息失败',
            plsLogin: '請先登錄',
            noVr: '此产品暂无VR图片',
            addOrder: '確認訂單'
        },
        orderDetails: {
            title: '訂單詳情',
            install: {
                title: '安裝信息',
                name: '客戶姓名',
                phone: '聯係電話',
                email: '電子郵箱',
                address: '安裝地址',
                measureDate: '测量日期',
                installDate: '安裝日期',
                deyDate: '交付日期',
                plsInstallD: '請先選擇安装日期時間',
                plsDeyD: '請先選擇量測日期時間',    
                plsMeasureD: '請先選擇日期時間'
            },
            tabel: {
                location: '安裝位置',
                type: '類型',
                color: '顏色',
                si: '產品規格'
            },
            prevBtn: '返回',
            preBtn: '上一步',
            placeBtn: '立即付款',
            editBtn: '修改订单',
            paidBtn: '已付款',
            editDone: '修改成功！'
        },
    },
      // 会员中心
      member: {
        memberInfo: {
            title: '會員信息',
            name: '会员名稱',
            phone: '聯繫電話',
            email: ' 電子郵件',
            address: '默認地址',
            edit: '點擊修改',
            cpEdit: '確認修改'
        },
        orderList: {
            title: '訂單列表',
            orderNumber: '訂單號',
            staus0: '待支付',
            step0: '等待测量',
            step1: '等待交付',
            step2: '等待安装',
            step3: '订单完成'
        }
    },
    // 登录
    login: {
        title: '會員登陸',
        forget: '忘记密码',
        register: '注册',
        login: '登陸',
        emailErr: '請輸入正確的電郵格式!',
        psdErr: '請輸入正確的密碼'
    },
    // 忘记密码
    forgetPsd: {
        title: '會員登陸',
        forget: '忘记密码',
        register: '注册',
        email: '郵箱',
        login: '登陸',
        emailErr: '請輸入正確的郵箱',
        emailPls: '郵箱/手機號',
        psdPls: '密碼',
        prve: '返回',
        edit: '確認修改'
    },
    // 注册
    register: {
        title: '會員註冊',
        tips: '*感謝您使用我們的服務，請在下麵填您的信息。以便我們處理訂單。如已有帳號，請直接點擊會員登陸。',
        name: '姓名',
        phone: '电话',
        email: '邮箱',
        psd: '密码',
        comfirmPsd: '再次输入密码',
        address: '收回地址',
        prevBtn: '上一步',
        loginBtn: "會員登錄",
        registerBtn: '立即注册',
        nameErr: '请填写姓名',
        phoneErr: '請輸入正確的電話格式!',
        emailErr: '請輸入正確的電郵格式!',
        psdErr: '请填写密码',
        psdComfirmErr: '两次输入密码不一致',
        addressErr: '请填写收件地址',
        emailAlert: {
            tips: '我們已經向您的郵箱發送了一封郵件，請前往郵箱進行身份驗證。',
            cancel: '取消',
            complate: '我已確認'
        }
    },
    // 轮播图内容
    swiperShow: {
        banner: {
            detailsBtn: '點擊了解 '
        },
        measure: {
            title: '免費上門度尺服務',
            subTitle: 'Free on-site measuring ruler service',
            detailsBtn: '點擊參與',
            ruleLabel: '兌換規則',
            numberLabel: '剩餘名額'
        },
        intellect: {
            title: '智能窗簾'
        },
        productP: {
            title: '產品優點'
        },
        news: {
            title: '最新消息'
        },
        consultion: {
            title: '免費咨詢',
            tips: '您可以就與我們產品相關的任何事情與我們聯繫，我們會盡快給您回復。',
            name: '姓名',
            email: '電子郵箱',
            phone: '電話號碼',
            proposal: '您的建議',
            btn: '聯繫我們'
        }
    },
    // 产品
    product: {
        style: '產品樣式',
        product: {
            detailsBtn: '了解詳情',
            backBtn: '返回'
        }
    },
    // 橱窗
    window: {
        floorLabel: '樓盤名稱',
        roomLabel: '房間',
        btn: '确认'
    },
    // 子页面内容
    subPage: {
        measure: {
            title: '免費上門度尺服務',
            numberLabel: '剩餘名額',
            timeLabel: '活動時間',
            addressLabel: '兌換地點',
            ruleLabel: '兌換規則',
            emailLabel: '電子郵箱'
        },
        intellect: {
            title: '智能窗簾',
            btn: "點擊參與"
        },
        productP: {
            title: '產品優點',
        },
        fac: {
            program: {
                title: 'POWERVIEW® 應用程式',
                text: '為你準備的智能家居生活。透過應用程式，彈指間即可操作窗簾開合；亦可設定不同的場景模式及時間，窗飾會自動按時調節，簡單易用。',
                dowload: '下載PowerView應用程式',
                android: 'Android下載',
                ios: 'IOS下載'
            },
            room: {
                title: '覆蓋全屋',
                roomList: [
                    {
                        id: '0',
                        titleEn: 'PowerView® wireless extender',
                        title: 'PowerView® 無線伸延器',
                        descriptionEn: 'The small signal enhancer can strengthen the signal sent by the main control box to ensure that the commands issued by the main control box can be transmitted to the whole house smoothly.',
                        description: '細小的訊號加強器，可加強總控盒發出的訊號，確保總控盒發出的指令能順利傳送至全屋。'
                    },
                    {
                        id: '1',
                        titleEn: 'Pebble® and Surface scene system',
                        title: 'Pebble®及Surface場景制',
                        descriptionEn: 'With the use of PowerView® master control box and applications, you can quickly select and activate your preset scenes. Scene system can choose portable Pebble® or wall-mounted',
                        description: '配合PowerView®總控盒及應用程式使用，可快速選擇及啟用你已預設的場景。場景制可選擇手提式的Pebble®或安裝於牆身的'
                    },
                    {
                        id: '2',
                        titleEn: 'PowerView® App application',
                        title: 'PowerView® App 應用',
                        descriptionEn: 'The PowerView application gives full play to the advanced functions of this system. Through your mobile phone or tablet, you can operate single or whole house curtains, preset scenes and time at your fingertips',
                        description: 'PowerView應用程式將此系統的先進功能完全發揮。透過手機或平板電腦，彈指間即可操作單一或全屋窗簾、預設場景及時間，甚至利'
                    },
                    {
                        id: '3',
                        titleEn: 'Pebble® and Surface remote control',
                        title: 'Pebble®及Surface 遙控器',
                        descriptionEn: 'The remote control can be equipped with a portable shell or installed on the wall. The design is simple and easy to use. Just tap the remote control to control the opening and closing of the curtains and adjust the angle of the curtains. You can also control one or a group of curtains as needed.',
                        description: '遙控器可配手提式外殼或安裝於牆上，設計簡單易用，只需輕按遙控器，即可控制窗簾開合和調校簾片角度，更可按需要操控一幅或一組窗簾。'
                    },
                    {
                        id: '4',
                        titleEn: 'PowerView® Master Control Box',
                        title: 'PowerView® 總控盒',
                        descriptionEn: 'As the central control point of the whole house PowerView window decoration, the master control box will connect to the WiFi network to store the settings of each curtain and activate the scene mode to help you remotely control the whole house curtain.',
                        description: '作為全屋PowerView窗飾的中央控制點，總控盒會連接WiFi網絡，儲存各幅窗簾的設定及啟動場景模式，助你遙控全屋窗簾。'
                    },
                ]
            },
            combaination: {
                title: '總控盒組合',
                text1: '新設計的總控盒可連接家中的WiFi網絡，作為全屋PowerView窗飾的中央控制點，儲存各幅窗簾的設定及啟動場景模式，助你遙',
                text2: '細小的訊號加強器，可加強總控盒發出的訊號，確保總控盒發出的指令能順利傳送至全屋。',
                text3: '連接網絡'
            },
            useInfo: {
                title: '使用說明',
                text1: '閱讀使用說明書，助你輕鬆為家中PowerView窗飾進行設定，你亦可觀看短片的示範說明。',
                text2: '使用說明：PowerView® Motorization Guides',
                text3: '常見問題：FAQ'
            },
            orther: {
                title: '兼容其他智能家居系統',
                text1: '兼容智能家居系統',
                text2: '兼容其他智能家居系統，令用戶享受一體化的優質智能家居體驗。',
                text3: '每個智能家居系統都有不同的整合方法，請參考使用說明及系統要求。',
                text4: '智能生活',
                text5: '智能電動系統，讓你可隨時透過應用程式、遙控器、甚至聲控*操作窗簾，輕鬆方便。',
                btn: '了解詳情'
            },
            curTain: {
                title: '選購心水窗飾',
                text: '聯絡特約經銷商，親身體驗各款窗飾產品的特點，揀選出最貼合你需要的窗簾。',
                btn1: '特約經銷商地址',
                btn2: 'PowerView小冊子'
            }
        },
        consultion: {
            plsName: '請輸入姓名',
            plsEmail: '請輸入郵箱',
            plsCEmauk: '請輸入正確的郵箱',
            plsPhone: '請輸入電話號碼',
            plsCPhone: '請輸入正確的手機號碼',
            plsIs: '請輸入您的建議'
        }
    },
    // 底部
    footer: [
        {
            id: '0',
            title: '關於我們',
            children: [
              {
                id: '0',
                text: '產品'
              },
              {
                id: '1',
                text: '關於公司'
              },
              {
                id: '2',
                text: '公司的管理'
              },
              {
                id: '3',
                text: '找經銷商'
              },
              {
                id: '4',
                text: '方針&使命'
              }
            ]
        },
        {
        id: '1',
        title: '資源',
        children: [
            {
            id: '0',
            text: 'Hunter Douglas集團'
            },
            {
            id: '1',
            text: 'Hunter Douglas亞洲酒店'
            },
            {
            id: '2',
            text: 'Hunter Douglas建築公司'
            },
            {
            id: '3',
            text: '設計師團隊'
            }
        ]
        },
        {
        id: '2',
        title: '聯繫我們',
        children: [
                {
                id: '0',
                text: 'Hunter Douglas櫥窗時裝'
                },
                {
                id: '1',
                text: '電話：（852）26358399'
                },
                {
                id: '2',
                text: '傳真：（852）26378611'
                },
                {
                id: '3',
                text: '電子郵件：service_hk@hunterdoungias.asia'
                },
                {
                id: '4',
                text: '方針&地址：九龍旺角大宇工業大廈12樓1123室'
                }
            ]
        }
    ],
    // 提示
    tips: {
        notTime: "未選擇開始時間或結束時間",
        pls: '请选择',
        to: '到',
        within: '以内的时间',
        vifitySus: "發送確認簡訊成功,請在半小時內確認",
        loginSus: "登入成功",
        infoErr: "獲取用戶資訊失敗",
        fristLogin: "请先登入",
        notFound: "該產品暫無詳細諮訊",
        registerSus: "注册成功",
        plsComplateDate: "請選擇完整日期"
    },
    date: {
        plsTime: '請輸入時間區間：',
        comfirm: '確認',
        year: '年',
        month: '月',
        not: '未選擇開始時間或結束時間',
        Mon: '壹',
        Tues: '貳',
        Wed: '参',
        Thur: '肆',
        Fri: '伍',
        Sat: '陸',
        Sun: '日'
    }
}