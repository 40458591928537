<template>
    <div class="Banner">
      <el-carousel style="height:100%" direction="horizontal" indicator-position="none" :autoplay="true">
        <el-carousel-item v-for="item in pageData" :key="item.homePageId">
          <img :src="`${baseUrl + item.homeImg}`" alt="">
          <div class="mask"></div>
          <div class="aside">
            <h1 class="title">{{item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']}}</h1>
            <p class="info">{{item[_i18n.locale == 'en' ? 'summaryEn' : 'summaryCn']}}</p>
            <a :href="item.homeUrl">{{$t('swiperShow.banner.detailsBtn')}} ></a>
          </div>
      </el-carousel-item>
    </el-carousel>
    </div>
  </template>
  
  <script>
  export default {
    name: "Banner",
    data(){
      return {
        // 页面数据
        pageData: [],
      }
    },
    methods: {
        // 获取页面数据
        async getPageData(){
            const res = await this.$http.get("/web/home/page")

            console.log("Banmer页面数据",res.data)
            this.pageData = res.data
        }
    },
    // 生命周期==>创建完成时
    created() {
      this.getPageData()
    },
  }
  </script>
  
  <style lang="less" scoped>
  .Banner{
    position: relative;
    width: 100%;
    height: 100%;
    .mask{
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0, .2);
      z-index: 10;
    }
     .img{
       position: absolute;
          height: 100% !important;
          width: 100%;
        }
    .aside{
      position: absolute;
      z-index: 100;
      top:50%;
      right: 309px;
      transform: translateY(-50%);
      text-align: right;
      color: white;
      font-family: Source Han Sans CN;
      .title{
        margin-bottom: 45px;
        font-size: 80px;
        font-weight: 500;
      }
      .info{
        font-size: 30px;
        margin-bottom:103px;
      }
        a{
            position: absolute;
            right: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: 0 20px;
            font-size: 20px;
            border-radius: 20px;
            color: white;
            text-decoration: none;
            .heffect();
            text-align: center;
            line-height: 60px;
            border: 1px solid #FFFFFF;
            border-radius: 10px;
      }
      p{
        text-align: right;
      }
    }
    .el-carousel,.el-carousel-item{
      position: relative;
      width: 100%;
      height: 100%;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
  </style>
  
  <style lang="less">
  .Banner{
    .el-carousel__container{
        height: 100%;
    }
    .el-carousel__indicators--horizontal{
      left: 8% !important;
      bottom: 5% !important;
    }
    .el-carousel__indicator--vertical .el-carousel__button {
      width:10px;
      height: 10px;
      border-radius: 50%;
    }
    .el-carousel__indicator--vertical{
      padding:25px 0;
    }
    .el-carousel__indicator.is-active button{
      background-color: #ED8220;
    }
    .el-carousel__indicators.el-carousel__indicators--vertical{
      width: 20px;
    }
  }
  </style>
  