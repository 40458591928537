<template>
    <div id="SwiperShow" ref="SwiperShow">
        <el-carousel ref="swiper" direction="vertical" trigger="click" :autoplay="false"  :initial-index="index" :indicator-position="index === 0 ? '' : 'none'" @change="(idx) => index = idx">
            <el-carousel-item>
                <Banner/>
            </el-carousel-item>

            <el-carousel-item >
                <FeeMeasure/>
            </el-carousel-item>

            <el-carousel-item >
                <Intellect/>
            </el-carousel-item>

            <el-carousel-item >
                <ProductP/>
            </el-carousel-item>

            <el-carousel-item >
                <News/>
            </el-carousel-item>

            <el-carousel-item >
                <FeeConsultaion/>
            </el-carousel-item>
        </el-carousel>
        <Foot :slide="true" :callback="next"/>
    </div>
</template>

<script>
    import Foot from '@/components/Foot'
    // 滑动块内容区展示区
    import Banner from "@/views/SwiperShow/Banner/index.vue";
    import News from '@/views/SwiperShow/News/index.vue'
    import FeeMeasure from '@/views/SwiperShow/FeeMeasure/index.vue'
    import Intellect from '@/views/SwiperShow/Intellect/index.vue'
    import ProductP from '@/views/SwiperShow/ProductP/index.vue'
    import FeeConsultaion from '@/views/SwiperShow/FeeConsultaion/index.vue'
    export default {
        name: "SwiperShow",
        components: {
            Banner,
            FeeMeasure,
            Intellect,
            ProductP,
            News,
            FeeConsultaion,
            Foot
        },
        data(){
            return {
                // 初始索引
                index: 0
            }
        },
        methods: {
            // 监听用户点击切换轮播图
            next(value){
                if(value >= 0){
                    // 将传来的赋值给index
                    this.index = Number(value)
                    this.$refs.swiper.setActiveItem(Number(value))

                    window.sessionStorage.setItem('swiperShowIndex',this.index)
                    return
                }
                if(this.index >= 5){
                    this.index = 0
                }else{
                   this.index++
                }
                this.$refs.swiper.setActiveItem(Number(this.index))
                // 保存状态到sesstion方便以后跳转进入历史的index
                window.sessionStorage.setItem('swiperShowIndex',this.index)
            },
            // 页面跳转
            jump(jumpObj){
                this.$router.push("/SubPage/IntellectDetail")
            },

            // 滑动切换轮播效果
            handlerMouseScroll(){
                 let valve = false
                
                 document.onmousewheel = (e)=>{

                    //  如果阀门开着或者当前路由不在swiperShow就退出
                    if(valve || this.$route.name != 'SwiperShow') return
                    
                    valve = true //开阀门
                    if(e.wheelDelta > 0){
                        // 鼠标下滑动
                        this.next(this.index >= 1 ? this.index -= 1 : 5)
                    }else{
                        // 鼠标上滑动
                        this.next(this.index < 5 ? this.index += 1 : 0)
                    }

                    // n毫秒后关阀门可再次滑动
                    setTimeout(()=>{
                        valve = false
                    },380)
                }
            },

            // 监听用户手指滑动
            handlerTouchMove(){
                let  startx, starty;
                let that = this
                function touchStart(e){
                    startx = e.touches[0].pageX;
                    starty = e.touches[0].pageY; 
                }
                document.addEventListener("touchstart", touchStart, false);
                //获得角度
                function getAngle(angx, angy) {
                    return Math.atan2(angy, angx) * 180 / Math.PI;
                };
                //根据起点终点返回方向 1向上 2向下 3向左 4向右 0未滑动
                function getDirection(startx, starty, endx, endy) {
                    var angx = endx - startx;
                    var angy = endy - starty;
                    var result = 0;

                    //如果滑动距离太短
                    if (Math.abs(angx) < 2 && Math.abs(angy) < 2) {
                        return result;
                    }

                    var angle = getAngle(angx, angy);
                    if (angle >= -135 && angle <= -45) {
                        result = 1;
                    } else if (angle > 45 && angle < 135) {
                        result = 2;
                    } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
                        result = 3;
                    } else if (angle >= -45 && angle <= 45) {
                        result = 4;
                    }

                    return result;
                }
                // 是否禁用
                function isDisabled(els){
                    let reg = /mobileDisabledSwiper/ig
                    for(let el of els){
                       if(!el.classList){
                            continue
                        }
                        let str = [...el.classList].join(' ')
                        if(str.indexOf('mobileDisabledSwiper') != -1) return true
                    }

                    return false
                }
                function startMove(e) {
                    if(isDisabled(e.path) == true) return
                    var endx, endy;
                    endx = e.changedTouches[0].pageX;
                    endy = e.changedTouches[0].pageY;
                    var direction = getDirection(startx, starty, endx, endy);
                    switch (direction) {
                        case 0:
                            break;
                        case 1:
                            that.next(that.index < 5 ? that.index += 1 : 0)
                            break;
                        case 2:
                            that.next(that.index >= 1 ? that.index -= 1 : 5)
                            break;
                        case 3:
                            break;
                        case 4:
                            break;
                        default:
                            break;
                    }
                }
                document.addEventListener("touchend", startMove, false);

                // 移除监听事件
                function destroyed(){
                    document.removeEventListener('touchend', startMove)
                    document.removeEventListener('touchstart', touchStart)
                }

                return destroyed
            }
        },
        watch: {
            $route(route){
                if(route.name == 'SwiperShow'){
                    this.next(route.query.page)
                }
            }
        },
        mounted(){
            this.$nextTick(() => {
                console.log( this.$refs.swiper, 'next')
                if(this.$route.params.isH){
                    this.next(0)
                }else{
                    this.next(Number(this.$route.query.page) || Number(window.sessionStorage.getItem('swiperShowIndex')) || 0)
                }

            // 鼠标滑动切换轮播效果
            this.handlerMouseScroll()
            // 手指滑动切换轮播图
            this.shutMouseMove = this.handlerTouchMove()
            })
        },
        beforeDestroy() {
            this.shutMouseMove()
        },
    }
</script>

<style lang="less">
    #SwiperShow{
        width: 100%;
        height: 100%;
        .el-carousel{
          height: 100%;
          width: 100%;
          .el-carousel__container{
              width: 100%;
              height: 100%;
            }
        .el-carousel__indicators{
            left: 105px;
            max-width: 20px;
            .el-carousel__indicator{
                .flexcc();
                width: 18px;
                height: 18px;
                margin: 20px 0;
                padding: 0px;
                border-radius: 50%;
                button{
                    width: 10px;
                    height: 10px;
                    background: #FFFFFF;
                    opacity: 0.7;
                    border-radius: 50%;
                }
            }
            .is-active{
                border: 1px solid rgba(237, 130, 32, 1);
                button{
                    width: 12px;
                    height: 12px;
                    background: @colorMain;
                }
            }
        }
        }
        .wrap>.hd{
            border-bottom: none !important;
            width: fit-content !important;
            margin: 0 auto;
            .bm{
                width: 100%;
                margin-top: -18px !important;
            }
        }
        #Foot{
            position: fixed;
            bottom: .25rem;
            z-index: 99;
        }
    }
</style>