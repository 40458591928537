<template>
  <div id="Foot">
    <div class="copyRight">
      <p>{{copyRight}}</p>
    </div>
    <div class="slide" v-show="slide">
      <img src="./arrow-down.png" alt="arrow" @click="()=>{callback()}">
    </div>
    <div class="more">
      <span class="iconfont icon-facebook-fill" @click='navigator("https://www.facebook.com/HunterDouglas.Luxaflex")'></span>
<!--      <span class="iconfont icon-instagram"></span>-->
      <span class="iconfont icon-Youtube-fill" @click="navigator('https://www.youtube.com/user/Hunterdouglas')"></span>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'Foot',
    props: ["slide","callback"],
    data(){
      return {
        copyRight: "Copyright @2023 Cherish Deco Ltd, All rights reserved."
      }
    },
    methods: {
        // 跳转地址
        navigator(link){
          window.open(link)
        }
    },
  }
</script>

<style lang="less">
  #Foot{
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    bottom: .5rem;
    opacity: .8;
    padding: 0 1.25rem;
    .flexbc();
    .copyRight{
      color: #fff;
      width: 1.25rem;
      p{
        font-family: "Arial, SourceHanSansCN-Light";
        font-size: .2125rem;
        white-space: nowrap;
      }
    }
    .slide{
      img{
        width: .45rem;
        height: .45rem;
        .heffect();
      }
    }
    .more{
      width: 1.25rem;
      .iconfont{
        font-size: .275rem;
        color: #fff;
        margin-right: .075rem;
        .heffect();
      }
    }
  }
</style>
