import Vue from 'vue'
import { Button, Select, Option, Tabs, TabPane,
         Input, Form, FormItem, Radio, RadioGroup,
         Checkbox, CheckboxGroup, Dropdown, DropdownItem,
         DropdownMenu, Menu, MenuItem, Submenu, Message,
         Row, Col, MessageBox, DatePicker, Slider, Dialog,
         TimeSelect, Image, Table, TableColumn, TimePicker,
        Carousel, CarouselItem, Pagination, Popover, MenuItemGroup} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(TimePicker)
Vue.use(DatePicker)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(TimeSelect)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Checkbox)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message

