<template>
    <div id="FeeMeasure" :style="`background-image: url( ${bgImg});`">
          <!-- 容器 -->
          <div class="wrap pubw">
            <!-- 头部 -->
            <div class="hd">
                <h1 class="title">{{$t('swiperShow.measure.title')}}</h1>
                <div class="bm"></div>
                <div class="subtitle">{{$t('swiperShow.measure.subTitle')}}</div>
            </div>
  
            <!-- 主体 -->
            <div class="bd swiper-container mobile-max-height mobileH40">
                <div class="details">
                    <div class="title">
                        <p>{{pageData[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']}}</p>
                    </div>
                    <div class="rule">
                        <div class="h">
                            <p class="h">{{$t('swiperShow.measure.ruleLabel')}}：</p>
                            <div v-html="pageData[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="number">
                            <p>{{$t('swiperShow.measure.numberLabel')}}: <span v-html="pageData[_i18n.locale == 'en' ? 'remainingPlacesEn' : 'remainingPlacesCn']"></span></p>
                        </div>
                        <div class="activitydate">
                            <p>{{pageData[_i18n.locale == 'en' ? 'activityTimeEn' : 'activityTimeCn']}}</p>
                        </div>
                    </div>
                    <div class="join" @click="handlerJoin">
                        <span>{{$t('swiperShow.measure.detailsBtn')}} ></span>
                    </div>
                </div>
                <div class="img">
                    <img :src="`${baseUrl + pageData.pageImg}`" v-if="pageData.pageImg" alt="">
                </div>
            </div>

        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FeeMeasure',
    components: {
    },
    data(){
      return {
        // 背景图片
        bgImg: process.env.BASE_URL + "bgImg/freeConsul.png",
        // 页面数据
        pageData: {},
      }
    },
    methods: {
        // 获取活动数据
        async getActivity(){
            let res = await this.$http.get("/web/measure/page")
            console.log(res,"免费测量页面数据")
            this.pageData = res.data
        },
        // 监听用户点击参加
        handlerJoin(){
            this.$router.push({
                name: "MeasureDetails",
                params: {pageData: this.pageData}
            })
        }
    },
    // 生命周期==>创建完成
    created() {
        this.getActivity()
    }
  }
  </script>
  
  <style lang="less">
    #FeeMeasure{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        .wrap{
            padding-top: 65px;
            padding-bottom: 1.25rem;
            text-align: left;
            .hd{
                text-align: center;
                .title{
                    font-size: .6rem;
                }
                .bm{
                    width: 2.725rem;
                    height: .1125rem;
                    background: @colorMain;
                    border-radius: .05rem;
                    margin: .0625rem auto;
                }
                .subtitle{
                    color: #B3B3B3;
                    font-size: .225rem;
                    font-weight: 400;
                    color: #999999;
                    text-align: center;
                }
            }
            .bd{
                .flex();
                padding-top: 100px;
                .details{
                    width: 55%;
                    line-height: .375rem;
                    margin-right: .625rem;
                    .title{
                       p{
                            font-size: .225rem;
                            margin-bottom: 28px;
                            text-align: left;
                       }
                    }
                    .rule{
                        .h{
                            margin-bottom: .125rem;
                        }
                        p{
                            font-size: .2rem;
                            color: #E6E6E6;
                            text-align: left;
                            font-family: 'SourceHanSansCN-Regular' !important;
                        }
                    }
                    .number{
                        span{
                            font-size: .225rem;
                        }
                    }
                    .row{
                        .flexbc();
                        margin-top: .75rem;
                        .number,.activitydate{
                            margin-right: 25px;
                            p{
                                font-size: .225rem;
                                color: @colorMain;
                            }
                        }
                        *{
                            font-family: 'SourceHanSansCN-Light' !important;
                        }
                    }
                    .join{
                        margin-top: .5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: fit-content;
                        padding: 10px 20px;
                        font-size: .225rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        background: rgba(237, 130, 32, 0.6);
                        border-radius: .1875rem;
                        text-align: center; 
                        line-height: .625rem;
                        border-radius: 15px;
                        .heffect();
                        *{
                            white-space: nowrap;
                            font-size: 20px;
                            font-family: 'SourceHanSansCN-Medium';
                        }
                    }
                }
                .img{
                    width: 38%;
                    img{
                        width: 100%;
                        height: 100%;
                        box-shadow: 0.3125rem -.3125rem  0rem rgba(121,123,121,.5);
                    }
                }
            }
        }

    }
  </style>
  