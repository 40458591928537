<template>
    <div id="Window">
        <router-view></router-view>
        <Foot/>
    </div>
</template>

<script>
    import Foot from '@/components/Foot'
    export default {
        name: 'Window',
        components: {
            Foot
        },
        mounted() {
            document.documentElement.style.overflow = 'hidden'
        },
        beforeDestroy() {
            document.documentElement.style.overflow = 'auto'
        },
    }
</script>

<style lang="less">
    #Window{
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
    }
</style>