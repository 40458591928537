const width = document.body.scrollWidth
const height = document.documentElement.clientHeight
const rem = 80 //1rem = 80px
let number = 24 //默认将屏幕宽度分为24等份
let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

// 根据class查找默认距离底部距离
function matchNumBuclass(el){
  let reg = /mobileH[0-9]+/igs
  let str = [...el.classList].join(' ')
  
  let num = str.match(reg)
  num ? num = num[0]?.replace(/[^0-9]/ig, '') : 0
  return Number(num)
}
function mobileHeight(){
       // 获取所有需要兼容屏幕高度的页面元素
    let specialElements = document.querySelectorAll('.mobile-max-height')
    specialElements.forEach(el => {
        const elHeight = el.offsetHeight
        let top = el.offsetTop  //元素距离顶部距离
        const optionHeight = matchNumBuclass(el) || 50
        let reservedHeight =  (optionHeight / ( width / number) * rem) //预留高度，比如底部所需要的高度
        let maxHieght =  height - top - reservedHeight //元素最大高度
        console.log(height, maxHieght, reservedHeight)
        // 如果内容高度没超出最大高度就不需要设置样式
        if(elHeight <= maxHieght) return
        el.style.maxHeight = maxHieght + 'px'
        el.style.overflowY = 'auto'
        el.classList.add('mobileDisabledSwiper') //在swipershow组件手指滑动滚动条时禁止切换swiper
    })
}

window.onload = () => {
  if(!isMobile) return
  mobileHeight()
}

exports.mobileHeight = mobileHeight
;(function(){

  if(isMobile){
      // 移动端分为9.375份 在750px设计稿上分割
      number = 9.375 
  }

  // 初始执行一次
  reRem()

  // 重新计算rem函数
  function reRem () {
    
    let w = document.documentElement.clientWidth
    let px = w / number
    document.documentElement.style.fontSize = px + 'px'
  }
  // 当窗口发生改变就执行
  window.onresize = (e) => {
    
    reRem()
  }

})();
