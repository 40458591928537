<template>
  <div id="IntellectDetails">
    <div class="banner">
      <div class="imgMask"></div>
      <img src="../../../assets/imgs/freemeasure.jpg" alt="">
      <div class="floting">
          <div class="title">
              <p>{{$t('subPage.intellect.title')}}</p>
          </div>
          <div class="subtitle">
              <p class="text">Intelligent curtain</p>
          </div>
      </div>
  </div>
      <!-- 容器 -->
      <div class="wrap pubw">
          <!-- 主体 -->
          <div class="bd">
              <div class="container" ref="container">
                  <div class="block" v-for="item in pageData" :key="item.meritPageId">
                    <div class="img">
                      <img :src="`${baseUrl + item.pageImg}`"alt="">
                    </div>
                    <div class="right">
                      <div>
                        <div class="txt">
                          <h2 v-html="item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']"></h2>
                          <div class="text" v-html="item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
                           <el-popover
                           v-if="textOverflow(item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn'])"
                          placement="top"
                          width="350"
                          trigger="hover"
                          :content="textOverflow(item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn'])">
                          <el-button slot="reference"> <span class="el-icon-chat-line-square"></span> </el-button>
                        </el-popover>
                        </div>
                          <!-- 跳转按钮 -->
                        <div class="joinBtn" @click="handlerJoin">
                          <span>{{$t('subPage.intellect.btn')}} ></span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
name: 'ProductP',
data () {
  return {
    // 内容列表
    pageData: [],
    // 轮播图列表
    swipers: [
      "../../../assets/imgs/intell1.jpg",
    ]
  }
},
  methods: {
      // 监听用户点击加入
      handlerJoin(){
        this.$router.replace("/SubPage/FAC")
      },
      // 判断文字是否超出展示区域
      textOverflow(text){
        let max = 144 //最大值
        let reg=/<[^<>]+>|[&nbsp;]/g
        let str = text.replace(reg, '')
        if(str.length > max) return str
        console.log('没有')
        return false
      }
  },
// 生命周期==>挂载完成
  mounted () {
      // 接收页面数据
        const pageData = this.$route.params.pageData || JSON.parse(window.sessionStorage.getItem('IntellectPageData'))
        window.sessionStorage.setItem('IntellectPageData',JSON.stringify(pageData))
        this.pageData = pageData
  },
  // 生命周期销毁了
  beforeDestroy() {
  },
}
</script>

<style lang="less">
  #IntellectDetails{
    overflow-x: hidden;
    padding-bottom: 150px;
    .banner{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
            .floting{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                .title{
                    p{
                        font-size: 1.175rem;
                    }
                }
                .subtitle{
                    p{
                        font-size: .45rem;
                    }
                }
            }
        }
      .wrap{
          .hd{
              text-align: center;
              .title{
                  font-size: .6rem;
                  color: #000;
              }
              .bm{
                  width: 2.725rem;
                  height: .1125rem;
                  background: @colorMain;
                  border-radius: .05rem;
                  margin: .0625rem auto;
                  margin-top:-0.1rem;
              }
              .subtitle{
                  font-size: .225rem;
                  font-weight: 400;
                  color: rgba(179, 179, 179, 1);
              }
          }
          .bd{
              margin-top:1.25rem;
              .container{
                  display: flex;
                  flex-wrap: wrap;
                      .block{
                        display: flex;
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        height: fit-content;
                        min-height: 455px;
                        justify-content: space-between;
                        align-items: center;
                        .img{
                              position: relative;
                              height: 100%;
                          img{
                              width: 100%;
                              height: 100%;
                          }
                        }
                        .right{
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          width: 60%;
                          height: 100%;
                        }
                         .joinBtn{
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: fit-content;
                          padding: 0px 20px;
                          background: @colorMain;
                          border-radius: .1875rem;
                          text-align: center;
                          line-height: .625rem;
                          color: #fff;
                          font-size: .225rem;
                          .heffect();
                          margin-top: 40px;
                          span{
                            font-family: 'SourceHanSansCN-Medium';
                          }
                        }
                        .txt{
                            position: relative;
                            text-align: left;
                            h2{
                                padding-left: .25rem;
                                border-left: .0625rem solid @colorMain;
                                margin-bottom: .25rem;
                                .flex();
                                p{
                                  margin-right: .125rem;
                                  font-size: .3rem;
                                  height: .3rem;
                                  line-height: .4375rem;
                                  color: #000;
                                  
                                }
                            }
                            .text{
                                p{
                                  margin-top: .1875rem;
                                  line-height: .375rem;
                                  text-align: justify;
                                  span{
                                    font-size: .225rem;
                                    text-align: justify;
                                    font-family: "Roboto-Light";
                                    color: #666666 !important;
                                    overflow:hidden; 
                                    text-overflow:ellipsis;
                                    display:-webkit-box; 
                                    -webkit-box-orient:vertical;
                                    -webkit-line-clamp:6; 
                                  }
                                }
                            }
                            .el-popover__reference-wrapper{
                              position: absolute;
                              right: 0px;
                              .el-button{
                                border: none;
                              }
                            }
                        }
                      }
                    .block:nth-child(2n + 1){
                      .img{
                        width: 50%;
                      }
                      .right{
                        width: 35%;
                      }
                    }
                    .block:nth-child(2n + 2){
                      margin-top: -20px;
                      .img{
                        position: absolute;
                        right: 0;
                        width: 40%;
                      }
                      .right{
                        position: absolute;
                        left: 0;
                        width: 30%;
                      }
                    }
                    .block:first-child{
                      align-items: flex-start;
                      .right{
                        align-items: flex-start;
                      }
                    }
                    .block:last-child{
                      align-items: flex-end;
                      margin-top: -15px;
                      .right{
                        align-items: flex-end;
                      }
                    }
              }
          }
      }
  }
</style>
