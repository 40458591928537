<template>
    <div id="TimePicker">
        <div class="timeLine">
            <div class="hours">
                
            </div>
            <div class="minute">

            </div>
        </div>
        <input type="text" class="ipt" 
        @keydown.up.prevent="keyup" 
        @keydown.left.prevent="keyLeftRight"
        @keydown.right.prevent="keyLeftRight"
        @keydown.down.prevent="keydown"
        @focus="handlerFocus" ref="ipt"
        @blur="handlerBlur"
        :placeholder="placeholder" 
        v-model="nativeIptvalue">
    </div>
</template>

<script>
    import formatTime from "@/assets/js/formatTime"
    export default {
        name: 'TimePicker',
        props: ["placeholder", "value", "updateTime", "disabelTime"],
        data(){
            return {
                // 时列表
                hoursList: [],
                // 分列表
                minuteList: [],
                // 当前小时
                curHour: 0,
                // 当前分钟
                curMinute: 0,
                // 时间
                nativeIptvalue: '0:0',
                // 当前控制点时间
                curActive: 'curHour'
            }
        },
        methods: {
            // 初始化时列表
            createHoursList(){
                let startSteamp = formatTime(this.disabelTime.start).stamp || 0
                let endSteamp = formatTime(this.disabelTime.end).stamp || 0
                let hoursList = []
                
                for(let i = 0;i < 23;i++){
                    let hours = {}
                    let time = formatTime(i + '00')
                    let stamp = time.stamp
                    hours.hour = time.hour
                    if(stamp < startSteamp || stamp > endSteamp){
                        hours.disabel = true
                    }else{
                        hours.disabel = false
                    }
                    hoursList.push(hours)
                }
                this.hoursList = hoursList
            },
            // 创建分钟列表
            createMnuteList(){

            },
            // 选中输入框内容
            setInputSelection(input, startPos, endPos) {
                console.log(endPos)
                input.focus();
                if (typeof input.selectionStart != "undefined") {
                    input.selectionStart = startPos;
                    input.selectionEnd = endPos;
                } else if (document.selection && document.selection.createRange) {
                    // IE branch
                    input.select();
                    var range = document.selection.createRange();
                    range.collapse(true);
                    range.moveEnd("character", endPos);
                    range.moveStart("character", startPos);
                    range.select();
                }
            },
            // 监听输入框获取焦点
            handlerFocus(){
                this.setInputSelection(this.$refs.ipt, 0,this.nativeIptvalue.length)
            },
            // 监听输入框按下向上
            keyup(){
                let curHour = Number(String(this.curHour))
                let curMinute = String(this.curMinute)
                if(curMinute.length == 1){
                    curMinute = "0" + curMinute
                }
                console.log(curMinute,"minute")
                switch(this.curActive){
                    case "curHour":
                        if(this.curHour >= this.hoursList.length){
                            return
                        }else{
                            if(this.hoursList[curHour+=1].disabel){
                                this.changeSelectVo()
                                return this.$message.error(this.$t("tips.pls") + this.disabelTime.start + this.$t("tips.to") + this.disabelTime.end + this.$t("tips.within"))
                            }
                            this.curHour++
                            this.nativeIptvalue = this.curHour + ":" + this.curMinute
                            this.changeSelectVo()
                        }
                    break;
                    case "curMinute":
                        if(this.curMinute >= 59){
                            return
                        }else{
                            let startSteamp = formatTime(this.disabelTime.start).stamp || 0
                            let endSteamp = formatTime(this.disabelTime.end).stamp || 0
                             let time = formatTime(this.curHour + (curMinute))
                              if(time.stamp < startSteamp || time.stamp > endSteamp){
                                 this.$message.error(this.$t("tips.pls") + + this.disabelTime.start + this.$t("tips.to") + this.disabelTime.end + this.$t("tips.within"))
                                 this.curMinute = "00"
                                 this.nativeIptvalue = this.curHour + ":" + this.curMinute
                                 this.changeSelectVo()
                                 return
                              }
                            this.curMinute++
                            this.nativeIptvalue = this.curHour + ":" + this.curMinute
                            this.changeSelectVo()
                        }
                    break;
                }
            },
            keydown(){
                let curHour = Number(String(this.curHour))
                switch(this.curActive){
                    case "curHour":
                        if(this.curHour <= 0){
                            return
                        }else{
                            if(this.hoursList[curHour-=1].disabel){
                                return this.$message.error(this.$t("tips.pls") + this.disabelTime.start +  this.$t("tips.to")+ this.disabelTime.end + this.$t("tips.within"))
                            }
                            this.curHour--
                            this.nativeIptvalue = this.curHour + ":" + this.curMinute
                            this.changeSelectVo()
                        }
                    break;
                    case "curMinute":
                        if(this.curMinute <= 0){
                            return
                        }else{
                            this.curMinute--
                            this.nativeIptvalue = this.curHour + ":" + this.curMinute
                            this.changeSelectVo()
                        }
                    break;
                }
            },
            // 箭头右边
            keyLeftRight(){
                if(this.curActive == 'curHour'){
                    this.curActive = 'curMinute'
                    this.changeSelectVo()
                }else{
                    this.curActive = 'curHour'
                    this.changeSelectVo()
                }
            },
            // 切换控制时间选中效果
            changeSelectVo(){
                if(this.curActive == 'curMinute'){
                    this.$nextTick(()=>{
                        this.setInputSelection(this.$refs.ipt, String(this.curHour).length+1, this.curActive.length)
                    })
                }else{
                    this.$nextTick(()=>{
                        this.setInputSelection(this.$refs.ipt, 0, String(this.curHour).length)
                    })
                }
            },
            // 监听输入失去焦点
            handlerBlur(){
                let startSteamp = formatTime(this.disabelTime.start)
                let endSteamp = formatTime(this.disabelTime.end)
                let time = formatTime(this.nativeIptvalue)
                 if(time.stamp < startSteamp.stamp || time.stamp > endSteamp.stamp){
                    this.$message.error(this.$t("tips.pls") + this.disabelTime.start +  this.$t("tips.to")+ this.disabelTime.end + this.$t("tips.within"))
                    this.curHour = startSteamp.hour
                    this.curMinute = startSteamp.minute
                    this.nativeIptvalue =  formatTime(this.curHour + this.curMinute).time
                 }else{
                     this.nativeIptvalue = time.time
                 }
                this.$emit("updateTime",this.nativeIptvalue)
            }
        },
        // 生命周期==>创建完成
        created(){
            this.nativeIptvalue = this.value
            this.createHoursList()
            this.curHour = formatTime(this.value).hour
            this.curMinute = formatTime(this.value).minute
        },
        mounted(){
        }
    }
</script>

<style lang="less">
    #TimePicker{
        position: relative;
        width: 100%;
        height: 100%;
        min-width: .625rem;
        min-height: .25rem;
        .timeLine{
            display: flex;
        }
        .ipt{
            position: absolute;
            width: 100%;
            height: 100%;
            outline: none;
            border-radius: .0625rem !important;
            font-size: .15rem;
            text-align: center;
            &:focus{
                border: .025rem solid #ED8220;
            }
        }
    }
</style>