// 格式化时间文件


function formatTime(time){
    time = time + ''
    let timeObj = {}
    time = time.replace(/[^\d/]/g,'') //清空除了数字的字符
    switch(time.length){
        case 0:
            timeObj.hour = '00'
            timeObj.minute = '00'
            timeObj.time = timeObj.hour + ":" +  timeObj.minute
            timeObj.stamp = (Number(timeObj.hour) * 60 * 60 * 1000) + (Number(timeObj.minute) * 60 * 1000)
            break;

        case 1:
            timeObj.hour = '0' + time[0]
            timeObj.minute = "00"
            timeObj.time = timeObj.hour + ":" +  timeObj.minute
            timeObj.stamp = (Number(timeObj.hour) * 60 * 60 * 1000) + (Number(timeObj.minute) * 60  * 1000) 
            break;

        case 2:
            timeObj.hour = '0' + time[0]
            timeObj.minute = "0" + time[1]
            timeObj.time = timeObj.hour + ":" +  timeObj.minute 
            timeObj.stamp = (Number(timeObj.hour) * 60 * 60 * 1000) + (Number(timeObj.minute) * 60 * 1000)
            break;
        case 3:
            timeObj.hour = '0' + time[0]
            timeObj.minute = time[1] + time[2]
            if(timeObj.minute > 59){
                timeObj.minute = "00"
            }
            timeObj.time = timeObj.hour + ":" +  timeObj.minute 
            timeObj.stamp = (Number(timeObj.hour) * 60 * 60 * 1000) + (Number(timeObj.minute) * 60 * 1000)
            break;
        case 4:
            let hour = time[0] + time[1]
            let minute = time[2] + time[3]
            if(hour > 23 || minute > 59){
                timeObj.hour = '00'
                timeObj.minute = '00'
            }else{
                timeObj.hour = time[0] + time[1]
                timeObj.minute = time[2] + time[3]
            }
            timeObj.time = timeObj.hour + ":" +  timeObj.minute 
            timeObj.stamp = (Number(timeObj.hour) * 60 * 60 * 1000) + (Number(timeObj.minute) * 60 * 1000)
            break;

        default:
            if(time.length > 0){
                let hour = time[0] + time[1]
                let minute = time[2] + time[3]
                if(hour > 23 || minute > 59){
                    timeObj.hour = '00'
                    timeObj.minute = '00'
                }else{
                    timeObj.hour = time[0] + time[1]
                    timeObj.minute = time[2] + time[3]
                }
                timeObj.time = timeObj.hour + ":" +  timeObj.minute 
            }
            break;

    }

    return timeObj
}

export default formatTime
