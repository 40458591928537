<template>
    <div id="NewMessage">
        <div class="warp pubw">
                <div class="hd">
                    <h1 class="title" v-html="pageData[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']"></h1>
                    <div class="date numL">{{pageData.dateCn}}</div>
                </div>
                <div class="bd">
                    <div v-html="pageData[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']">
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewMessage',
    data(){
        return {
            // 页面数据
            pageData: {}
        }
    },
        // 生命周期==>挂载完成
    mounted () {
        // 告诉头部组件该变暗
        this.$bus.$emit("ProductPDetails",0)

        // 接收页面数据
        this.pageData = this.$route.params.pageData || JSON.parse(window.sessionStorage.getItem('NewMessagePageData'))

        
        window.sessionStorage.setItem('NewMessagePageData',JSON.stringify(this.pageData))
    },
    // 生命周期销毁了
    beforeDestroy() {
    },
}
</script>

<style lang="less">
    .el-popover{
        text-indent: 10px;
        line-height: 25px !important;
    }
    #NewMessage{
        padding-bottom: 150px;
        img{
            width: 100%;
        }
        .hd{
            position: relative;
            padding-top: .875rem;
            padding-bottom: 20px;
            border-bottom: 2px solid #ccc;
            margin-bottom: .5rem;
            .title{
                font-size: .6rem;
                color: rgba(51, 51, 51, 1);
                p{
                    margin: .25rem 0;
                    font-size: .6rem;
                }
            }
            .date{
                position: absolute;
                right: 0;
                bottom: .0625rem;
                color: rgba(102, 102, 102, 1);
            }
        }
        .bd{
            .txt{
                line-height: .625rem;
                p,a{
                    font-size: .3rem;
                    color: #666666;
                    line-height: 30px;
                    text-align: justify;
                    margin: .375rem 0;
                }
            }
            .block{
                width: 100%;
                height: 7.5rem;
                background: #CBCBCB;
                margin: .625rem 0;
            }
            *{
                font-family: 'Roboto-Light' !important;
            }
        }
    }
</style>