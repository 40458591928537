<template>
    <div id="Intellect" :style="`background-image: url( ${bgImg} )`">
          <!-- 容器 -->
          <div class="wrap pubw">
            <!-- 头部 -->
            <div class="hd">
                <h1 class="title">{{$t('swiperShow.intellect.title')}}</h1>
                <div class="bm"></div>
                <div class="subtitle">Intelligent curtain</div>
            </div>
  
            <!-- 主体 -->
            <div class="bd mobile-max-height">
                <!-- tab栏 -->
                <div class="tabs">
                    <div class="tab" v-for="item in pageData" :key="item.smartPageId" :class="curActive==item.smartPageId?'active':''"
                     @click="handlerSwichTab(item.smartPageId)">
                     <div class="border" v-show='curActive==item.smartPageId'></div>
                        <div class="title" v-html="item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']"></div>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="items">
                    <div class="item" v-for="item in pageData" :key="item.smartPageId" v-show="curActive==item.smartPageId">
                        <div class="imgs">
                            <img :src="`${baseUrl + item.pageImg}`" alt="智能窗簾">
                        </div>
                        <div class="description">
                            <span v-html="item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></span>
                        </div>
                        <div class="bottom">
                            <div class="arrow">
                                <img src="../../../assets/imgs/arrow.png" alt=""  @click="handlerDetails()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Intellect',
    data(){
      return {
        // 内容区
        itemList:[
            {
                id:"0",
                imgs:["img/shine.jpeg","img/shine.jpeg"],
                text:"將優美窗飾與智慧功能結合智能電動系統可操控大部份Hunter Douglas窗飾，更可預設時間及場景，窗飾，更可預設時間及場景，助你控制日光，打造理想家居。"
            },
            {
                id:"1",
                imgs:["img/shine.jpeg","img/shine.jpeg"],
                text:"將優美窗飾與智慧功能結合，PowerView® 智能電動系統可操控大部份Hunter Douglas窗飾，更可預設時間及場景，窗飾，更可預設時間及場景，助你控制日光，打造理想家居。"
            },
            {
                id:"2",
                imgs:["img/shine.jpeg","img/shine.jpeg"],
                text:"將優美窗飾與智慧功能結合，PowerView® Hunter Douglas窗飾，更可預設時間及場景，窗飾，更可預設時間及場景，助你控制日光，打造理想家居。"
            },
        ],
        // 当前展示的列表
        curActive: 1,
        // 背景图片
        bgImg: process.env.BASE_URL + "bgImg/intellect.png",
        // 页面数据
        pageData: []
      }
    },
    methods: {
        // 获取页面数据
        async getPageData(){
            const res = await this.$http.get("/web/smart/page")
            console.log(res, "智能窗帘页面数据")
            this.pageData = res.data
        },
        // 监听用户切换tab栏
        handlerSwichTab(index){
            this.curActive = index
        },
        // 监听用户点击箭头查看详情
        handlerDetails(){
            this.$router.push({
                name: 'IntellectDetail',
                params:{pageData: this.pageData}
            })
        }
    },
    // 生命周期==>挂载完成
    mounted() {
        // 给nav传递背景图片路径
        this.$bus.$emit("setNavBg",this.bgImg)
        this.getPageData()
    },
  }
  </script>
  
  <style lang="less">
    #Intellect{
      width: 100%;
      height: 100%;
      color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      .wrap{
        padding-top: 100px;
        width: 1100px;
        .hd{
            text-align: center;
            .title{
              font-size: .6rem;
            }
            .bm{
                width: 218px;
                height: 9px;
                background: @colorMain;
                border-radius: 4px;
                margin:5px auto;
                margin-top:-8px;
            }
            .subtitle{
                color: #B3B3B3;
                font-size: 18px;
                font-weight: 400;
                color: rgba(179, 179, 179, 1);
                text-align: center;
             }
        }
        .bd{
            .flexb();
            margin-top: 30px;
            height: 558px;
            background: rgba(255, 255, 255, 0.05);
            width: 100%;
            .tabs{
                width: 35%;
                .flex();
                flex-wrap: wrap;
                .heffect();
                .tab{
                    position: relative;
                    width: 100%;
                    height: 33.3333%;
                    padding: .875rem;
                    p{
                        text-align: left;
                        font-family: 'SourceHanSansCN-Light';
                    }
                    *{
                        font-size: 18px;
                        text-align: left;
                        font-family: 'SourceHanSansCN-Light';
                    }
                    .title p:first-child{
                        font-size: .375rem;
                    }
                    .title p:last-child{
                        font-size:.2rem;
                    }
                    .border{
                        position: absolute;
                        left: 60px;
                        top: 77px;
                        border-radius: 6px;
                        width: 6px;
                        height: 30px;
                        background-color: @colorMain;
                    }
                }
               .active{
                    background: rgba(255, 255, 255, 0.1);
                    .title{
                        p{
                            font-family: 'SourceHanSansCN-Medium';
                        }
                        *{
                            font-size: 20px;
                            font-family: 'SourceHanSansCN-Medium';
                        }
                    }
                }
            }
            .items{
                width: 65%;
                background: rgba(255, 255, 255, 0.1);
                padding: 30px;
                .item{
                    height: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .imgs{
                        height: 75%;
                        img{
                            width: 100%;
                            height: 100%;
                            margin-right: .1875rem;
                        }
                    }
                    .description{
                        height: 11.5%;
                        margin-top: .3125rem;
                        * /deep/{
                            height: 100% !important;
                            box-sizing: border-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            display: -moz-box;
                            -moz-line-clamp: 2;
                            -moz-box-orient: vertical;
                            word-wrap: break-word;
                            word-break: break-all;
                            white-space: normal;    
                        }
                        span{
                            p{
                                overflow: hidden;
                                text-overflow:ellipsis;

                                font-size: 18px;
                                font-weight: 300;
                                color: #E6E6E6;
                                line-height: .3375rem;
                                text-align: justify;
                                color: #E6E6E6;
                                span{
                                    font-size: 18px;
                                    font-weight: 300;
                                    color: #E6E6E6 !important;
                                    line-height: .3375rem;
                                    background: none !important;
                                    height: 100%;
                               }
                            }
                        }
                    }
                }
                .bottom{
                    text-align: right;
                    .arrow{
                        margin-top: .25rem;
                        text-align: right;
                        img{
                            width: 50px;
                            height: 30px;
                            .heffect();
                        }
                    }
                }
            }
        }
      }
    }
  </style>
  