<template>
    <router-view :formatNum="formatNum"></router-view>
</template>

<script>
    export default {
        name: "Shop",
        methods: {
            // 处理3位数字加,的函数
            formatNum(str){
                var newStr = "";
                var count = 0;

                if(str.indexOf(".")==-1){
                    for(var i=str.length-1;i>=0;i--){
                    if(count % 3 == 0 && count != 0){
                        newStr = str.charAt(i) + "," + newStr;
                    }else{
                        newStr = str.charAt(i) + newStr;
                    }
                    count++;
                    }
                    str = newStr; //自动补小数点后两位
                }
                else
                {
                    for(var i = str.indexOf(".")-1;i>=0;i--){
                    if(count % 3 == 0 && count != 0){
                        newStr = str.charAt(i) + "," + newStr;
                    }else{
                        newStr = str.charAt(i) + newStr; //逐个字符相接起来
                    }
                    count++;
                    }
                    str = newStr;
                }
                return str;
            }
        },
        created() {
        },
        mounted() {
        },
    }
</script>

<style lang="less">

</style>