<template>
    <div class="Member" :style="`background-image: url( ${bgImg} )`">
        <div class="wrap pubw">
            <div class="hd">
            </div>
            <div class="bd mobile-max-height">
                <div class="info">
                    <h1 class="title">{{$t('member.memberInfo.title')}}</h1>
                    
                    <div class="details">
                        <div class="row">
                            <p class="label"> {{$t('member.memberInfo.name')}}：</p>
                            <p class="value"> {{memberInfo.memberName}}</p>
                        </div>
                        <div class="row">
                            <p class="label"> {{$t('member.memberInfo.phone')}}：</p>
                            <p class="value numL"> {{memberInfo.memberMobil}}</p>
                        </div>
                        <div class="row">
                            <p class="label"> {{$t('member.memberInfo.email')}}：</p>
                            <p class="value numL"> {{memberInfo.memberEmail}}</p>
                        </div>
                        <div class="row">
                            <p class="label"> {{$t('member.memberInfo.address')}}：</p>
                            <p class="value" v-show="!iptShow">{{address || ''}}</p>
                            <div id="edit" v-model="address" v-show="!iptShow" @click="iptShow = true">
                                <img src="../../assets/imgs/edit.png" alt="">
                            </div>
                            <el-input :clearable="true" @blur="handlerEditAddress" v-model="address" v-show="iptShow" class="adsIpt" ></el-input>
                        </div>
                    </div>
                    <div class="options">                        
                        <div class="btn pubBtnMed" @click="handlerLoginout">
                            <p >{{$t('member.loginout')}} <span>></span></p>
                        </div>
                    </div>
                </div>
                <div class="orderList">
                    <h1 class="title">{{$t('member.orderList.title')}}</h1>
                    <div class="orders">
                        <div class="empty-tips" v-if="orderList.length == 0">{{$t('member.empty')}}</div>
                        <div class="order" v-for="item in orderList" :key="item.id" @click="handlerShowOrder(item)">
                            <div class="top">
                                <div class="number">
                                    <p>{{$t('member.orderList.orderNumber')}}： <span class="numL">{{item.orderNumber}}</span></p>
                                </div>
                            </div>
                            <div class="middle">
                                <p>{{item[_i18n.locale == 'en' ? 'planNameEn' : 'planNameCn']}}</p>
                                <div class="price">
                                    HKD <span class="numFont">{{formatNum(item.totalCharges + '')}}</span>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="status" v-if="item.paymentStatus == '0'">
                                    <p class="lable">{{$t('member.orderList.orderStatusL')}}: </p> <p class="value">{{$t('member.orderList.staus0')}}</p>
                                </div>
                                <div class="status" v-else>
                                    <p class="lable">{{$t('member.orderList.orderStatusL')}}: </p>
                                    <!-- <p v-if="item.orderStatus == '0'">{{$t('member.orderList.step0')}}</p>
                                    <p v-if="item.orderStatus == '1'">{{$t('member.orderList.step1')}}</p>
                                    <p v-if="item.orderStatus == '2'">{{$t('member.orderList.step2')}}</p> -->
                                    <p v-if="item.orderStatus == '3'">{{$t('member.orderList.step3')}}</p>
                                    <!-- <p v-if="item.orderStatus == '4'">{{$t('member.orderList.step4')}}</p> -->
                                    <p v-if="item.orderStatus == '5'">{{$t('member.orderList.step5')}}</p>
                                    <!-- <p v-if="item.orderStatus == '6'">{{$t('member.orderList.step6')}}</p>
                                    <p v-if="item.orderStatus == '7'">{{$t('member.orderList.step7')}}</p> -->
                                    <p v-if="item.orderStatus == '8'">{{$t('member.orderList.step8')}}</p>
                                    <p v-if="item.orderStatus == '9'">{{$t('member.orderList.step9')}}</p>
                                </div>
                                <div class="options" v-if="item.paymentStatus == '0'">
                                    <div class="delete">
                                        <span class="el-icon-delete" @click.stop="handlerDeleteOrder(item.orderId)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <!-- 遮罩 -->
            <div class="imgMask" v-show="deleteAlertShow"></div>
            <!-- 删除订单浮窗 -->
            <div class="alret" v-show="deleteAlertShow">
                <div class="container">
                    <p class="tips">{{$t("member.deleteOrder")}}</p>
                    <div class="options">
                        <div class="cancel btn" @click="deleteAlertShow = false">
                            <p>{{$t("member.cancel")}}</p>
                        </div>
                        <div class="complate btnMain" @click="confirmDelete">
                            <p>{{$t("member.confirm")}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 退出登录弹窗 -->
            <div class="alret" v-show="showLoginout">
                <div class="container">
                    <p class="tips">{{$t("member.loginoutCon")}}</p>
                    <div class="options">
                        <div class="cancel btn" @click="showLoginout = false">
                            <p>{{$t("member.cancel")}}</p>
                        </div>
                        <div class="complate btnMain" @click="loginout">
                            <p>{{$t("member.confirm")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        <Foot/>
    </div>
</template>

<script>
    import Foot from '@/components/Foot'
export default {
    name: "Member",
    components: {
        Foot  
    },
    data(){
        return{
            // 背景图片
            bgImg: process.env.BASE_URL + "bgImg/member.png",
            // 修改地址ipt是否展示
            iptShow: false,
            // 地址
            address: "",
            // 用户信息
            memberInfo:"",
            // 订单列表
            orderList: [],
            // 是否展示删除确认框
            deleteAlertShow: false,
            // 当前要删除的订单id
            deleteID: undefined,
            // 是否展示退出登录弹窗
            showLoginout: false,

        }
    },
    methods:{
        // 获取会员信息
        async getMemberInfo(){
            
            let res = await this.$http.get("/web/member")
            if(res.status != 200) return this.$message.error(this.$t("tips.infoErr"))
            this.memberInfo = res.data
            // 根据用户id查询订单
            this.getOrderList(res.data.memberId)
            this.address = this.memberInfo.address
        },  
        // 监听用户修改地址
        async handlerEditAddress(){

            if(this.iptShow){
                if(this.address !=  this.memberInfo.address){
                // 需要修改地址
                    let res = await this.$http.put("/web/member/edit",{
                        address: this.address,
                        memberId: this.memberInfo.memberId
                    })
                    this.$message(res.data.msg)
                    console.log(res,"修改地址结果")
                }
            }
            this.iptShow = !this.iptShow
        },
        // 获取订单
        async getOrderList(id){
            let res = await this.$http.get("/web/order/list")
            console.log(res,"订单列表")
            this.orderList = res.data
        },
        // 监听用户查看订单
        handlerShowOrder(item){
            window.sessionStorage.setItem('memberOrderInfo', JSON.stringify(item))
            this.$router.push({
                name: "Order",
                params: {
                    orderInfo: item
                }
            })
        },
        // 清空地址
        clearAddress(){
            this.address = ''
        },
        // 处理3位数字加,的函数
        formatNum(str){
            var newStr = "";
            var count = 0;

            if(str.indexOf(".")==-1){
                for(var i=str.length-1;i>=0;i--){
                if(count % 3 == 0 && count != 0){
                    newStr = str.charAt(i) + "," + newStr;
                }else{
                    newStr = str.charAt(i) + newStr;
                }
                count++;
                }
                str = newStr; //自动补小数点后两位
            }
            else
            {
                for(var i = str.indexOf(".")-1;i>=0;i--){
                if(count % 3 == 0 && count != 0){
                    newStr = str.charAt(i) + "," + newStr;
                }else{
                    newStr = str.charAt(i) + newStr; //逐个字符相接起来
                }
                count++;
                }
                str = newStr;
            }
            return str;
        },
        // 监听用户点击删除订单
        handlerDeleteOrder(orderId){
            this.deleteID = orderId
            this.deleteAlertShow = true
        },
        // 用户确定删除订单
        async confirmDelete(){
            let res = await this.$http.delete('/web/order/remove?id=' + this.deleteID)
            if(res.data.code == 200 || res.data.code == 0){
                this.$message.success(res.data.msg)
            }else{
                this.$message.error(res.data.msg)
            }
            this.getOrderList(this.memberInfo.memberId)
            this.deleteID = undefined
            this.deleteAlertShow = false
            console.log(res, '删除订单结果')
        },
        // 监听用户点击退出登录
        handlerLoginout(){
            this.showLoginout = true
        },
        // 退出登录
        loginout(){
            window.sessionStorage.clear()
            this.$router.push('login')
        }
    },
    // 生命周期==>挂载完成
    created() {
        if(window.sessionStorage.getItem("token")){
            this.getMemberInfo()
        }else{
            this.$message.info(this.$t("tips.fristLogin"))
            this.$router.push("/Login")
        }
    },
}
</script>
    
<style scoped lang="less">
    .Member{
        width: 100%;
        height: 100%;
        background-color: #aaa;
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        .wrap{
            height: 100%;
            padding-top: 0px;
            .hd{
                padding-top: 160px;
                border-bottom: none;
            }
            .bd{
                height: 8rem;
                background: rgba(255, 255, 255, 0.05);
                border: 0.0125rem solid rgba(232, 232, 255, 0.14);
                border-radius: .325rem;
                padding: .625rem;
                .flex();
                .info,.orderList{
                    width: 50%;
                    .title{
                        font-size: 36px;
                        font-weight: 500;
                    }
                }
                .info{
                    position: relative;
                    border-right: .0125rem solid rgba(232, 232, 255, 0.14);
                    .details{
                        margin-top: .625rem;
                        .row{
                            position: relative;
                            .flexlc();
                            margin: .275rem 0; 
                            text-align: left;
                            .label{
                                font-size: .3rem;
                            }
                            .value{
                                font-size: .3rem;
                                font-weight: 580;
                                width: 4rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                text-align: left;
                            }
                            .adsIpt{
                                box-sizing: content-box !important;
                                width: 4rem;
                                height: 100%;
                                outline: none;
                                border: 1px solid rgba(232, 232, 255, 0.14);
                                border-radius: .0625rem;
                                padding:.0625rem;
                                font-size: .225rem;
                                background: none;
                                color: #fff;
                                box-sizing: border-box;
                                padding-right: 15px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                &:hover{
                                    cursor: pointer;
                                }
                                /deep/ .el-input__inner{
                                    background: none;
                                    height: 100%;
                                    border: none;
                                    color: #fff;
                                    padding:.0625rem;
                                    font-size: .225rem;
                                    background: none;
                                    color: #fff;
                                }
                            }
                            .icon, #edit{
                                right: 1.25rem;
                                position: absolute;
                                color: rgba(232, 232, 255, 5);
                                font-weight: 550;
                                .heffect();
                                font-size: .2rem;
                            }
                        }
                        .row:last-child{
                            padding: .025rem;
                            margin-top: -2px;
                        }
                    }
                    .editAds{
                        position: absolute;
                        bottom: 0rem;
                        left: 25%;
                        transform: translateX(25%);
                        border: .0125rem solid #ED8220;
                        border-radius: .125rem;
                        width: 1.625rem;
                        height: .625rem;
                        line-height:.625rem;
                        .heffect();
                        margin: 0 auto;
                        margin-top: .875rem;
                        p{
                            text-align: center;
                            font-size: 18px;
                            color: @colorMain;
                        }
                    }
                    .options{
                        margin-top: .875rem;
                        .btn{
                            margin: 0 auto;
                            width: fit-content;
                            p{
                                font-size: 18px;
                            }
                        }
                    }
                }
                .orderList{
                    .flexjc();
                    .title{
                        margin-bottom: .625rem;
                    }
                    flex-wrap: wrap;
                    .orders{
                        height: 5.5625rem;
                        overflow-y: scroll;
                        min-width: 80%;
                        .empty-tips{
                            font-size: 18px;
                        }
                        .order{
                            margin: .275rem 0; 
                            width: 6.125rem;
                            height: 1.5rem;
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: .1875rem;
                            border: 0.0125rem solid rgba(232, 232, 255, 0.14);
                            padding: .1875rem .25rem;
                            .heffect();
                            .top{
                                .flexbc();
                                p{
                                    font-size: .2rem;
                                }
                                span{
                                    font-size: .2rem;
                                }
                            }
                            .middle{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin: .125rem 0 .0625rem;
                                .price{
                                    color: @colorMain;
                                    span{
                                        font-size: .25rem;
                                    }
                                    .numFont{
                                        color: @colorMain;
                                    }
                                }
                               p{
                                    font-size: .25rem;
                                    font-weight: 580;
                                    text-align: left;
                               }
                            }
                            .bottom{
                                .flexbc();
                                border-top: 1px solid rgba(0, 0, 0, .2);
                                padding-top: 8px;
                                .type p{
                                    color: #ccc;
                                    font-family: 'SourceHanSansCN-Light';
                                }
                                .status{
                                    display: flex;
                                    .lable{
                                        margin-right: 5px;
                                    }
                                }
                                .options{
                                    .delete{
                                        span{
                                            font-size: 18px;
                                            &:hover{
                                                opacity: .8;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .orders::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
        .imgMask{
            top: 0;
        }
        .alret{
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            .flexcc();
            flex-wrap: wrap;
            .container{
                width: 11.25rem;
                height: 3.75rem;
                background: rgba(150, 150, 150, 0.7);
                border: 0rem solid #FFFFFF;
                border-radius: .325rem;
                z-index: 20;
                .tips{
                    margin-top: .75rem;
                    font-size: .25rem;
                    color: #fff;
                }
                .options{
                    margin-top: 1.25rem;
                    .flexcc();
                    .btn{
                        margin-right: 1.25rem;
                    }
                    .btnMain,.btn{
                        p{
                            font-size: .225rem !important;
                        }
                    }
                    .login,.prev{
                        p{
                            color: #000;
                        }
                    }
                }
            }
        }
    }
</style>
    