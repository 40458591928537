<template>
    <div id="Contact-Us">
        <div class="banner">
            <div class="imgMask"></div>
            <img src="../../../assets/imgs/freemeasure.jpg" alt="">
            <div class="floting">
                <div class="title">
                    <p class="title">{{$t('subPage.contactus.title')}}</p>
                </div>
                <!-- <div class="subtitle">
                    <p class="text">Shop Location</p>
                </div> -->
            </div>
        </div>
              <!-- 容器 -->
      <div class="wrap pubw">
        <!-- 主体 -->
        <div class="bd">
            <div class="container" ref="container">
                <div class="block" v-for="(item, index) in pageData" :key="index">
                  <div class="img">
                    <img :src="`${baseUrl + item.imgUrl}`"alt="">
                  </div>
                  <div class="right">
                    <div>
                      <div class="txt">
                        <h2 v-html="item[_i18n.locale == 'en' ? 'storeNameEn' : 'storeNameCn']"></h2>
                        <div class="info">
                            <div class="address row">
                                <p>{{item[_i18n.locale == 'en' ? 'addressEn' : 'addressCn']}}</p>
                            </div>
                            <div class="telephone row">
                              <div class="label"><p>{{$t('subPage.contactus.phone')}}: </p></div> <div class="value numL"><p class="numFont">{{item.telephone}}</p></div>
                            </div>
                            <div class="fax row">
                              <div class="label"><p>{{$t('subPage.contactus.fax')}}: </p></div> <div class="value numL"><p class="numFont">{{item.fax}}</p></div>
                            </div>
                            <div class="instruction row">
                                <p>{{item[_i18n.locale == 'en' ? 'instructionEn' : 'instructionCn']}}</p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactUs',
        data(){
            return {
                // 页面数据
                pageData: []
            }
        },
        methods: {
            // 获取页面数据
            async getPageData(){
                let res = await this.$http.get('/web/liaison')
                console.log(res.data.tdTermsPage, '联系我们数据')
                this.pageData = res.data.tdTermsPage
            }
        },
        created() {
            this.getPageData()
        },
    }
</script>

<style lang="less">
    #Contact-Us{
        min-height: 600px;
        .banner{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
            .floting{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                .title{
                    p{
                        font-size: 1.175rem;
                    }
                }
                .subtitle{
                    p{
                        font-size: .45rem;
                    }
                }
            }
        }
        .wrap{
            padding: 50px 0;
          .hd{
              text-align: center;
              .title{
                  font-size: .6rem;
                  color: #000;
              }
              .bm{
                  width: 2.725rem;
                  height: .1125rem;
                  background: @colorMain;
                  border-radius: .05rem;
                  margin: .0625rem auto;
                  margin-top:-0.1rem;
              }
              .subtitle{
                  font-size: .225rem;
                  font-weight: 400;
                  color: rgba(179, 179, 179, 1);
              }
          }
          .bd{
              margin-top:1.25rem;
              .container{
                  display: flex;
                  flex-wrap: wrap;
                      .block{
                        display: flex;
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        height: fit-content;
                        min-height: 455px;
                        justify-content: space-between;
                        align-items: center;
                        .img{
                              position: relative;
                              height: 100%;
                          img{
                              width: 100%;
                              height: 100%;
                          }
                        }
                        .right{
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          width: 60%;
                          height: 100%;
                        }
                         .joinBtn{
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: fit-content;
                          padding: 0px 20px;
                          background: @colorMain;
                          border-radius: .1875rem;
                          text-align: center;
                          line-height: .625rem;
                          color: #fff;
                          font-size: .225rem;
                          .heffect();
                          margin-top: 40px;
                          span{
                            font-family: 'SourceHanSansCN-Medium';
                          }
                        }
                        .txt{
                            position: relative;
                            text-align: left;
                            h2{
                                padding-left: .25rem;
                                border-left: .0625rem solid @colorMain;
                                margin-bottom: .25rem;
                                .flex();
                                font-size: 24px;
                                p{
                                  margin-right: .125rem;
                                  font-size: .3rem;
                                  height: .3rem;
                                  line-height: .4375rem;
                                  color: #000;
                                }
                            }
                            .text{
                                p{
                                  margin-top: .1875rem;
                                  line-height: .375rem;
                                  text-align: justify;
                                  span{
                                    font-size: .225rem;
                                    text-align: justify;
                                    font-family: "Roboto-Light";
                                    color: #666666 !important;
                                    overflow:hidden; 
                                    text-overflow:ellipsis;
                                    display:-webkit-box; 
                                    -webkit-box-orient:vertical;
                                    -webkit-line-clamp:6; 
                                  }
                                }
                            }
                            .info{
                              margin-top: 15px;
                                .row{
                                    display: flex;
                                    align-items: center;
                                    p{
                                        font-size: 18px;
                                        color: #333;
                                        text-align: left;
                                    }
                                    .label{
                                      margin-right: 5px;
                                    }
                                    .value{
                                      /* margin-bottom: -2px; */
                                    }
                                }
                                .instruction, .telephone{
                                  margin-top: 30px;
                                  align-items: center;
                                }
                            }
                        }
                      }
                    .block:nth-child(2n + 1){
                      .img{
                        width: 50%;
                      }
                      .right{
                        width: 35%;
                      }
                    }
                    .block:nth-child(2n + 2){
                      margin-top: -20px;
                      .img{
                        position: absolute;
                        right: 0;
                        width: 40%;
                      }
                      .right{
                        position: absolute;
                        left: 0;
                        width: 30%;
                      }
                    }
                    .block:first-child{
                      align-items: flex-start;
                      .right{
                        align-items: flex-start;
                      }
                    }
                    .block:last-child{
                      /* align-items: flex-end; */
                      margin-top: -15px;
                      .right{
                        /* align-items: flex-end; */
                      }
                    }
              }
          }
        }
    }
</style>