<template>
    <div id="Register"  :style="`background-image: url( ${bgImg} )`">
        <div class="wrap pubw" :class="alretShow?'blur':''">
            <div class="hd">
                <h1 class="title">{{$t('register.title')}}</h1>
            </div>
            <div class="bd mobile-max-height">
                <div class="tips">
                    <p>{{$t('register.tips')}}</p>
                </div>
                <el-form :model="form" hide-required-asterisk :rules="formRules" ref="ruleForm" >
                    <el-form-item :label="$t('register.name') + ':' " prop="memberName">
                      <el-input v-model="form.memberName"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('register.psd') + ':' " prop="memberPassword">
                        <el-input type="password" v-model="form.memberPassword"></el-input>
                      </el-form-item>

                    <el-form-item :label="$t('register.phone') + ':' " prop="memberMobil">
                        <el-input v-model="form.memberMobil"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('register.comfirmPsd') + ':' " prop="memberConfirmPassword">
                        <el-input type="password" v-model="form.memberConfirmPassword"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('register.email') + ':' " prop="memberEmail">
                        <el-input v-model="form.memberEmail"></el-input>
                    </el-form-item>
             
                    
                    <el-form-item :label="$t('register.address') + ':' " prop="address">
                        <el-input v-model="form.address"></el-input>
                    </el-form-item>
                </el-form>
                <div class="bottom">
                    <div class="options">
                        <div class="prev btn pubBtnMed" @click="()=>{$router.go(-1)}">
                            <p>{{$t('register.prevBtn')}}</p>
                        </div>
                        <div class="login btn pubBtnMed" @click="login">
                            <p>{{$t('register.loginBtn')}}</p>
                        </div>
                        <div class="register btnMain pubBtnMed" @click="register">
                            <p>{{$t('register.registerBtn')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 浮窗 -->
        <div class="alret" v-show="alretShow">
            <div class="container">
                <p class="tips">{{$t("register.emailAlert.tips")}}</p>
                <div class="options">
                    <div class="cancel btn" @click="alretShow = false">
                        <p>{{$t("register.emailAlert.cancel")}}</p>
                    </div>
                    <div class="complate btnMain" @click="confirmRg">
                        <p>{{$t("register.emailAlert.complate")}}</p>
                    </div>
                </div>
            </div>
        </div>

        <Foot/>
    </div>
</template>

<script>
    // 引入loding组件
import { Loading } from 'element-ui';
import Foot from '@/components/Foot'
export default {
    name: "Register",
    components: {
        Foot
    },
    data(){

            // 验证邮箱正则
            let checkEmail = (rule,value,callback)=>{
                // const regEmail=/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
                // 自定义正则
                const regEmail = /^[A-Za-z0-9/.]+@+[A-Za-z0-9*]+[/.A-Za-z0-9]*$/
                if(!regEmail.test(value)) return callback(new Error(this.$t('register.emailErr')))
                return callback()
            }

            // 验证电话正则
            let checkMobile = (rule,value,callback)=>{
            const regMobile= /^\d{8}|\d{11}$/
            // const  regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            if(!regMobile.test(value)) return callback(new Error(this.$t('register.phoneErr')))
            return callback()
        }

        // 验证密码
            var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error(this.$t('register.psdComfirmErr')));
            } else if (value !== this.form.memberPassword) {
            callback(new Error(this.$t('register.psdComfirmErr')));
            } else {
            callback();
            }
        };
        return {
            // 注册表单
            form: {
                memberName: '',
                memberMobil: '',
                memberEmail: '',
                address: '',
                memberPassword: '',
                memberConfirmPassword: ''
            },
            // 表单规则
            formRules:{
                memberName: [
                    { required: true, message: this.$t('register.plsEntryName'), trigger: 'blur' },
                    { min: 1, max: 50, message:  this.$t('register.nameLen'), trigger: 'blur' }
                ],
                memberMobil:[
                    {
                    required: true,
                    validator: checkMobile,
                    trigger: "blur",
                    },
                ],
                memberEmail: [
                    {
                    required: true,
                    validator: checkEmail,
                    trigger: "blur",
                    },
                ],
                memberPassword:[
                    { required: true, message: this.$t('register.psdErr'), trigger: 'blur' },
                    { min: 6, max: 20, message: this.$t('register.psdLen'), trigger: 'blur' }
                ],
                memberConfirmPassword:[
                    { required: true, validator: validatePass2, trigger: 'blur' },
                    { min: 6, max: 20, message: this.$t('register.ptyPism'), trigger: 'blur' }
                ],
                address:[
                    { required: true, message: this.$t('register.addressErr'), trigger: 'blur' },
                ],
            },
            // 背景图片
            bgImg:  process.env.BASE_URL + "bgImg/member.png",
            // 控制确认浮窗的展示
            alretShow: false,
            // 确认注册的验证码
            verify:""
        }
    },
    methods: {
        // 注册
        register(){
            this.$refs.ruleForm.validate(async valid => {
                if(!valid) return
                let loadingInstance = Loading.service();
                // 通过验证发送注册请求
                let res = await this.$http.post("/web/add/member",{
                    ...this.form
                })
                console.log(res,"注册结果")
                this.verify = res.data.verify
                if(res.data.code == 0){
                    this.alretShow = true
                    loadingInstance.close();
                    this.$message.success(res.data.msg)
                }else{
                    loadingInstance.close();
                    this.$message.error(res.data.msg)
                }
            })
        },
        // 监听用户店家登录
        login(){
            this.$router.push("/Login")
        },
        // 监听用户点击确认注册
        async confirmRg(){
            let res = await this.$http.post(`/web/verify/member/${this.verify}`)
            console.log(res,"注册验证结果")

            if(res.data.code == 0){
                this.$message.success(this.$t("tips.registerSus"))
               
               // 将Token放到sesstion里
               window.sessionStorage.setItem("token",res.data.token)
               this.$router.push("/Shop/Coupon")
               window.sessionStorage.setItem("isPlace", true)
               this.$router.push('/login')
            }
            if(res.data.code == 500) return this.$message.error(res.data.msg)
        }
    },
    mounted() {
        document.onkeydown = (e) => {
        if(e.code=='Enter'){
          this.register()
        }
      }
    },
}
</script>

<style lang="less">
    #Register{
        width: 100%;
        height: 100%;
        background-color: #ccc;
        background-repeat: no-repeat;
        background-size: cover;
        .wrap{
            padding-top: 170px;
            padding-bottom: 120px;
            .hd{
                text-align: center;
                border-bottom: .0125rem solid rgba(255, 255, 255, 0.5);
                padding-bottom: .25rem;
                .title{
                        font-size: .6rem;
                        color: #fff;
                }
            }
            .bd{
                margin-top: .625rem;
                .tips{
                   p{
                        text-align: left;
                        color: @colorMain;
                        font-size: .2rem;
                   }
                }
                .el-form{
                    margin-top: .375rem;
                    .flexbc();
                    flex-wrap: wrap;
                    .el-form-item{
                        width: 40%;
                        margin: .25rem 0;
                        .el-form-item__content{
                            display: flex;
                        }
                        .el-form-item__label{
                            font-size: .25rem;
                            text-align: left;
                            white-space: nowrap;
                            color: #000;
                            font-size: 24px;
                            color: #fff;
                            width: fit-content;
                        }
                        .el-input__inner{
                            border: none;
                            border-radius: 0rem;
                            font-size: 24px;
                            background: none;
                            color: #fff;
                            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                        }
                    }
                }
            }
            .bottom{
                .options{
                    .flexcc();
                    .prev,.login,.register{
                        margin: 80px 1.5625rem;
                        .heffect();
                        p{
                            font-size: .225rem;
                        }
                    }
                    .login,.prev{
                        p{
                            color: #000;
                        }
                    }
                }
            }
        }
        .wrap.blur{
            filter: blur(.1875rem);
        }
        .alret{
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            .flexcc();
            flex-wrap: wrap;
            .container{
                width: 11.25rem;
                height: 3.75rem;
                background: rgba(255, 255, 255, 0.5);
                border: 0rem solid #FFFFFF;
                border-radius: .325rem;
                z-index: 20;
                .tips{
                    margin-top: .75rem;
                    font-size: .25rem;
                    color: #fff;
                }
                .options{
                    margin-top: 1.25rem;
                    .flexcc();
                    .btn{
                        margin-right: 1.25rem;
                    }
                    .btnMain,.btn{
                        p{
                            font-size: .225rem !important;
                        }
                    }
                    .login,.prev{
                        p{
                            color: #000;
                        }
                    }
                }
            }
        }
    }
    .el-form-item__error{
        font-size: 18px;
    }
</style>