import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

import zh from './langs/cn-zh'
import en from './langs/en'
import hk from './langs/cn-hk'

const language = new VueI18n({
    locale: window.localStorage.getItem("language") != null ? window.localStorage.getItem("language") : 'hk',
    messages: {
        hk,
        en,
        zh
    }
})

export default language