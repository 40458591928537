import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
// 样式
import '@/assets/css/app.css'

// rem适配
import '@/assets/js/rem.js'

import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);

//引入多语言支持
import i18n from './language'
import './assets/css/index.css'
import './assets/css/fonts.css'

//引入swiper相关文件
import 'swiper/dist/css/swiper.css'
import 'swiper/dist/js/swiper.min'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

// 引入视频相关文件
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


const originalPush = VueRouter.prototype.push
// 重写了原型上的push方法，统一的处理了错误信息
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 创建全局事件总线
Vue.prototype.$bus = new Vue();

import './utils/http.js'

// 是否为移动端
if(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
  Vue.prototype.isMobile = true
}else{
  Vue.prototype.isMobile = false
}
Vue.prototype.baseUrl = 'https://hd-eshop.com.hk/hunter-douglas-api'
// vue.prototype.patj = /
// 调试
import eruda from 'eruda'
// eruda.init()
new Vue({
  router,
  i18n,   //挂载i18n
  render: h => h(App),
  mounted() {
    // console.log(this.$cookie)
  },
}).$mount('#app')
