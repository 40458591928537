<template>
    <div class="Login" :style="`background-image: url( ${bgImg} )`">
      <div class="content">
        <h1 class="title">{{$t('login.title')}}</h1>
        <el-form :model="form" ref="loginForm" class="login-form" autocomplete="off" :rules="formRule">
          <el-form-item prop="memberEmail">
            <div class="icon">
              <img src="../../assets/imgs/user.png" alt="">
            </div>
              <el-input :placeholder="$t('login.email')"  v-model="form.memberEmail" ></el-input>
          </el-form-item>
          <el-form-item class="password" prop="memberPassword">
            <div class="icon">
              <img src="../../assets/imgs/lock.png" alt="">
            </div>
            <el-input type="password" v-model="form.memberPassword" :placeholder="$t('login.psd')"
            ></el-input>
          </el-form-item>
          <div class="forgetPsd">
            <span  @click="forgetPsd">{{$t('login.forget')}}</span>
          </div>
          <el-form-item class="actions">
            <el-button class="register pubBtnMed" @click="goRegister">{{$t('login.register')}}</el-button>
            <el-button class="login pubBtnMed" @click="goLogin">{{$t('login.login')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Login",
    data() {

          // 验证邮箱正则
          let checkEmail = (rule,value,callback)=>{
            // const regEmail=/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
            // 自定义正则
            const regEmail = /^[A-Za-z0-9/.]+@+[A-Za-z0-9*]+[/.A-Za-z0-9]*$/
            if(!regEmail.test(value)) return callback(new Error(this.$t('login.emailErr')))
            return callback()
          }

      return {
        // 背景图片
        bgImg: process.env.BASE_URL + "bgImg/member.png",
        // 表单信息
        form: {
          memberEmail: '',
          memberPassword: '',
        },
        // 表单规则
        formRule: {
          memberEmail: [
            {
              required: true, message: this.$t('login.emailErr')
            },
            {
                    required: true,
                    validator: checkEmail,
                    trigger: "blur",
                    },
          ],
          memberPassword: [
            {
                required: true, message: this.$t('login.psdErr')
            }
          ]
        }
      }
    },
    methods: {
      // 跳转登录
      goRegister() {
        this.$router.push('/register');
      },
      // 登录
      goLogin() {
        this.$refs.loginForm.validate(async valid => {
          if(!valid) return 
            let res = await this.$http.post("/web/member/login",{
            ...this.form
          })
          console.log(res,"登录结果")
          
          if(res.data.msg == "success"){
            this.$message.success(this.$t("tips.loginSus"))
            // 将Token放到sestion里
            window.sessionStorage.setItem("token",res.data.token)

            // 如果是下单跳转过来的,注册成功就跳转回去
            if(window.sessionStorage.getItem("isPlace") !== null) {
                this.$router.push("/Shop/Order")
                window.sessionStorage.removeItem('isPlace')
            }else{
              this.$router.push("/Member")
            }
          }else{
            this.$message.error(res.data.msg)
          }
        })
      },
      // 监听用户的点击忘记密码
      forgetPsd(){
        this.$router.push("/ForgetPsd")
      }
    },
    mounted() {
      document.onkeydown = (e) => {
        if(e.code=='Enter'){
          this.goLogin()
        }
      }
    }
  }
  </script>
  
  <style lang="less">
  .Login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    .content {
      width: 530px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      .title {
        text-align: center;
        font-size: 48px;
        color: white;
        margin-bottom: 80px;
      }
  
  
      .el-select {
        width: 100%;
      }
  
      .actions {
        padding-top: 50px;
  
        .el-button {
          width: 160px;
          height: 70px;
          border-radius: 20px;
          font-size: 20px;
          border: 0;
          text-align: center;
          font-family: 'SourceHanSansCN-Medium';
          &.register {
            color: #333333;
            .heffect();
            background-color: rgba(255, 255, 255, 0.6);
            *{
              font-family: 'SourceHanSansCN-Medium' !important; 
            }
          }
          span{
            font-size: .25rem;
          }
          &.login {
            background: rgba(237, 130, 32, 0.6);
            color: #fff;
            .heffect();
            margin-left: 80px;
           *{
            font-family: 'SourceHanSansCN-Medium' !important;
           }
          }
        }
  
      }
  
    }
  }
  
  </style>
  <style lang="less">
    .el-input__icon{
        height: auto !important;
    }
  .Login {
    .el-input__inner {
      border: 0;
      border-bottom: 1px solid rgba(255,255,255,.3);
      border-radius: 0;
      font-size: .3rem;
      padding-left: .625rem;
      margin-bottom: .375rem;
      color: #fff;
      background: none !important;
    }
    .el-input__prefix {
      font-size: .325rem;
    }
    .el-input__icon,.el-icon-user{
        font-size: .25rem;
    }
    .el-input__suffix {
      height: auto;
    }
    .actions .el-form-item__content {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    .el-form-item,.el-form-item__content{
      position: relative;
      height: 60px;
      .el-input{
        height: 100%;
        .el-input__inner{
          height: 100%;
          margin-bottom: 0;
          font-size: 24px;
          padding: 0 55px;
        }
      }
      .icon{
        position: absolute;
        top: 8px;
        left: 10px;
        width: 40px;
        height: 40px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  input:-internal-autofill-previewed,
  input:-internal-autofill-selected {
    -webkit-text-fill-color: #FFFFFF !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
  .forgetPsd{
    text-align: right;
    span{
      color: #fff;
      font-size: 18px;
      font-family: 'SourceHanSansCN-Light';
      .heffect();
    }
  }
  </style>
  