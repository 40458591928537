<template>
  <div id="ContactUs">
        <!-- 容器 -->
        <div class="wrap pubw">
          <!-- 头部 -->
          <div class="hd">
              <h1 class="title">{{$t('swiperShow.consultion.title')}}</h1>
              <div class="bm"></div>
              <div class="subtitle">Free consultation</div>
          </div>

          <!-- 主体 -->
          <div class="bd mobile-max-height">
            <div class="img">
              <img src="./img/fc1.jpg" alt="">
            </div>
            <div class="form">
                <p class="message">{{$t('swiperShow.consultion.tips')}}</p>
                <el-form ref="form" :model="form"  class="free-consult-f" status-icon  :rules="rules">
                  <el-form-item prop="name">
                    <el-input v-model="form.name" :placeholder="$t('swiperShow.consultion.name')"></el-input>
                  </el-form-item>
                  <el-form-item prop="email">
                    <el-input v-model="form.email" :placeholder="$t('swiperShow.consultion.email')"></el-input>
                  </el-form-item>
                  <el-form-item prop="phone">
                    <el-input v-model="form.phone" :placeholder="$t('swiperShow.consultion.phone')"></el-input>
                  </el-form-item>
                  <el-form-item prop="message">
                    <el-input type="textarea" class="texa" v-model="form.message" :placeholder="$t('swiperShow.consultion.proposal')"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button   class="submit pubBtnMed" @click="sendMessage">{{$t('swiperShow.consultion.btn')}} ></el-button>
                  </el-form-item>
                </el-form>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Header from '@/components/Header'
// 引入loding组件
import { Loading } from 'element-ui';
export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  data(){
    return {
      // 表单
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      // 表单规则
      rules: {
        name: [
          {required: true, message: this.$t('subPage.consultion.plsName'), trigger: 'blur'},
        ],
        email: [
          {required: true, message: this.$t('subPage.consultion.plsEmail'), trigger: 'blur'},
          {
            pattern: /^[A-Za-z0-9/.]+@+[A-Za-z0-9*]+[/.A-Za-z0-9]*$/,
            message: this.$t('subPage.consultion.plsCEmail'),
            trigger: 'blur'
          }
        ],
        phone: [
          {required: true, message: this.$t('subPage.consultion.plsPhone'), trigger: 'blur'},
          {
            pattern:  /^\d{8}|\d{11}$/,
            message:  this.$t('subPage.consultion.plsCPhone'),
            trigger: 'blur'
          }
        ],
        message: [
          {required: true, message:  this.$t('subPage.consultion.plsIs'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    // 发送消息
    sendMessage(){
      this.$refs.form.validate(async valid => {
        if(!valid) return
        let loadingInstance = Loading.service();
        let res = await this.$http.post("/web/send/msg",{
          ...this.form
        })
        if(res.data.code == 0){
          loadingInstance.close();
          this.$message.success(this.$t('subPage.consultion.sendSu'))
        }else{
          loadingInstance.close();
          this.$message.error(res.data.msg)
        }
      })
    }
  },
}
</script>

<style lang="less">
  #ContactUs{
    width: 100%;
    height: 100%;
    background: url("img/freeConsult.png");
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    .wrap{
      padding-top: 100px;
      padding-bottom: 100px;
      .hd{
          text-align: center;
          .title{
            font-size: .6rem;
          }
          .bm{
              width: 218px;
              height: 9px;
              background: @colorMain;
              border-radius: 4px;
              margin:5px auto;
              margin-top:-8px;
          }
          .subtitle{
              font-size: 18px;
              font-weight: 400;
              color: #B3B3B3;
        }
      }
      .bd{
        text-align: left;
        margin-top: 38px;
        .flex();
        .img{
          width: 50%;
          height: 6.9125rem;
          margin-right: 100px;
          img{
            width: 100%;
            height: 100%;
            box-shadow: -0.3125rem .3125rem 0rem rgba(121,123,121,.5);
          }
        }
        .form{
          width: 50%;
          .message{
            position: relative;
            padding-left:10px;
            padding-left: 0.052083;
            line-height: .375rem;
            font-weight: 550;
            font-size: .2875rem;
            text-align: left;
            font-family: 'SourceHanSansCN-Light';
          }
          .message:before {
            content: '';
            display: block;
            height: 28px;
            width: .0625rem;
            left: -5px;
            position: absolute;
            background-color: @colorMain;
            border-radius: 6px;
            width: 6px;
          }
          .free-consult-f{
            margin-top: .25rem;
            height: 91%;
            .flexbc();
            flex-wrap: wrap;
            .el-form-item {
              width: 100%;
              .el-form-item__content{
                width: 100%;
              }
              .el-input{
                width: 100%;
              }
              .texa{
                textarea{
                  border: 1px solid rgba(255,255,255,0.3);
                  border-radius: 10px;
                  height: 1.475rem;
                  max-height: 1.475rem;
                  min-height: 1.475rem;
                  resize: none;
                }
              }
            }
          }
        }
      }
    }
    .el-input__inner,.el-textarea__inner{
      border: none;
      border-bottom: 0.005208rem solid rgba(255,255,255,0.3);
      background-color: transparent;
      border-radius: 0;
      color: #CCCCCC;
      font-size: .2rem;
      font-family: 'SourceHanSansCN-Light';
    }
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 16px 26px ;
        background: rgba(237, 130, 32, 0.6);
        border-radius: .0625rem;
        border: none;
        color: #fff;
        font-size: .2rem;
        border-radius: .1875rem;
        *{
          font-size: 20px;
          font-family: 'SourceHanSansCN-Medium';
        }
    }
  }
</style>
