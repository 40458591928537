<template>
    <div id="Footer">
        <!-- 容器 -->
        <div class="wrap pubw">

            <!-- 上部分 -->
            <div class="up">

                <!-- 每一块 -->
                <div :class="['block', index == activeIndex ? 'active' : '']" v-for="(item, index) in sectionList" :key="item.id" @click.slef="changeActiveIndex(index)">
                    <h4>{{item[_i18n.locale == 'en' ? 'titleEn' : 'title']}}

                      <span class="icon el-icon-arrow-right" v-if="isMobile"></span>
                    </h4>
                    <ul class="children" ref="block">
                        <li v-for="subItem in item.children" :key="subItem.id">
                            <p @click="navigator(subItem)">{{subItem[_i18n.locale == 'en' ? 'textEn' : 'text']}}</p>
                        </li>
                    </ul>
                </div>

            </div>

            <!-- 下部分 -->
            <div class="down">
                <div class="logo">
                  <img src="./img/logoLight.png" alt="" @click="$router.push('/')">
                </div>
                <div class="cr">
                    <p>Copyright @2023 Cherish Deco Ltd, All rights reserved.</p>
                </div>
                <div class="surround">
                    <div class="follow">
                        <p>關注我們</p>
                    </div>
                    <div class="icons">
                        <span class="iconfont icon-facebook-fill" @click='navigator("https://www.facebook.com/HunterDouglas.Luxaflex")'></span>
                        <span class="iconfont icon-instagram"></span>
                        <span class="iconfont icon-Youtube-fill" @click="navigator('https://www.youtube.com/user/Hunterdouglas')"></span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      // 当前激活展示的索引
      activeIndex: undefined,
      // 每个展开栏的初始高度
      ulHeight: [],
      // 底部内容
      sectionList: [
        {
          id: '0',
          title: '關於我們',
          titleEn: 'About Us',
          children: [
            {
              id: '0',
              text: '產品',
              textEn: 'Product',
              route: this.isMobile ? '/Product' : '/ProductStyle'
            },
            {
              id: '1',
              text: '關於公司',
              textEn: 'About Us',
              route: '/SubPage/Aboutus'
            },
          ]
        },
        {
          id: '1',
          title: '資源',
          titleEn: 'Resources',
          children: [
            {
              id: '0',
              text: '小冊子',
              textEn: 'Pamphlets',
              route: '/SubPage/Pamphlet'
            },
            {
              id: '1',
              text: '維修保養',
              textEn: 'Maintenance',
              route: '/SubPage/Maintain'
            },
            {
              id: '2',
              text: '條款及細則',
              textEn: 'Terms and conditions',
              route: '/Subpage/Clause'
            },
            {
              id: '3',
              text: '常見問題',
              textEn: 'FAQ',
              route: '/SubPage/Problem'
            }
          ]
        },
        {
          id: '2',
          title: '聯絡我們',
          titleEn: 'Contact Us',
          children: [
            {
              id: '1',
              text: 'Hunter Douglas 銅鑼灣店',
              textEn: 'Hunter Douglas 銅鑼灣店',
              route: '/Subpage/ContactUs'
            },
            {
              id: '2',
              text: 'Hunter Douglas 九龍灣店',
              textEn: 'Hunter Douglas 九龍灣店',
              route: '/Subpage/ContactUs'
            },
            {
              id: '3',
              text: 'Hunter Douglas 沙田店',
              textEn: 'Hunter Douglas 沙田店',
              route: '/Subpage/ContactUs'
            },
            {
              id: '4',
              text: 'Hunter Douglas 灣仔店',
              textEn: 'Hunter Douglas 灣仔店',
              route: '/Subpage/ContactUs'
            },
            {
              id: '5',
              text: 'Email: enquiry@hdgallery.com.hk',
              textEn: 'Email: enquiry@hdgallery.com.hk',
              link: "mailto:enquiry@hdgallery.com.hk"
            }
          ]
        }
      ]
    }
  },
  methods: {
    // 监听用户点击ul栏
    changeActiveIndex(index){
      if(window.sessionStorage.getItem('model') != 'mobile') return;
      if(index == this.activeIndex){
        this.activeIndex = undefined
        this.$refs.block[index].style.height = '0px'
        return
      }
      this.$refs.block[index].style.height = this.ulHeight[index] + 'px'
      this.activeIndex = index
    },
    // 跳转地址
    navigator(subItem){
      if(subItem.link){
        window.open(subItem.link)
      }else if(subItem.route){
        this.$router.push(subItem.route)
      }
    }
  },
  mounted() {
    this.$nextTick(()=> {
      if(window.sessionStorage.getItem('model') == 'mobile'){        
        this.$refs.block.forEach(element => {
          this.ulHeight.push(element.offsetHeight + 2)
          element.style.height = 0 + 'px'
        });
      }
    })
},
}
</script>

<style lang="less" scoped>
    #Footer{
        width: 100%;
        min-height: 6.1875rem;
        height: fit-content;
        background: #999999;
        color: #fff;
        z-index: 20;
        font-size: .2rem;
        padding-bottom: 81px;
        padding-top: 75px;
        .wrap{
          padding-top: 0px;
            .up{
                display: flex;
                justify-content: space-between;
                .block{
                  width: 33.33%;
                    padding-right: 1.25rem;
                    h4{
                        margin-bottom: .25rem;
                        text-align: left;
                    }
                    .children{
                       line-height:.4375rem;
                       li{
                           &:hover{
                               .heffect();
                           }
                           P{
                             text-align: left;
                             font-family: 'SourceHanSansCN-Light';
                             font-size: 18px;
                           }
                       }
                    }
                    &:last-child{
                        border-right: none;
                        padding-left: 1.25rem ;
                        padding-right: 0 ;
                    }
                }
                .block:nth-child(2){
                  padding-left: 1.25rem;
                  border-right: .0125rem solid #ccc;
                  border-left: .0125rem solid #ccc;
                }
            }
            .down{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .logo{
                  width: 257px;
                  height: 39px;
                  img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    .heffect();
                  }
                }
                .cr p{
                  font-family: 'SourceHanSansCN-Light';
                  padding-right: 120px;
                }
                .follow{
                    margin-right: .1875rem;
                }
                .surround{
                    display: flex;
                    justify-content: space-between;
                }
                .icons{
                    span{
                        font-size: .25rem;
                        margin-right: .0625rem;
                        .heffect;
                    }
                }
            }
        }
    }
</style>
