<template>
    <div class="Coupon" :style="`background-image: url( ${bgImg});`">
    <div class="box" >
      <label>{{$t('shop.coupon.label')}}：</label> <input class="input" v-model="number"> <el-button class="go-shop" @click="goShop"  icon="el-icon-right" circle></el-button>
    </div>
    <Foot/>
    </div>
    </template>
    
    <script>
    import Foot from '@/components/Foot'
    export default {
      name: "Coupon",
      components: {
        Foot
      },
      data(){
        return{
            // 优惠券号码
            number: '', //eozVhyfc
            // 背景图片
            bgImg: process.env.BASE_URL + "bgImg/shop.png",
        }
      },
      methods:{
        async goShop(){
          if(this.number.length == 0) return
          let res = await this.$http.get(`/web/service/plan/list?promotionCode=${this.number}`)
          console.log(res,"优惠券请求结果")
          if(res.data.length == 0) return this.$message.error(this.$t('shop.coupon.error'))

          window.localStorage.setItem('couponData', JSON.stringify({number: this.number, date: new Date()}))
          // this.$message.success(this.$t('shop.coupon.success'))
          this.$router.push({
            name: "OnlineChoice",
            params:{
              product:res.data,
              promotionCodeId:this.number
            }
          })
        },
        // 如果一小时内进过商店则不用再次输入
        preBack(){
          let coupon = JSON.parse(window.localStorage.getItem('couponData'))
          if(coupon){
            let now = new Date().getTime()
            let beforTime = new Date(coupon.date).getTime()
            if((now - beforTime) < 1 * 60 * 60 * 1000){
              this.number = coupon.number
              this.goShop()
            }
          }
        }
      },
      mounted() {
        document.onkeydown = (e) => {
          if(e.code=='Enter'){
            this.goShop()
          }
       }
      },
      created() {
        this.preBack()
      }
    }
    </script>
    
    <style   lang="less">
    .Coupon{
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      
      .box{
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        display: flex;
        align-items: center;
        width: fit-content;
        height: 80px;
        padding-right: 10px;
        padding-left: 38px;
        background: rgba(232, 232, 232, 0.14);
        border: 1px solid  rgba(232, 232, 255, 0.14);
        border-radius: 40px;
        font-size: 30px;
        color: #fff;
        label{
            font-size: 30px;
            flex-shrink: 0;
            font-family: Source Han Sans CN;
            opacity: .8;
            font-weight: 300;
        }
        .input{
          display: block;
          width: 5rem;
          border: none;
          background: rgba(232, 232, 232, 0.14);
          border: none;
          background-color: transparent;
          color: #fff;
          outline:none;
          border: none;
          height: .625rem;
          line-height: .625rem;
          font-size: .375rem;
          font-family: Source Han Sans CN;
        }
        .go-shop{
          width: .7375rem;
          height: .7375rem;
          .flexcc();
          flex-shrink: 0;
          border: none;
          background-color: #ED8220;
          font-size: .375rem;
          color: white;
          font-size: .3125rem;
          &:hover{
            transform: scale(1.1);
          }
        }
        .el-icon-right{
            font-size: .375rem;
          }
      }
    }
    </style>
    