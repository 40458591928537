import Vue from 'vue'
import VueRouter from 'vue-router'
// 滑动块内容区展示区
import SwiperShow from "@/views/SwiperShow/index.vue"

// 子页面详情区
import SubPage from "@/views/SubPage"
import IntellectDetail from '@/views/SubPage/IntellectDetail/index.vue'
import NewMessage from '@/views/SubPage/NewMessage/index.vue'
import ProductPDetails from '@/views/SubPage/ProductPDetails/ProductPDetails.vue'
import MeasureDetails from '@/views/SubPage/MeasureDetails/index.vue'
import FAC from "@/views/SubPage/FAC/index.vue"
import Clause from "@/views/SubPage/clause/Clause.vue"
import ContactUs from "@/views/SubPage/contactUs/ContactUs.vue"
import Pamphlet from "@/views/SubPage/pamphlet/index.vue"
import Aboutus from "@/views/SubPage/aboutus/index.vue"
import Maintain from "@/views/SubPage/maintain/index.vue"
import Problem from "@/views/SubPage/problem/index.vue"

// 商城
import Shop from "@/views/Shop"
import Coupon from '@/views/Shop/Coupon/index.vue'
import OnlineChoice from '@/views/Shop/OnlineChoice/index.vue'
import VR from '@/views/Shop/OnlineChoice/VR/index.vue'
import Vedio from '@/views/Shop/OnlineChoice/Vedio/index.vue'
import Order from '@/views/Shop/OrderDetails/index.vue'

// 用户中心
import Register from '@/views/Register/index.vue'
import Member from '@/views/Member/index.vue'
import Login from '@/views/Login/index.vue'
import ForgetPsd from '@/views/ForgetPsd/index.vue'

// 橱窗展示
import Window from "@/views/Window/index.vue"
import Sample from "@/views/Window/Sample/index.vue"

// 产品
import ProductStyle from "@/views/Product/Style.vue"
import Product from "@/views/Product/index.vue"

Vue.use(VueRouter)



const routes = [


  // fy-write
  {
    path: '/',
    redirect: '/SwiperShow'
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Register',
    component: Register,
  },
  {
    path: '/Member',
    name: "Member",
    component: Member,
  },
  {
    path: '/Shop',
    redirect: '/Shop/Coupon',
    component: Shop,
    children: [
      {
        path: '/Shop/Coupon',
        name: 'Coupon',
        component: Coupon,
      },
      {
        path: '/Shop/OnlineChoice',
        name: 'OnlineChoice',
        component: OnlineChoice,
      },
      {
        path: '/Shop/Order',
        name: "Order",
        component: Order,
      },
      {
        path: '/Shop/Vedio',
        name: 'Vedio',
        component: Vedio,
      },
      {
        path: '/Shop/VR',
        name: "VR",
        component: VR,
      },
    ]
  },
  {
    path: '/SwiperShow',
    name: 'SwiperShow',
    component: SwiperShow,
  },
  {
    path: '/SubPage',
    name: 'SubPage',
    component: SubPage,
    children: [
      {
        path: '/SubPage/ProductPDetails',
        name: 'ProductPDetails',
        component: ProductPDetails
      },
      {
        path: '/SubPage/IntellectDetail',
        name: "IntellectDetail",
        component: IntellectDetail,
      },
      {
        path: '/SubPage/NewMessage',
        name: 'NewMessage',
        component: NewMessage,
      },
      {
        path: '/SubPage/MeasureDetails',
        name: 'MeasureDetails',
        component: MeasureDetails,
      },
      {
        path: '/SubPage/FAC',
        name: 'FAC',
        component: FAC,
      },
      {
        path: '/SubPage/Clause',
        name: 'Clause',
        component: Clause
      },
      {
        path: '/SubPage/ContactUs',
        name: 'ContactUs',
        component: ContactUs,
      },
      {
        path: '/SubPage/Pamphlet',
        name: 'Pamphlet',
        component: Pamphlet,
      },
      {
        path: '/SubPage/Aboutus',
        name: 'Aboutus',
        component: Aboutus,
      },
      {
        path: '/SubPage/Maintain',
        name: 'Maintain',
        component: Maintain,
      },
      {
        path: '/SubPage/Problem',
        name: 'Problem',
        component: Problem,
      },
    ]
  },

  {
    path: '/Window',
    name: 'Window',
    component: Window,
    redirect: 'Window/Sample',
    children: [
      {
        path: '/Window/Sample',
        name: 'Sample',
        component: Sample,
      },
    ]
  },

  {
    path: '/ProductStyle',
    name: 'ProductStyle',
    component: ProductStyle,
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/ForgetPsd',
    name: 'ForgetPsd',
    component: ForgetPsd,
  }


]



const router = new VueRouter({
  routes
})


// 路由跳转控制滚动条位置
router.scrollBehavior = (to, form, savedPosition) => {
  return savedPosition || { x: 0, y: 0 }
}

export default router
