<template>
    <div id="Date">
         <!-- 展示区 -->
        <div :class="['date-editor', disable ? 'disable' : 'active']"  @click="!disable && handlerIptFocus()" >
            
            <div class="icon" v-if="!disable">
                <img src="../../assets/imgs/calendar.png" alt="">
            </div>
            <input type="text" 
                :placeholder="placeholder" disabled="disabled" class="input__inner numFont" :value="showDate" @focus="!disable && handlerIptFocus()" @blur="!disable && handlerIptBlur()" >
        </div>
        <!-- 容器 -->
        <div class="wrapper" v-show="pickerShow">
            <!-- 头部 -->
            <div class="header">
                <div class="prev" @click="handlerPrevtmonth">
                    <p><</p>
                </div>
                <div class="date">
                    <p class="year numL">{{year}}{{$t('date.year')}}</p>
                    <p class="numL">{{month}}{{$t('date.month')}}</p>
                </div>
                <div class="next" @click = "handlerNextmonth">
                    <p>></p>
                </div>
            </div>
            <!-- 主体 -->
            <div class="content">
                <thead>
                    <tr class="tr">
                        <th v-for="(th,index) in thList" :key="index"><span class="numFont">{{th}}</span></th>
                    </tr>
                </thead>
                <tbody >
                    <tr class="tr" v-for="(days,index) in dayList" :key="index" v-if="dayList[index].length != 0">
                        <td v-for="(day,index) in days" :key="index" @click="handlerClickDay(day)" :class="[curDate == day.shortDate?'active':'',day.month == month?'':'off',day.disabel?'disabel':'', 'numL']"><span class="numL">{{day.day}}</span></td>
                    </tr>
                </tbody>
            </div>
            <!-- 选择时间 -->
            <div class="time">
                <div class="label"><span>{{$t('date.plsTime')}}</span></div>
                <div class="startTime">
                    <el-time-select
                    v-model="startTime"
                    :picker-options="startTimeOption"
                    :clearable="false"
                    placeholder="选择时间"
                    @change="handlerChangeStartTime">
                  </el-time-select>
                </div>
                <div class="endTime disabled">
                    <input type="text" :value="endTime" disabled>
                </div>
            </div>
            <!-- 确认按钮 -->
            <div class="complate" @click="complate">
                <span>{{$t('date.comfirm')}}</span>
            </div>
        </div>
        <!-- 遮罩 -->
        <div class="mask" @click="complate" v-show="pickerShow"></div>
    </div>
</template>

<script>
    import formtDate from "@/assets/js/formatDate"
    import formatTime from "@/assets/js/formatTime"
    // 引入时间选择器
    import TimePicker from "@/components/TimePicker"

    export default {
        name: 'Date',
        components: {
            TimePicker
        },
        // 接受配置对象
        props: ["startDateDisabel", "placeholder", "updateDate", "defaultTime", "timeStep", "isclear", 'disable'],
        data(){
            return {
                year: 0,
                month: 0,
                week: 0,
                day: 0,
                // 表头
                thList: [ 
                    this.$t('date.Sun'), 
                    this.$t('date.Mon'), 
                    this.$t('date.Tues'), 
                    this.$t('date.Wed'), 
                    this.$t('date.Thur'), 
                    this.$t('date.Fri'),
                    this.$t('date.Sat')
                ],
                // 表格
                dayList: [],
                curDate: '',
                startTime: '11:00',
                endTime: '12:00',
                // 控制选择器是否展示
                pickerShow: false,
                // 禁用时间
                startTimeOption: {
                    start: '11:00',
                    step: '1:00',
                    end: '17:00'
                },
                endTimeOption: {
                    start: '11:00',
                    step: '1:00',
                    end: '17:00'  
                }
            }
        },
        methods: {
            // 监听用户选择下一月
            handlerNextmonth(){ 
                if(this.month >= 12){
                    this.month = 1
                    if(this.year == new Date().getFullYear()){
                        this.year = this.year += 1
                    }
                }else{
                    this.month ++ 
                }
                const days = this.getDaysInmonth( this.year, this.month)
                this.createDayinDays(days,this.getWeekInMonth( this.year, this.month),this.getDaysInmonth(this.year,this.month-1 || 12))
            },
            
            // 监听用户选择上一月
            handlerPrevtmonth(){
                if(this.month <= 1){
                    this.month = 12
                    if(this.year != new Date().getFullYear()){
                        this.year = this.year -= 1
                    }
                }else{
                    this.month -- 
                }
                const days = this.getDaysInmonth(this.year, this.month)
                this.createDayinDays(days,this.getWeekInMonth( this.year, this.month),this.getDaysInmonth(this.year,this.month-1 || 12))
            },
            
            // 初始化日期
            init(){
                console.log(new Date('2022-2-25 0:0:0'.replace(/-/g, '/')).getTime(), "时间")
                const date = this.startDateDisabel || new Date()
                this.year = date.getFullYear()
                this.month = date.getMonth() + 1
                this.week = date.getDay()
                this.day = date.getDate()
                const days = this.getDaysInmonth( this.year, this.month)
                
                // 默认时间
                if(this.defaultTime == '' || this.defaultTime == undefined || typeof this.defaultTime == 'undefined'){

                }else{
                    this.curDate = this.defaultTime.date
                    this.startTime = this.defaultTime.time
                    this.endTime = this.defaultTime.endTime
                }
                // 创建当前月的天列表
                this.createDayinDays(days,this.getWeekInMonth( this.year, this.month),this.getDaysInmonth(this.year,this.month-1 || 12))
            },
            
            // 根据月份获取天数
            getDaysInmonth(year,month){
                return new Date(year,month,0).getDate()
            },
            
            // 根据月份获取第一天是周几
            getWeekInMonth(year,month){
                return new Date(new Date(year,month,0).setDate(1)).getDay()
            },
            
            // 校验短日期是否可选
            validateDisabe(date){
                if(this.startDateDisabel == '' || typeof this.startDateDisabel == 'undefined'){
                    // 如果没有限制开始日期
                    return new Date(date).getTime() >= new Date(new Date().getFullYear(), new Date().getMonth(),new Date().getDate())? false : true
                }else{
                    // console.log(new Date(formtDate(this.startDateDisabel).date).getTime())
                    // return new Date(date).getTime() >= this.startDateDisabel.getTime()? false : true
                    return new Date(date).getTime() >= new Date(formtDate(this.startDateDisabel).spriteDate.replace(/-/g, '/')).getTime()? false : true
                }
            },
            
            // 根据天数月的第一天 上个月有多少天创建出day
            createDayinDays(days, fristDay, prevMonthDays){
                let arr = [] //最终创建出的日历数据

                let flag = 0 //有多少条
                // 创建出当前整个月的数组
                for(let a = 0;a < 8 && flag < 42;a++){
                    let row = []
                    for(let b = 0;b < 7 && flag < 38;b++){
                        flag++
                        let day = {}
                        if(fristDay + 1 > flag){
                            // 上个月的天
                            day.day = prevMonthDays - fristDay  + flag
                            day.month = this.month
                            if(this.month == 1){
                                 day.month = 12
                            }else{
                                 day.month --
                            }
                            day.shortDate = this.year  + "/" + day.month  + "/" +  day.day
                            day.disabel = this.validateDisabe(day.shortDate)
                        }
                        else if(flag > days + fristDay){
                            // 下个月的天
                            day.day = flag - days - fristDay
                            day.month = this.month
                            if(day.month == 12){
                                day.month = 1
                            }else{
                                day.month++
                            }
                            day.shortDate = this.year  + "/" + day.month  + "/" +  day.day
                            day.disabel = this.validateDisabe(day.shortDate)
                        }else{
                            // 当前月的天
                            day.month = this.month
                            day.day = flag - fristDay
                            day.shortDate = this.year  + "/" + day.month  + "/" +  day.day
                            day.disabel = this.validateDisabe(day.shortDate)
                        }
                        row.push(day)
                    }
                    arr.push(row)
                }
                this.dayList = arr
            },
           
            // 监听用户点击天
            handlerClickDay(day){
                // 如果是禁止日期就return
                if(day.disabel) return 
                if(day.month == this.month){
                    // 点击的是当前这个月的时间
                    this.curDate = day.shortDate

                    // 如果是当天,就禁用只能选择当前以后的时间
                    const date = day.shortDate.split('/')
                    const selectDate = new Date(date[0], date[1], date[2])
                    const now = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())

                    if(now.getTime() == selectDate.getTime()){
                        let date = new Date()
                        this.startTimeOption.start = formatTime(date.getHours() + '' + date.getMinutes()).time
                        this.endTimeOption.end = formatTime((date.getHours() + 2) + '' + date.getMinutes()).time
                    }
                }else{
                    this.month = day.month -1
                    this.curDate = day.shortDate
                    this.month >= day.month ? this.handlerPrevtmonth() : this.handlerNextmonth()
                }
            },
            
            // 计算+n个月的时间
            addMonth(date, months) {
                var datearr = date.split("/");
                var y = parseInt(datearr[0]);
                var m = parseInt(datearr[1][0] == 0 ? datearr[1][1] : datearr[1]) - 1;
                var d = parseInt(datearr[2][0] == 0 ? datearr[2][1] : datearr[2]);

                y += Math.floor((m + months) / 12); //计算年
                m = Math.floor((m + months) % 12) + 1; //计算月
                var d_max = new Date(y + "/" + (m + 1) + "/0").getDate();  //获取计算后的月的最大天数
                if (d > d_max) {
                    d = d_max;
                }

                return (y + "/" + (m < 10 ? ("0" + m) : m) + "/" + (d < 10 ? ("0" + d) : d));
            },
            
            // 监听用户点击确认
            complate(){
        
                if(this.startTime == ''|| this.startTime == '' || this.curDate == ''){
                    this.pickerShow = false
                    this.$message.error(this.$t('tips.notTime'))
                    return 
                }
                console.log(this.addMonth(this.curDate, 2),this.startTime)
                console.log(new Date(this.addMonth(this.curDate, 2) + " " + this.startTime))
                // 传递数据到父组件
                this.$emit("updateDate",{
                    start: this.startDate,
                    end: this.endDate,
                    date: this.curDate,
                    nextMonthDisabel: new Date(this.addMonth(this.curDate, 2) + " " + this.startTime),
                    afterDisabel: new Date(this.endDate)
                })
                this.pickerShow = false
            },
            
            // 监听输入框获取焦点
            handlerIptFocus(){
                this.pickerShow = true
            },
            
            // 监听输入框失去焦点
            handlerIptBlur(){
                // this.$bus.$emit('pickerShut')
            },
            
            // 监听在时间输入框用户按下上箭头
            incrementTime(){
                // let time = Number(this.startTime.replace(/[\s,:]/g,''))
                // console.log(time)
            },
            
            // 监听用户修改开始时间
            handlerChangeStartTime(time){
                let timeArr = time.split(':')
                let endTime = ''
                if(Number(timeArr[0] )> 23){
                    endTime = '00' + ':' + timeArr[1]
                }else{
                    endTime = Number(timeArr[0]) + 1 + ':' + timeArr[1]
                }
                this.endTimeOption.start = endTime
                this.endTime = endTime
                console.log(endTime, 'end')
            }
        },
        // 计算属性 
        computed: {
            showDate: {
                get(){
                    if(this.curDate == ''){
                        return ''
                    }
                    return this.curDate + '    ' + this.startTime + " - " + this.endTime
                },
                set(newValue){
                    return newValue
                }
            },
            startDate: {
                get(){
                    return this.curDate + ' ' + this.startTime
                },
                set(newValue){
                    return newValue
                }
            },
            endDate: {
               get(){
                 return this.curDate + ' ' + this.endTime 
               },
               set(newValue){
                    return newValue
               }
            }
        },
        watch: {
            startDateDisabel(){
                this.init()
            },
            startTime(value){
                this.endTimeOption.start = value
            }
        },
        // 生命周期==>创建完成
        created() {
            // 初始化
            this.init()

            this.$bus.$on("clearDate", ()=>{
                if(this.isclear){
                    this.startDate = '' 
                    this.endDate = ''
                    this.curDate = ''
                    this.showDate = ''
                }
            })
        },
        // 生命周期==>挂载完毕
        mounted() {
            this.$bus.$on('pickerShut',()=>{
                if(this.pickerShow){
                    this.complate()
                }else{
                    
                }
            })

            
        },
    }
</script>

<style lang="less">
    #Date{
        position: relative;
        .mask{
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 1;
        }
        .date-editor{
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            background: none;
            border: none;
            border-bottom: .0125rem solid  rgba(232, 232, 255, 0.14);
            min-height: 30px;
            line-height: 30px;
            padding: 0 .0625rem;
            color: #ccc;
            .icon{
                position: absolute;
                right: .0625rem;
                top: 5%;
                font-size: 18px;
                width: 30px;
                height: 30px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .input__inner{
                background: none;
                border: none;
                border-radius: 0rem;
                font-size: .225rem;
                color: #fff;
                padding-right: .125rem;
                outline: none;
                width: 3.375rem;
                display: block;
                font-size:18px;
                white-space: nowrap;/*不换行*/
                text-overflow:ellipsis;/*超出部分文字以...显示*/
                height: 100%;
                /* text-align: center; */
            }
            .input__inner::-webkit-input-placeholder{
                font-size: 16px;
            }
            ::-webkit-input-placeholder {
                color: rgba(255, 255, 255, 0.5); 
            } 
        }
        .date-editor.active{
            .heffect();
        }
        .date-editor.disable{
            input{
                color: #bbb;
                &:hover{
                cursor: not-allowed;
            }
            }
            &:hover{
                cursor: not-allowed;
            }
        }
        .wrapper{
            position: absolute;
            bottom: 120%;
            left: 0%;
            transform: translate(25% 25%);
            min-width: 3.8rem;
            width: 320px;
            height: 5.75rem ;
            background: rgba(255, 255, 255, 0.96);
            border: .0125rem solid #FFFFFF;
            border-radius: .125rem;
            padding: .125rem .225rem;
            color: #000;
            z-index: 20;
            .header{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 5px 0;
                margin-bottom: 20px;
                .date{
                    display: flex;
                    margin: 0 10px;
                    font-size: 20px;
                    p{
                        font-size: 25px;
                    }
                }
                .next,.prev{
                    p{
                        font-size: 20px;
                    }
                    &:hover{
                        cursor: pointer;
                        opacity: .8;
                    }
                }
            }
            .content{
                width: 100%;
                thead{
                    display: flex;
                    width: 100%;
                    .tr{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        th{
                            text-align: center;
                            width: 40px;
                            height: 30px;
                            span{
                                font-family: "SourceHanSansCN-Light";
                                font-size: 20px;
                            }
                        }
                    }
                }
                tbody{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: .1875rem;
                    z-index: 20;
                    .tr{
                        width: 100%;
                        display: flex;
                        td{
                            display: inline-block;
                            width: 40px;
                            height: 30px;
                            text-align: center;
                            line-height: 30px;
                            border-radius: 5px;
                            margin-right: 3px;
                            span{
                                font-size: 20px;
                                text-align: center;
                            }
                            &:hover{
                                cursor: pointer;
                                color: #ED8220;
                            }
                        }
                        .active{
                            background: #ED8220 !important;
                            color: #fff !important;
                        }
                        .off{
                            color: #666;
                            &:hover{
                                color: #c0c4cc;
                            }
                        }
                        .disabel{
                            color: #c0c4cc;
                            &:hover{
                            cursor: not-allowed;
                                color: #c0c4cc;
                            }
                        }
                    }
                }
                tbody.disabel{
                    .tr{
                        td{
                            color: #c0c4cc;
                            &:hover{
                                cursor: auto;
                            }
                            span{
                                font-family: 'DIN-Medium';
                            }
                        }
                    }
                }
            }
            .time{
                bottom: .0625rem;
                margin: 0 auto;
                margin-top: .1875rem;
                .flexbc();
                flex-wrap: wrap;
                .label{
                    width: 100%;
                    span{
                        font-size: .175rem;
                    }
                }
                .startTime,.endTime{
                    width: 49%;
                    .el-input{
                        width: 100%;
                        input{
                            background: none;
                            font-size: 16px;
                        }
                    }
                }
                .endTime{
                    input{
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, .1);
                        padding: 7px 15px;
                        border-radius: 3px;
                        background: none;
                        font-size: 18px;
                    }
                    *:hover{
                        cursor: not-allowed;
                    }
                }
            }
            .complate{
                width: 1.4125rem;
                height: .575rem;
                background: #ED8220;
                border: .0125rem solid #ED8220;
                border-radius: .125rem;
                line-height: .5rem;
                text-align: center;
                margin: 0 auto;
                margin-top: .3125rem;
                span{
                    color: #fff;
                    font-size: .225rem;
                }
                &:hover{
                    opacity: .8;
                    cursor: pointer;
                }
            }
        }
    }
</style>