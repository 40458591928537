<template>
    <div id="News" :style="`background-image: url( ${bgImg} )`">
          <!-- 容器 -->
          <div class="wrap">
            <!-- 头部 -->
            <div class="hd">
                <h1 class="title">{{$t('swiperShow.news.title')}}</h1>
                <div class="bm"></div>
                <div class="subtitle">The latest news</div>
            </div>
  
            <!-- 主体 -->
            <div class="bd swiper-container mobile-max-height" >
                <swiper :options="swiperOption" ref="producrSwiper" class="news">
                    　<swiper-slide class="new-item" v-for="item in pageData" :key="item.newsPageId" @click.native="handlerDetails(item)">
                        <div class="top">
                            <img :src="`${baseUrl + item.pageImg}`">
                        </div>
                        <div class="bottom">
                            <div class="description">                            
                                <p class="title" v-html="item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']"></p>
                                <span class="text" v-html="item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']"></span>
                            </div>
                            <div class="foot">
                                <p class="date" v-html="item.lastUpdateDate"></p>
                                <div class="arrow">
                                    <img src="../../../assets/imgs/arrow.png" alt="">
                                </div>
                            </div>
                        </div>  
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" slot="button-prev" @click="$refs.producrSwiper.$swiper.slidePrev() "></div>
                <div class="swiper-button-next" slot="button-next" @click="$refs.producrSwiper.$swiper.slideNext() "></div>
                <!-- <ul class="news swiper-wrapper" ref="mySwiper">
                    <li class="new-item swiper-slide" v-for="item in pageData" :key="item.newsPageId" @click="handlerDetails(item)">
                        <div class="top">
                            <img :src="`${baseUrl + item.pageImg}`">
                        </div>
                        <div class="bottom">
                            <div class="description">                            
                                <p class="title" v-html="item.labelCn"></p>
                                <span class="text" v-html="item.labelCn"></span>
                            </div>
                            <div class="foot">
                                <p class="date" v-html="item.lastUpdateDate"></p>
                                <div class="arrow">
                                    <i class="iconfont icon-jiantou_xiangyou_o"></i>
                                </div>
                            </div>
                        </div>  
                    </li>
                </ul> -->
                        <!-- 笼罩层 -->
                <div class="mask-left" v-show="leftMaskShow"></div>
                <div class="mask-right" v-show="rightMaskShow"></div>
            </div>
            
        </div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'News',
    components: {
    },
    data(){
        let that = this
      return {
        // 背景图片
        bgImg:process.env.BASE_URL + "bgImg/news.png",
        // 页面数据
        pageData: [],
        // 左边遮罩是否展示
        leftMaskShow: false,
         // 右边遮罩是否展示
        rightMaskShow: true,
        //   轮播图配置
        swiperOption: {
            slidesPerView: 'auto',
            notNextTick: true,
            centeredSlides: true,
            freeMode : this.isMobile ? true : false,//是否滑动
            paginationClickable: true,
            spaceBetween: 50,//间距
            observer:true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents:true, //修改swiper的父元素时，自动初始化swiper
            onSlideChangeStart: (swiper) => {
                if(swiper.activeIndex >= 2 && this.leftMaskShow == false){
                    this.leftMaskShow = true
                }else if(this.leftMaskShow == true && swiper.activeIndex < 2){
                    this.leftMaskShow = false
                }
                if(this.pageData.length - swiper.activeIndex  < 2 && this.rightMaskShow == true){
                    this.rightMaskShow = false
                }else if(this.pageData.length - swiper.activeIndex  >= 2 && this.rightMaskShow == false){
                    this.rightMaskShow = true
                }
            },
            on:{
                click(tar){
                    console.log(tar)
                }
            }
        },
      }
    },
    methods: {
        // 监听用户点击箭头查看详情
        handlerDetails(data){
            console.log(data)
            this.$router.push({
                name: 'NewMessage',
                params: {pageData: data}
            })
        },
        // 获取页面数据
        async getPageData(){
            const res = await this.$http.get("/web/news/page")

            console.log("新闻页面数据",res.data)
            this.pageData = res .data.records
        }
    },
    mounted() {
        // 给nav传递背景图片路径
        this.$bus.$emit("setNavBg",this.bgImg)

    },
    // 生命周期==>创建完成
    created(){
        this.getPageData();
    }
  }
  </script>
  
  <style lang="less">
    #News{
        width: 100%;
        height: 100%;
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        p{
            text-align: left;
        }
        .wrap{
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            .hd{
                text-align: center;
                .title{
                    font-size: .6rem;
                }
                .bm{
                    width: 2.725rem;
                    height: .1125rem;
                    background: @colorMain;
                    border-radius: .05rem;
                    margin: .0625rem auto;
                    margin-top: -0.1rem;
                }
                .subtitle{
                    color: #B3B3B3;
                    font-size: .225rem;
                    font-weight: 400;
                    color: rgba(179, 179, 179, 1);
                }
            }
            .bd{
                .flexb();
                margin-top: 70px;
                height: 520px;
                text-align: left;
                .news{
                    display: flex;
                    width: 100%;
                    .swiper-button-next,.swiper-button-prev{
                        z-index: 199;
                    }
                    .new-item{
                        width: 4.25rem;
                        height: 100%;
                        margin-right: .4375rem;
                        background: #ffffff;
                        color: #000;
                        transition: all .6s;
                        .heffect();
                        .top{
                            height: 70%;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .bottom{
                            padding: .1875rem;
                            height: 30%;
                            .description{
                                font-weight: 560;
                                .title{
                                    font-size: .325rem;
                                    margin-bottom: 10px;
                                    *{
                                        font-size: .325rem;
                                        margin-bottom: 10px;
                                    }
                                }
                                .text{
                                    width: 100%;
                                    overflow: hidden;
                                    height: 10px;
                                    p{
                                        opacity: 0;
                                        font-size: .2rem;
                                    }
                                }
                            }
                            .foot{
                                margin-top: 5px;
                                .flexbc();
                                *{
                                    font-family: 'DIN-Medium';
                                }
                                .date{
                                    color: #999999 ;
                                    font-size: .2rem;
                                }
                                .arrow{
                                    margin-top: .125rem;
                                    img{
                                        width: 50px;
                                        height: 30px;
                                        .heffect();
                                    }
                                }
                            }
                        }
                    }
                    .swiper-slide-active{
                        width: 600px !important;
                        .bottom{
                            .description{
                                .text{
                                    p{
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                .swiper-button-prev,.swiper-button-next{
                    z-index: 99;
                }
            }
        }
        .mask-right,.mask-left{
            position: absolute;
            top: 0;
            height: 100%;
            z-index: 20;
            width: 1.875rem;
        }
        .mask-right{
            right: 0;
            background: linear-gradient(to left, #333, transparent);
        }
        .mask-left{
            left: 0;
            background: linear-gradient(to right, #333, transparent);
        }
    }
  </style>
  