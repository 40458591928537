function css(obj,name){     //获取对象的样式
    if(obj.currentStyle){        //考虑浏览器问题，采用if判断获取当前对象的样式
        return obj.currentStyle[name];
        }else{
        return getComputedStyle(obj,false)[name];
        }
    }

export function startMove(ele, opt, fn){
	
	//清定时器防止叠加
	clearInterval(ele.timer);
	//为每个对象绑定定时器
	ele.timer = setInterval(function() {
		//做判断开关
		var istrue = false;
		//遍历可执行多属性
		for (var key in opt) {
			//初始值
			var cur = 0;
			//当属性为透明度时执行这里
			if (key == 'opacity') {
				//获取当前透明度并乘100，好计算速度
				cur = ele.style.opacity * 100;
			} else {
				//若为其他属性 width height left top。。。获取当前属性值
				cur = parseInt(css(ele, key));
			}
			//计算运动速度，目标值-当前值，目标值大于当前值为正，反之为负
			var speed = (opt[key] - cur) / 5;
			//去小数取整，防止运动最后的抖动
			speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);
			//判断是否达到目标值，达到开关为true
			if (cur == opt[key]) {
				istrue = true;
			} else {
				istrue = false;
			}
			//判断
			if (key == 'opacity') {
				//为普通浏览器时透明度为0-1所以除于100，会成功执行这句
				ele.style.opacity = (cur + speed) / 100;
				//ie低版本执行这句取值为0-100
				obj.style.filter = 'alpha(opacity:' + (cur + speed) + ')';
			} else {
				//其他属性执行这里，速度+当前属性值，通过速度的正负达到去和回的效果，透明度也是如此
				ele.style[key] = speed + cur + 'px';
			}
		}
		//判定成立清理定时器，并判断是否有回调函数有则调用执行
		if (istrue) {
			clearInterval(ele.timer);
			if (fn) {
				fn();
			}
		}
	}, 30);

}

export function  imStartMove(domobj, json, fn){
	clearInterval(domobj.timer);
	 //假设所有的属性值都达到了目标值
	 let flag = true;
 
	 for(let attr in json){
		 //取目标值
		 let iTarget = json[attr];
		 //考虑透明度
		 if(attr == "opacity"){
			 var iCur = parseInt(css(domobj,"opacity")*100);
		 }else{
			 //取当前值
			 var iCur = parseInt(css(domobj,attr));
		 }
		 
		 
		 
		 //求每次移动距离
		 let iSpeed = (iTarget-iCur)/8;
		 iSpeed = iSpeed > 0 ? Math.ceil(iSpeed) : Math.floor(iSpeed);
		 
		 if(attr == "opacity"){
			 domobj.style.opacity = (iCur+iSpeed)/100;
			 domobj.style.filter = "alpha(opaicty="+(iCur+iSpeed)+")";
		 }else{
			 domobj.style[attr] = iCur + iSpeed + "px";
		 }
		 
		 
		 if(iCur!=iTarget){
			 //只要一个没有达到，假设就不成立
			 flag = false;
		 }
	 }
	 
	 //if条件如果满足，所有的均达到了目标值
	 if(flag){
		 clearInterval(domobj.timer);
		 //避免用户不传第三个参数
		 if(fn){
			 fn();
		 }
	 }
	
}