<template>
    <div id="Vedio">
      <div class="top-mask" :class="videoStatus == 'paused' || videoStatus == 'ended' ?'active':'passive'"></div>
      <div class="bottom-mask" :class="videoStatus == 'paused' || videoStatus == 'ended' ?'active':'passive'"></div>
      <video id="video" class="video" :src="`${videoSrc}`" width="100%" height="100%" @pause="videoPause" @play="videoPlay" @ended="videoEnded" :x5-video-player-type="isMobile ? 'h5-page': ''"
      :playsinline="isMobile ? 'true' : 'false'">
      </video>

      <div class="options" :class=" !pageLazy?'active':'passive'">
        <img src="../../../../assets/imgs/play.png" alt="play" @click="playVideo()" v-if="videoStatus == 'paused'">
        <img src="../../../../assets/imgs/pause.png" alt="pause" @click="pauseVideo()" v-else-if="videoStatus == 'playing'">
        <img src="../../../../assets/imgs/replay.png" alt="replay"  @click="replayVideo()" v-else-if="videoStatus == 'ended'">
    </div>

      <div class="goback"  :class="videoStatus == 'paused' || videoStatus == 'ended'?'active':'passive'" @click="goback">
          <span>返回</span>
      </div>

    </div>
</template>
  
<script>
    export default {
    name:'viewVideo',
      data() {
        return {
          // 视频路径
          videoSrc: '',
          // 视频状态
          videoStatus: 'paused',
           // 页面状态
           pageLazy: false,
        }
      },
      methods: {
        // 播放视频
        playVideo(){
          this.videoStatus = 'playing'
          document.getElementById("video").play()
        },
        // 视频播放
        videoPlay(){
          console.log("播放")
        },
        // 监听视频暂停
        videoPause(){
            console.log("暂停")
            document.getElementById("video").pause()
        },
        // 暂停视频
        pauseVideo(index){
          this.videoStatus = 'paused'
          document.getElementById("video").pause()
        },
        // 重播视频
        replayVideo(){
          document.getElementById("video").load()
          document.getElementById("video").play()
          this.videoStatus = 'playing'
        },
        // 监听视频播放完毕
        videoEnded(){
          console.log('播放完毕')
          this.videoStatus = 'ended'
        },
        // 监听鼠标滑动改变页面展示状态
        handlerMouseMove(){
            let valve = false
            let tiemr = undefined 
            let that = this
            function pageAction(){
              if(valve) return
                valve = true
                that.pageLazy = false
                setTimeout(() => {
                    that.pageLazy = false
                    valve = false
                    tiemr = setTimeout(()=>{
                      if(!that.pageLazy){
                        that.pageLazy = true
                      }
                    },1000)
                },1000)
            }
            window.addEventListener('mousemove', pageAction)
            if(this.isMobile){ 
              window.addEventListener('touchstart', pageAction)
            }
          },
        // 监听用户点击返回
        goback(){
          this.$router.replace("/Shop/OnlineChoice")
        },

      },
      computed: {
      },
      // 生命周期==>创建完成时
      created(){
        console.log(this.$route)
        this.videoSrc = this.$route.params.src ||  window.sessionStorage.getItem('vedioSrc')

          this.handlerMouseMove()

        // 存储一个路径防止刷新数据丢失
        window.sessionStorage.setItem('vedioSrc',this.videoSrc)
      },
    }
  </script>
  

<style lang="less">
    #Vedio{
        position: absolute;
        width: 100%;
        height: 100%;
        color: #fff;
        overflow: hidden;
        .video{
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 90%;
            min-height: 100%;
            height: auto;
            width: auto;
        }
        .top-mask,.bottom-mask{
            position: absolute;
            width: 100%;
            z-index: 20;
        }
        .top-mask{
            top: 0;
            height: 2.5rem;
            background:linear-gradient(rgba(0,0,0,1),rgba(0,0,0,0));
        }
        .bottom-mask{
            bottom: 0;
            height: 5.325rem;   
            background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
        }
        .top-mask.active,.bottom-mask.active{
            animation: show .6s forwards;
        }
        .top-mask.passive,.bottom-mask.passive{
            animation: close .6s forwards;
        }
        .options{
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          img{
              width: 1.25rem;
              height: 1.25rem;
              .heffect();
          }
      }
      .options.active{
          animation: show .6s forwards;
      }
      .options.passive{
          animation: close .6s forwards;
      }
      .goback{
        position: fixed;
        z-index: 99;
        top: 80%;
        left: 50%;
        transform: translate(-58%, 55%);
          width: 2rem;
          height: .875rem;
          line-height: .875rem;
          background: rgba(255, 255, 255, 0.6);
          border-radius: .25rem;
          margin: 0 .25rem;
          .heffect();
          text-align: center;
          span{
              font-size: .25rem;
              color: #fff;
          }
      }
      .goback.passive{
        animation: close .6s forwards;
        display: none;
      }
      .goback.active{
        animation: show .6s forwards;
      }
    }
</style>
