<template>
    <div id="VR">
        <Vr360  :imgSrc="imgSrc"/>
        <!-- 选项 -->
        <div class="option">
            <div class="onlineChoice" @click="onlineChoice">
                <span>返回</span>
            </div>
            <!-- <div class="joinOrder"  @click="joinOder">
                <span>加入訂單</span>
            </div> -->
        </div>
    </div>
</template>
  
<script>
      import Vr360 from "vue-360vr";
    export default {
    name:'VR',
      components: {
        Vr360
      },
      data() {
        return {
            imgSrc: this.$route.params.src,
        }
      },
      methods: {
        // 监听用户点击在线选择
        onlineChoice(){
            this.$router.push("/Shop/OnlineChoice")
        },
        // 监听用户点击加入订单
        joinOder(){
          this.$router.push("/Shop/OnlineChoice")
        },
      },
      computed: {
        
      },
    //   生命周期==>创建完成时
    created(){

        console.log("vr收到了图片", this.$route.params.src)
        this.imgSrc = this.$route.params.src || window.sessionStorage.getItem('vrSrc')
        
        window.sessionStorage.setItem('vrSrc', this.imgSrc)
    }
}
  
  </script>
  

<style lang="less">
    #VR{
        position: relative;
        .componentContainer{
            width: 100vw !important;
            height: 100vh !important;
        }
        .playBtn{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-25%,-25%);
            width: .75rem;
            height: .75rem;
            background: rgba(153, 153, 153, 0.5);
            border-radius: 50%;
            .flexcc();
            .heffect();
            display: none;
            i{
                display: inline-block;
                font-size: .375rem;
                color: #fff;
                margin-right: -0.0625rem;
            }
        }
        .option{
            width: 100%;
            position: fixed;
            bottom: 1.25rem;
            .flexcc();
            .onlineChoice,.joinOrder{
                width: 2rem;
                height: .875rem;
                line-height: .875rem;
                background: rgba(255, 255, 255, 0.6);
                border-radius: .25rem;
                margin: 0 .25rem;
                .heffect();
                text-align: center;
                span{
                    font-size: .25rem;
                    color: #fff;
                }
            }
            .joinOrder{
                background: rgba(237, 130, 32, 0.6);
            }
        }
    }
</style>
