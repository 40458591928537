<template>
    <div id="FAC">
        
        <div class="pubw">
            <div class="program">
                <div class="title">
                    <p>{{$t('subPage.fac.program.title')}}</p>
                </div>
                <div class="img">
                    <img src="../../../assets/imgs/pv1.jpg" alt="">
                </div>
                <div class="bottom">
                    <div class="text">
                        <span>
                            {{$t('subPage.fac.program.text')}}
                        </span>
                    </div>
                    <div class="download">
                        <div class="t">
                            {{$t('subPage.fac.program.download')}}
                        </div>
                        <div class="b">
                            <div class="android">
                                <span class="iconfont icon-android"></span>    <span>{{$t('subPage.fac.program.android')}}</span>
                            </div>
                            <div class="apple">
                                <span class="iconfont icon-ios"></span>    <span>{{$t('subPage.fac.program.ios')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="room">
                <div class="title">
                    <p>{{$t('subPage.fac.room.title')}}</p>
                </div>
                <div class="img">
                    <img src="../../../assets/imgs/pv2.jpg" alt="">
                </div>
                <ul>
                    <li v-for="(item, index) in roomList" :key="item.id">
                        <div class="title">
                            <span>{{index +1}}. {{_i18n.locale == 'en' ? item.titleEn : item.title}}</span>
                        </div>
                        <div class="description">
                            <span>{{_i18n.locale == 'en' ? item.descriptionEn : item.description}}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="combaination">
                <div class="title">
                    <p>
                        {{$t('subPage.fac.combaination.title')}}
                    </p>
                </div>
                <div class="part">
                    <span> {{$t('subPage.fac.combaination.text1')}}</span>
                </div>
                <div class="img">
                     <img src="../../../assets/imgs/PV3.jpg" alt="">
                </div>
                <div class="part">
                    <span>{{$t('subPage.fac.combaination.text2')}}</span>
                </div>
                <div class="img">
                     <img src="../../../assets/imgs/pv4.jpg" alt="">
                     <span>{{$t('subPage.fac.combaination.text3')}}</span>
                </div>
            </div>
            <div class="useInfo">
                <div class="title">
                    <p>
                        {{$t('subPage.fac.useInfo.title')}}
                    </p>
                </div>
                <div class="row">
                    <P> {{$t('subPage.fac.useInfo.text1')}}</P>
                    <P> {{$t('subPage.fac.useInfo.text2')}}</P>
                    <P> {{$t('subPage.fac.useInfo.text3')}}</P>
                </div>
            </div>
            <div class="orther">
                <div class="title">
                    <p>
                        {{$t('subPage.fac.orther.title')}}
                    </p>
                </div>
                <div class="content">
                    <div class="block">
                        <div class="t">
                            <span>{{$t('subPage.fac.orther.text1')}}</span>
                        </div>
                        <div class="description">
                            <p>{{$t('subPage.fac.orther.text2')}}</p>
                            <p>{{$t('subPage.fac.orther.text3')}}</p>
                        </div>
                    </div>
                    <div class="block">
                        <div class="img">
                              <img src="../../../assets/imgs/pv5.jpg" alt="">
                        </div>
                    </div>
                    <div class="block">
                        <div class="img">
                              <img src="../../../assets/imgs/pv6.png" alt="">
                        </div>
                    </div>
                    <div class="block">
                        <div class="t">
                            <span>{{$t('subPage.fac.orther.text4')}}</span>
                        </div>
                        <div class="description">
                            <p>{{$t('subPage.fac.orther.text5')}}</p>
                        </div>
                        <!-- <div class="details">
                            <span>{{$t('subPage.fac.orther.btn')}} ></span>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="curtain">
                <div class="title">
                    <p>{{$t('subPage.fac.curTain.title')}}</p>
                </div>
                <div class="part">
                    <p>{{$t('subPage.fac.curTain.text')}}</p>
                </div>
                <div class="option">
                    <div class="address" @click="handlerClickAddress">
                        <span>{{$t('subPage.fac.curTain.btn1')}}</span>
                    </div>
                    <div class="powar" @click="handlerClickPowar">
                        <span>{{$t('subPage.fac.curTain.btn2')}}</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FAC',
        data(){
            return {
                // 轮播图列表
                swiperS: [
                    'assets/imgs/freemeasure.jpg',
                    'assets/imgs/freemeasure.jpg',
                    'assets/imgs/freemeasure.jpg',
                    'assets/imgs/freemeasure.jpg',
                    'assets/imgs/freemeasure.jpg'
                ],
                // 房屋列表信息
                roomList: [
                {
                        id: '0',
                        titleEn: 'PowerView® wireless extender',
                        title: 'PowerView® 無線伸延器',
                        descriptionEn: 'The small signal enhancer can strengthen the signal sent by the main control box to ensure that the commands issued by the main control box can be transmitted to the whole house smoothly.',
                        description: '細小的訊號加強器，可加強總控盒發出的訊號，確保總控盒發出的指令能順利傳送至全屋。'
                    },
                    {
                        id: '1',
                        titleEn: 'Pebble® and Surface scene system',
                        title: 'Pebble®及Surface場景制',
                        descriptionEn: 'With the use of PowerView® master control box and applications, you can quickly select and activate your preset scenes. Scene system can choose portable Pebble® or wall-mounted',
                        description: '配合PowerView®總控盒及應用程式使用，可快速選擇及啟用你已預設的場景。場景制可選擇手提式的Pebble®或安裝於牆身的'
                    },
                    {
                        id: '2',
                        titleEn: 'PowerView® App application',
                        title: 'PowerView® App 應用',
                        descriptionEn: 'The PowerView application gives full play to the advanced functions of this system. Through your mobile phone or tablet, you can operate single or whole house curtains, preset scenes and time at your fingertips',
                        description: 'PowerView應用程式將此系統的先進功能完全發揮。透過手機或平板電腦，彈指間即可操作單一或全屋窗簾、預設場景及時間，甚至利'
                    },
                    {
                        id: '3',
                        titleEn: 'Pebble® and Surface remote control',
                        title: 'Pebble®及Surface 遙控器',
                        descriptionEn: 'The remote control can be equipped with a portable shell or installed on the wall. The design is simple and easy to use. Just tap the remote control to control the opening and closing of the curtains and adjust the angle of the curtains. You can also control one or a group of curtains as needed.',
                        description: '遙控器可配手提式外殼或安裝於牆上，設計簡單易用，只需輕按遙控器，即可控制窗簾開合和調校簾片角度，更可按需要操控一幅或一組窗簾。'
                    },
                    {
                        id: '4',
                        titleEn: 'PowerView® Master Control Box',
                        title: 'PowerView® 總控盒',
                        descriptionEn: 'As the central control point of the whole house PowerView window decoration, the master control box will connect to the WiFi network to store the settings of each curtain and activate the scene mode to help you remotely control the whole house curtain.',
                        description: '作為全屋PowerView窗飾的中央控制點，總控盒會連接WiFi網絡，儲存各幅窗簾的設定及啟動場景模式，助你遙控全屋窗簾。'
                    },
                ]
            }
        },
        methods: {
            // 监听用户点击进销商地址
            handlerClickAddress(){

            },
            // 监听用户点击小册子
            handlerClickPowar(){

            }
        },
        // 生命周期==>挂载完成
        mounted() {
        },
        // 生命周期==>创建完成时
        created(){

        },
    }
</script>

<style lang="less">

    #FAC{
        padding-bottom: 150px;
        .title{
            margin-top: 50px;
            margin-bottom: 40px;
            background: none;
            p{
                font-size: 48px;
                font-family: Roboto;
                font-weight: 500;
                color: #333333;
            }
        }
        #banner{
            width: 100%;
            .el-carousel{
                width: 100%;
                height: 100%;
                .el-carousel__indicators {
                    .el-carousel__indicator{
                        .el-carousel__button{
                            background: none;
                            border: 1px solid #fff;
                            border-radius: 20px;
                            height: 5px;
                            width: 30px;
                            transition: all .2s;
                        }
                    }
                    .is-active{
                        .el-carousel__button{
                            width: 40px;
                            background-color: @colorMain;
                        }
                    }
                }       
                .el-carousel__container{    
                        width: 100%;
                        height: 100%;
                    .el-carousel__arrow{
                        width: .775rem;
                        height: .775rem;
                        i{
                            font-size: .3125rem;
                        }
                        &:hover{
                            color: @colorMain;
                        }
                    }
                    .el-carousel__item{
                        width: 100%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .pubw{
            &>div>.img{
                img{
                                            width: 100%;
                        height: 959px;
                }
            }
            &>div>.title{
                margin-top: 150px;
            }
            .program{
                .img{
                    width: 1301px;
                    height: 959px;
                    img{
                        width: 100%;
                        height: 100%;  
                    }
                }
                .bottom{
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    .text{
                        width: 50%;
                        span{
                            line-height: 35px;
                            font-size: 24px;
                            font-family: 'SourceHanSansCN-Light';
                            font-weight: 300;
                            color: #333333;
                        }
                    }
                    .download{
                        width: 45%;
                        .t{
                            font-size: 30px;
                            font-family: 'SourceHanSansCN-Medium';
                            font-weight: 500;
                            color: #333333;
                            text-align: center;
                        }
                        .b{
                            margin-top: 20px;
                            display: flex;
                            justify-content: space-between;
                            .android,.apple{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-width: 240px;
                                width: fit-content;
                                padding: 10px 20px;
                                height: 70px;
                                background: #338F01;
                                border-radius: 20px;
                                text-align: center;
                                line-height: 70px;
                                color: #fff;
                                /* .heffect(); */
                                .iconfont{
                                    font-size: 30px;
                                    margin-right: 20px;
                                }
                                .icon-android{
                                    font-size: 35px;
                                }
                                span{
                                    font-size: 24px;
                                    font-family: 'SourceHanSansCN-Medium';
                                    white-space: nowrap;
                                }
                            }
                            .apple{
                                background: #333333;
                            }
                        }
                    }
                }
            }
            .room{
                .img{
                    img{
                        height: 512px;
                    }
                }
                ul{
                    li{
                        border-bottom: 1px solid #ccc;
                        padding-bottom: 20px;
                        margin-bottom: 30px;
                        .title{
                            text-align: left;
                            margin: 30px 0;
                            span{
                                font-size: 24px;
                                font-family: D-DIN;
                                font-weight: bold;
                                color: #333333;
                                margin: 20px 0;
                            }
                        }
                        .description{
                            span{
                                font-size: 24px;
                                font-family: Source Han Sans CN;
                                font-weight: 300;
                                color: #333333;
                            }
                        }
                    }
                }
            }
            .combaination{
                .part{
                    margin: 15px 0;
                    span{
                        margin: 20px 0;
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #333333;
                    }
                }
                .img{
                    text-align: center;
                    height: 470px;
                    width: 650px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    span{
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                    }
                }
            }
            .useInfo{
                .row{
                    p{
                        text-align: left;
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #333333;
                        margin: 20px 0;
                    }
                }
            }
            .orther{
                paddign-bottom: 200px;
                .content{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .block{
                        width: 49%;
                        height: 513px;
                        margin: 35px 0;
                        .t{
                            border-left: 4px solid @colorMain;
                            padding-left: 5px;
                            span{
                                font-size: 24px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #333333;
                            }
                        }
                        .img{
                            width: 100%;
                            height: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .description{
                            margin-top: 20px;
                            p{
                                margin: 10px 0;
                                font-size: 24px;
                                font-family: Source Han Sans CN;
                                font-weight: 300;
                                color: #333333;
                                text-align: left;
                            }
                        }
                        .details{
                            width: 130px;
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            background: @colorMain;
                            border-radius: 15px;
                            margin: 100px 0px;
                            .heffect();
                            span{
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
            .curtain{
                .title{
                    p{
                        font-size: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333;
                    }
                }
                .part{
                    p{
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #333333;
                    }
                }
                .option{
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                    .address,.powar{
                        width: 200px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        background: @colorMain;
                        border-radius: 15px;
                        margin: 0 50px;
                        .heffect();
                        span{
                            font-size: 18px;
                            font-family: Roboto;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
</style>