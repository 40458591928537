<template>
    <div id="SubPage">
        <router-view></router-view>
        <Footer/>
    </div>
</template>

<script>
    import Footer from '@/components/Footer'
    export default {
        name: 'SubPage',
        components: {
            Footer
        },
        methods: {
            // 刷新函数 ：兼容iphone12 刷新时页面超出当前屏幕底部下的部分内容为空白
            reload(){
                let prevTime = window.localStorage.getItem('prevRelaodTime')
                let count = (window.localStorage.getItem('prevRelaodCount') - 0)
                if(prevTime){
                    if((new Date().getTime() - prevTime)  < 5000 || count > 3) return
                    window.localStorage.setItem('prevRelaodTime', new Date().getTime())
                    window.localStorage.setItem('prevRelaodCount', count + 1)
                    window.location.reload()
                }else{
                    window.localStorage.setItem('prevRelaodTime', new Date().getTime())
                    window.localStorage.setItem('prevRelaodCount', count  + 1)
                    window.location.reload()
                }
            }
        },
        created() {
            // 如果设备为iphone就要执行刷新
            if(navigator.userAgent.match(/(phone)/i)){
                this.reload()
            }
        },
        mounted() {
            // 通知header的头部状态变暗
            this.$bus.$emit("setHeaderStatus",0)
            document.querySelector('#app').style.overflowY = 'auto'
        },
        beforeDestroy() {
            console.log('subPage dis')
            this.$bus.$emit("setHeaderStatus", 1)
            document.querySelector('#app').style.overflowY = 'hidden'
            window.localStorage.setItem('prevRelaodCount', 0)
        },
    }
</script>

<style lang="less">
    #SubPage{
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 80px;
        & > div{
            & > .banner{
                position: relative;
                height: 600px !important;
            }
            .top{
                .floting{
                    .subtitle{
                        *{
                            color: #999;
                        }
                    }
                }
            }
        }
    }
</style>