<template>
    <div id="ContackPopup" @mousedown="move" @touchstart="move" @mouseenter="mouseenter" ref="ContackPopup">
    </div>
</template>

<script>
    import {imStartMove, startMove} from '@/assets/js/startMove.js'
    export default {
        name: 'ContackPopup',
        data(){
            return {
                // 号码
                number: 88888888
            }
        },
    methods: {
        // 跳转
        jump(e){
            window.open(`https://wa.me/57038334`)
        },
        // 拖拽
        move(e){
            clearTimeout(e.stimer)
            let that = this
            let flag = true
            let odiv = e.target;        //获取目标元素
            //算出鼠标相对元素的位置
            let disX = (e.clientX || e.targetTouches[0].clientX)- odiv.offsetLeft;
            let disY = (e.clientY || e.targetTouches[0].clientY) - odiv.offsetTop;
            e.releaseCapture && e.releaseCapture()
            e.preventDefault && e.preventDefault()
            if(window.sessionStorage.getItem('model') == 'pc'){
                document.onmousemove = (e)=>{       //鼠标按下并移动的事件
                    flag = false
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = e.clientX - disX;
                    let top = e.clientY - disY;
                    //绑定元素位置到positionX和positionY上面
                    this.positionX = top;
                    this.positionY = left;

                    //移动当前元素
                    imStartMove(odiv, {
                        left,
                        top
                    })
                };
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    if(flag){
                        this.jump()
                    }
                    e.stimer = setTimeout(()=>{
                        this.reduction(odiv)
                    }, 1500)
                };
            }else{
                document.ontouchmove = (e) => {
                    flag = false
                    let left = e.targetTouches[0].clientX - disX;
                    let top = e.targetTouches[0].clientY - disY;
                    this.positionX = top;
                    this.positionY = left;
                    //移动当前元素
                    imStartMove(odiv, {
                        left,
                        top
                    })
                    odiv.style.left = left + 'px';
                    odiv.style.top = top + 'px';
                }
                document.ontouchend = (e) => {
                    document.ontouchmove = null;
                    document.ontouchend = null;
                    if(flag){
                        this.jump()
                    }
                    if(this.isMobile) return
                    e.stimer = setTimeout(()=>{
                        this.reduction(odiv)
                    }, 1500)
                }
            }
        },
        // 还原
        reduction(e){
            let left = e.offsetLeft
            let width = e.clientWidth
            let creendW = document.body.clientWidth
            if(left > (creendW / 2)){
                startMove(e, {
                    left: creendW - width / 2
                }, () => {
                    e.setAttribute('isReduction', 'true')
                    console.log('true')
                })
            }else{
                startMove(e, {
                    left: -(width / 2)
                }, ()=>{
                    e.setAttribute('isReduction', 'true')
                    console.log('true')
                })
            }
        },
        // 鼠标移入
        mouseenter(even){
            console.log(even.target.getAttribute('isReduction'))
            if(even.target.getAttribute('isReduction') != 'true') return false;
            let e = even.target
            e.setAttribute('isReduction', null)
            let left = e.offsetLeft
            let width = e.clientWidth
            let creendW = document.body.clientWidth
            if(left < (creendW / 2)){
                startMove(e, {
                    left: width / 2
                }, () => {
                    e.setAttribute('isReduction', null)
                    e.stimer = setTimeout(()=>{
                        this.reduction(e)
                    }, 1500)
                })
            }else{
                startMove(e, {
                    left:  creendW - width
                }, () => {
                    e.setAttribute('isReduction', null)
                    if(this.isMobile) return
                    e.stimer = setTimeout(()=>{
                        this.reduction(e)
                    }, 1500)
                })
            }
        }
    },
    mounted() {
        if(this.isMobile) return
        let el = this.$refs.ContackPopup
        this.reduction(el)
    },
}
</script>

<style lang="less">
    #ContackPopup{
        position: fixed;
        right: 17px;
        top: 25%;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        .heffect;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 200;
        background: url('../../assets/imgs/icon/contactus.png');
        background-size: 100% 100%;
    }
</style>
