<template>
    <div id="Search" :class="isSearch?'floting':''" @click.self="()=>{
        isSearch = false
        shutSearch()    
    }">
        <div class="column">
            <div class="input">
                <el-input
                :placeholder="$t('search.pleaseEnter')"
                prefix-icon="el-icon-search"
                v-model="keyword" @change="search">
              </el-input>
                <div class="options">
                    <div class="pay btnMain pay paid" @click="search">
                        <p class="">{{$t('search.search')}}</p>
                    </div>
                </div>
            </div>
            <div class="hotWord" v-if="!isSearch">
                <p class="label">
                    {{$t('search.guessYouLike')}}: 
                </p>
                <span class="word" v-for="(item,index) in hotWordList" :key="index" @click="handlerClickHotWord(item.searchValue)">{{item.searchValue}}</span>
            </div>
        </div>
        <div class="content pubw" v-if="isSearch">
            <div class="entryList">
                <div class="tips" v-if="entryList.length == 0">
                    <span>{{$t('search.empty')}}</span>
                </div>
                <div class="item" v-for="item in entryList" :key="item.searchId">
                    <div class="title">
                        <p>
                            {{item[_i18n.locale == 'en' ? 'searchTitleEn' : 'searchTitleCn']}}
                        </p>
                    </div>
                    <div class="description" v-html="item[_i18n.locale == 'en' ? 'searchExplanaEn' : 'searchExplanaCn']">
                    </div>
                    <div class="link">
                        <span class="el-icon-link"></span>
                        <a :href="item.searchUrl">
                            {{item.searchUrl}}
                        </a>
                    </div>
                    <div class="arror" @click="goLink(item.searchUrl)">
                        <span class="el-icon-right"></span>
                    </div>
                </div>
            </div>
            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="entryList.length">
                </el-pagination>
            </div>
        </div> 
    </div>
</template>

<script>
    export default {
        name: "Search",
        props: ["shutSearch"],
        data(){
            return {
                // 关键字
                keyword: '',
                // 热词
                hotWordList: [],
                // 词条列表
                entryList: [
                    // {
                    //     createrBy: 1,
                    //     creationDate: "2021-12-07 15:09:21",
                    //     deleteFlag: 0,
                    //     lastUpdateDate: "2021-12-07 15:16:12",
                    //     lastUpdatedBy: 1,
                    //     searchExplanaCn: "<p>搜索引擎</p>",
                    //     searchExplanaEn: "<p>search engine</p>",
                    //     searchId: 40,
                    //     searchTitleCn: "百度一下",
                    //     searchTitleEn: "Bai Du",
                    //     searchUrl: "https://www.baidu.com/",
                    //     version: 1
                    // }
                    
                ],
                // 是否有内容
                isSearch: false,
            }
        },
        methods: {
            // 监听用户点击搜索
            async search(){
                this.entryList = []
                const res = await this.$http.get("/web/search/page",{
                    params: {
                        current: 1,
                        size: 20,
                        value: this.keyword
                    }
                })
                console.log("Search结果",res.data)
                this.entryList = res.data.records
                if(!this.isSearch){
                    this.isSearch = true
                }
            },
            // 监听用户点击热词
            handlerClickHotWord(word){

                this.keyword = word
                this.search()
            },
            // 获取猜你想搜数据
            async getHotWordData(){
                const res = await this.$http.get("/web/search/page/frequency")

                console.log("Search页面热词数据",res.data)
                this.hotWordList = res.data
            },
            // 跳转
            goLink(link){
                window.open(link)
            }
        },

        // 生命周期==>创建完成时
        created() {
            this.getHotWordData()
        },
    }
</script>

<style lang="less">
    #Search{
        position: fixed;
        top: 0;
        left: 0;
        text-align: center;
        z-index: 20;
        width: 100%;
        height: 100%;
        .flexcc();
        flex-wrap: wrap;
        background: rgba(0,0,0, .3);
        .input{
            width: 100%;
            .flexcc();
            .el-input{
                width: 7.5rem;
                height: .875rem;
                margin-right: .625rem;
                border-radius: .25rem;
                background: rgba(232, 232, 232, 0.14);
                border: 0.0125rem solid rgba(232, 232, 255, 0.14);
                input{
                    height: 100%;
                    width: 100%;
                    font-size: .25rem;
                    border-radius: .25rem;
                    background: rgba(232, 232, 232, 0.14);
                    color: #fff;
                    padding-left: 40px;
                    font-family: 'SourceHanSansCN-Medium';
                }
                .el-input__prefix{
                    left: 10px;
                }
                span{
                    .flexcc();
                    i{
                        font-size: .25rem;
                    }
                }
                .el-input.is-active .el-input__inner, .el-input__inner:focus{
                    border-color: @colorMain;
                }
            }
            .options{
                p{
                    font-size: 20px;
                }
            }
        }
        .column{
            margin: 0 auto;
            transition: all .6s;
            z-index: 99;
            .hotWord{
                .flexlc();
                margin: 0 auto;
                margin-top: .375rem;
                width: 10rem;
                .label{
                        font-size: .25rem;
                        font-family: 'SourceHanSansCN-Medium';
                    }
                span{
                    display: inline-block;
                    color: @colorMain;
                    font-size: .25rem;
                    margin: 0 .0625rem;
                    border-bottom: .0125rem solid #ccc;;
                    padding-bottom: .0375rem;
                    .heffect();
                    font-family: 'SourceHanSansCN-Medium' !important;
                }
            }
        }
        .content{
            animation: cjoin .6s forwards;
            .entryList{
                height: 643px;
                background: rgba(232, 232, 232, 0.14);
                border-radius: 20px;
                margin-top: .375rem;
                padding: 5px .375rem;
                overflow-y: auto;
                .tips{
                    padding-top: 20px;
                    span{
                        font-size: 20px;
                    }
                }
                .item{
                    position: relative;
                    text-align: left;
                    border-bottom: 1px solid rgba(232, 232, 232, 0.14);
                    padding: .25rem 0;
                    .title{
                        p{
                            text-align: left;
                            color: @colorMain;
                            font-size: .375rem;
                            font-family: 'SourceHanSansCN-Bold';
                        }
                    }
                    .description{
                        margin-top: .125rem;
                        margin-bottom: .1875rem;
                        p{
                            text-align: left;
                            color: #fff;
                            font-size: .25rem;
                            font-family: 'SourceHanSansCN-Light';
                        }
                    }
                    .link{
                        .flexlc();
                        span{
                            font-size: .225rem;
                            margin-right: .0625rem;
                        }
                        a{
                            font-family: 'Roboto-Light';
                            text-align: left;
                            font-size: .25rem;
                            color: #FFFFFF;
                            opacity: 0.5;
                            .heffect();
                            text-decoration: none;
                        }
                    }
                    .arror{
                        position: absolute;
                        bottom: .0625rem;
                        right: .0625rem;
                        span{
                            font-size: .3125rem;
                            font-weight: 580;
                            color: @colorMain;
                            .heffect();
                        }
                    }
                }
            }
            .entryList::-webkit-scrollbar {
                display: none;
            }
            .pagination{
                margin-top: .375rem;
                .flexcc();
                .el-pagination{
                    .el-pager{
                        li{
                            width: .45rem;
                            height: .45rem;
                            line-height: .45rem;
                            border-radius: .125rem;
                            background-color: rgba(255, 255 , 255, .6);
                            &:hover{
                                color: @colorMain;
                            }
                        }
                        .active{
                            background-color: @colorMain;
                        }
                    }
                    button{
                        width: 1rem;
                        height: .45rem;
                        text-align: center;
                        color: #000;
                        font-size: .175rem;
                        border-radius: .125rem;
                        background-color: rgba(255, 255, 255, .6);
                        i{
                            display: none;
                        }
                    }
                    .btn-prev::after{
                        content: '上一页';
                    }
                    .btn-next::after{
                        content: '下一页';
                    }
                }
            }
        }
    }
    #Search.floting{
        align-items: flex-start;
        .column{
            width: 16.25rem;
            animation: cremove .6s forwards;
            .input{
                justify-content: space-between;
                .el-input{
                    width: 90%;
                }
                input{
                    width: 100%;
                }
            }
        }
        .content{
            animation: cjoin .6s forwards; 
        }
    }
    @keyframes cremove{
        0{
            transform: translateY(200px);
        }
        100%{
            transform: translateY(80px);
        }
    }
    @keyframes cjoin{
        0{
            transform: translateY(0px);
        }
        100%{
            transform: translateY(20px);
        }
    }
</style>