<template>
    <div id="app">
      <!-- <NavTop/> -->
        <Header/>
        <div class="wrap" ref="wrap">
          <router-view/>
        </div>
<!--        <ContactCopup />-->
    </div>
</template>

<script>
import Header from '@/components/Header'
import ContactCopup from '@/components/Contact/index.vue'
import {mobileHeight} from './assets/js/rem'
// 横屏js
import rotate from '@/assets/js/rotate.js'
export default {
  name: 'App',
  components: {
    Header,
    ContactCopup
  },
  data(){
    return {
    }
  },
  mounted() {
    this.$nextTick(() => {
      // rotate()
    })
  },
  watch: {
    $route: {
      // 深度观察监听
      deep: true,
      handler(val,oldval){
        this.$nextTick(() => {
          if(!this.isMobile) return
          console.log('执行')
          mobileHeight()
        })
      },
    }
  },
  created() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if(isAndroid || isiOS){
      import('@/assets/css/mobile.css').then((err, me) => {
        console.log(err,me, 'errme')
      }).catch(err => err)
      window.sessionStorage.setItem('model', 'mobile')
    }else{
      this.mobile = false
      window.sessionStorage.setItem('model', 'pc')
    }
  },
}
</script>

<style lang="less">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}
.wrap{
  width: 100%;
  height: 100%;
}

/* 轮播图按钮 */
.el-carousel__arrow{
    background-size: 70px 70px !important;
    width: 70px !important;
    height: 70px !important;
    background: none;
    i{
      display: none;
    }
}
.el-carousel__arrow--left{
  background-image: url(../src/assets/imgs/prev.png) !important;
}
.el-carousel__arrow--right{
    background-image: url(../src/assets/imgs/next.png) !important;
}

.swiper-button-next{
    transform: translateY(-50%);
    background-image: url(../src/assets/imgs/next.png) !important;
    background-size: 0.875rem 0.875rem !important;
    width: 0.875rem !important;
    height: 0.875rem !important;
    background: none;
}
.swiper-button-prev{
    transform: translateY(-50%);
    background-image: url(../src/assets/imgs/prev.png) !important;
    background-size: 0.875rem 0.875rem !important;
    width: 0.875rem !important;
    height: 0.875rem !important;
    background: none;
}
</style>


<style>
  @media screen and (orientation: portrait) {
      html{
         width : 100% ;
         height : 100% ;
          background-color: white ;
          overflow : hidden;
      }
      body{
          width : 100% ;
         height : 100% ;
        overflow : hidden;
      }
      #print{
         position : absolute ;
         background-color: yellow ;
      }
}
@media screen and (orientation: landscape) {
       html{
         width : 100% ;
         height : 100% ;
         background-color: white ;
      }
       body{
          width : 100% ;
         height : 100% ;
         background-color: white ;
      }
           #app{
            position : absolute ;
            top : 0 ;
            left : 0 ;
            width : 100% ;
            height : 100% ;
         }
}
#app {
        margin: auto ;
        text-align: center;
      }

</style>

