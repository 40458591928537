<template>
    <div id="Problem">
        <div class="banner">
            <div class="imgMask"></div>
            <img src="../../../assets/imgs/freemeasure.jpg" alt="">
            <div class="floting">
                <div class="title">
                    <p class="title">{{$t('subPage.problem.title')}}</p>
                </div>
                <!-- <div class="subtitle">
                    <p class="text">{{$t('subPage.problem.subTitle')}}</p>
                </div> -->
            </div>
        </div>
        <ul class="b pubw render-html" v-html="pageData[_i18n.locale == 'en' ? 'problemEn' : 'problemCn']">
        
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'Problem',
        data(){
            return {
                // 页面数据
                pageData: {}
            }
        },
        methods: {
            // 获取页面数据
            async getPageData(){
                let res = await this.$http.get('/web/problem')
                this.pageData = res.data.tdProblemPage
                console.log(res, '常见问题页面数据')
            }
        },
        created() {
            this.getPageData()
        },
    }
</script>

<style lang="less">
    #Problem{
        min-height: 600px;
        .banner{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
            .floting{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                .title{
                    p{
                        font-size: 1.175rem;
                    }
                }
                .subtitle{
                    p{
                        font-size: .45rem;
                    }
                }
            }
        }
        .b{
            /* padding: 35px 0; */
            p{
                text-align: left;
            }
        }
    }
</style>