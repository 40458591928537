<template>
    <div id="OnlineChoice" :style="`background-image: url( ${bgImg});`">
        <div>
                    <!-- 容器 -->
        <div class="wrap pubw">
            <div class="hd">
                <h1 class="title">{{$t('shop.choice.title')}}</h1>
            </div>
            <div class="bd mobile-max-height mobileH35">
                <div class="left">
                    <div class="swipe">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(item,index) in swiperS" :key="index">
                                    <img :src="`${item}`" alt="窗帘" @click="handlerClickImg(item)">
                                </div>
                            </div>
                            <!-- 选项 -->
                            <div class="swiper-pagination" slot="pagination" ></div>
                            <div class="option">
                                <div class="vedio" @click="handlerVedio">
                                    <img src="./img/Video.png" alt="">
                                </div>
                                <div class="vr" @click="handlerVR">
                                    <img src="./img/VR.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="tips">
                            <p>{{$t('shop.choice.tips')}}</p>
                        </div>
                    </div>
                    <div class="section">
                        <!-- 下拉选项 -->
                        <div class="choices">
                            <div class="row">
                                <p class="label">{{$t('shop.choice.options.plan')}}</p>
                                <el-dropdown trigger="click" placement='top-start'>   
                                    <el-button  >
                                        {{curProduct[_i18n.locale == 'en' ? 'planNameEn' : 'planNameCn']}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown" trigger="click">
                                      <el-dropdown-item v-for="item in productList" :key="item.servicePlanId"> <span @click="handlerChoicePlan(item)">{{item[_i18n.locale == 'en' ? 'planNameEn' : 'planNameCn']}}</span></el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="row">
                                <p class="label">{{$t('shop.choice.options.curenv')}}</p>
                                <el-dropdown trigger="click" >
                                    <el-button type="primary" placement='top-start'>
                                        {{curEnv[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item v-for="item in planDetailList" :key="item.servicePlanDetailId"><span @click="handlerChoiceEnv(item)">{{item[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}</span> </el-dropdown-item>
                                    </el-dropdown-menu>
                                  </el-dropdown>
                            </div>
                            <div class="row">
                                <p class="label">{{$t('shop.choice.options.color')}}</p>
                                <el-dropdown trigger="click">
                                    <el-button type="primary">
                                        {{curEnv.params[_i18n.locale == 'en' ? 'colorNameEn' : 'colorNameCn']}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item v-for="item in curEnv.webProductDetailVos" :key="item.productDetailId"><span  @click="handlerChoiceColor(item)">{{item[_i18n.locale == 'en' ? 'colorNameEn' : 'colorNameCn']}}</span> </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="table">
                        <div class="hd">
                            <h2 class="title">{{curProduct[_i18n.locale == 'en' ? 'planNameEn' : 'planNameCn']}}</h2>
                            <div class="address">
                                <span class="value">({{curProduct[_i18n.locale == 'en' ? 'locationNameEn' : 'locationNameCn']}})</span>
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div class="myTable">
                            <ul class="tr">
                                <li class="th">{{$t('shop.choice.tabel.location')}}</li class="th">
                                <li class="th">{{$t('shop.choice.tabel.type')}}</li class="th">
                                <li class="th">{{$t('shop.choice.tabel.color')}}</li class="th">
                                <li class="th">{{$t('shop.choice.tabel.si')}}</li class="th">
                            </ul>
                            <ul class="tr" v-for="(item,index) in planDetailList" :key="item.productId">
                                <li class="td">{{item[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}</li >
                                <li class="td">{{item[_i18n.locale == 'en' ? 'productTypeEn' : 'productTypeCn']}}</li >
                                <li class="td">{{item.params[_i18n.locale == 'en' ? 'colorNameEn' : 'colorNameCn']}}</li >
                                <li class="td">
                                    <span class="description">{{item.params[_i18n.locale == 'en' ? 'productDescriptionsEn' : 'productDescriptionsCn']}}</span>
                                    <span class="iconfont icon-description" @click="alertText(index)"></span>
                                    <div class="textAleart" v-show="curDesIndex === index"  @click="alertText(index)">
                                            <span>{{item.params[_i18n.locale == 'en' ? 'productDescriptionsEn' : 'productDescriptionsCn']}}</span>
                                    </div>
                                </li >
                            </ul>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="HKD">
                            <!-- <p class="label">總費用(折扣後)：</p> -->
                            <p class="type">HKD</p>
                            <p class="price numFont">{{formatNum(totalCharges + '')}}</p>
                        </div>
                        <div class="addOrder pubBtnMed" @click="addOrder">
                            <p>{{$t('shop.choice.addOrder')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Foot/>
        </div>
    </div>
</template>

  <script>
import Foot from '@/components/Foot'
export default {
    name: 'OnlineChoice',
    props:["formatNum"],
    components: {
        Foot
    },
    data() {
        return {
            // 优惠券代码
            promotionCodeId: "",
            // 产品列表
            productList: [],
            // 当前产品对象
            curProduct: {},
            // 套餐明细列表
            planDetailList:  [],
            // 当前参考环境对象
            curEnv: {params: {},webProductDetailVos: []},
            // 控制选择套餐的下拉框是否显示
            planSelectShow: false,
            // 控制选择颜色的下拉框是否显示
            colorSelectShow: false,
            // 控制选择环境的下拉框是否显示
            envSelectShow: false,
            // 轮播图列表
            swiperS: [
            ],
            // 背景图片
            bgImg: process.env.BASE_URL + "bgImg/shop.png",
            // 当前正在展示产品描述的下标
            curDesIndex: ''
        }
    },
    methods: {
        // 根据ID获取套餐明细
        async getProductDetails(id,oldPlan){
            let res = await this.$http.get("/web/service/plan/detail?servicePlanId="+id)
            if(res.data.length <= 0){
                this.curProduct = oldPlan || this.productList[0]
                this.getProductDetails(this.curProduct.servicePlanId)
                return this.$message.info(this.$t('shop.shoice.palnNodata'))
            }
            console.log(res,"套餐明细")
            if(res.status !== 200) return this.$message.error(this.$t('shop.shoice.planErr'))
            let dataList = res.data
            dataList.forEach(item => {
                item.params = item.webProductDetailVos[0]
                item.webProductDetailVos.forEach(subItem => {
                    subItem.photosUrl = `${this.baseUrl}${subItem.photosUrl || '/upload/img/20211201104408.png'}`
                    subItem.videoUrl = `${this.baseUrl}${subItem.videoUrl}`
                })
            })
            // 添加轮播图
            this.updateSwiperData(dataList)
            console.log(dataList,"加工后的套餐明细")
            this.planDetailList = dataList
            this.curEnv = dataList[0]
        },
        // 控制下拉框的关闭隐藏
        chagnePlanSelected(attr){
            let valid = this[attr]
            // 先关闭之前在显示的select
            this.planSelectShow = false
            this.colorSelectShow =  false
            this.envSelectShow = false
            this[attr] = !valid
        },
        // 监听用户选择套餐
        async handlerChoicePlan(item){
            // 把新套餐的id和上一个套餐作为参数传递过去
            this.getProductDetails(item.servicePlanId,this.curProduct)
            this.curProduct = item
        },
        // 监听用户选择环境
        handlerChoiceEnv(item){
            this.curEnv = item
            this.updateSwiperData([item])
        },
        // 更新轮播图数据
        updateSwiperData(arr){
            this.swiperS = []
            console.log(arr,"arr")
            arr.forEach(item => {
                item.webProductDetailVos.forEach(subItem => {
                    this.swiperS.push(subItem.photosUrl)
                })
            })
        },
        // 监听用户选择颜色
        handlerChoiceColor(item){
            this.planDetailList.forEach(el => {
                if(el.servicePlanDetailId == this.curEnv.servicePlanDetailId){
                    el.params = item 
                }
            });
            this.updateSwiperData([{webProductDetailVos: [item]}])
        },
        // 监听用户点击确认下单
        addOrder(){
            window.sessionStorage.removeItem("choiceData")
            let choiceData = {
                productList: this.productList,
                planDetailList: this.planDetailList,
                curProduct: this.curProduct,
                curEnv: this.curEnv,
                totalCharges: this.totalCharges,
                promotionCodeId: this.promotionCodeId
            }
            window.sessionStorage.setItem("choiceData",JSON.stringify(choiceData))
            // 已登录
            if(window.sessionStorage.getItem("token") !== null){
                this.$router.push("/Shop/Order")
            }else{
                window.sessionStorage.setItem('isPlace',true)
                // 未登录
                this.$message(this.$t('shop.choice.plsLogin'))
                this.$router.push("/Register")
            }

        },
        // 监听用户点击观看视频
        handlerVedio(){
            this.$router.push({
                name: "Vedio",
                params:{
                    src: this.curEnv.params.videoUrl,
                    couverSrc: this.curEnv.params.photosUrl || "/bgImg/freeRule.png"
                }
            })
        },
        // 监听用户点击观看VR
        handlerVR(){
            if(this.curEnv.params.vrUrl == '' || this.curEnv.params.vrUrl == undefined){
                return this.$message.info(this.$t('shop.choice.noVr'),this.curEnv.params.vrUrl)
            }
            this.$router.push({
                name: "VR",
                params:{
                    src: `${this.baseUrl}${this.curEnv.params.vrUrl}` || "bgImg/vr.jpg",
                }
            })
        },
        // 监听用户点击产品详情信息
        alertText(index){
            if(this.curDesIndex === index){
                this.curDesIndex = ''
            }else{
                this.curDesIndex = index
            }
        },
        // 把信息保存在session里
        updateChoiceData(){
            let choiceData = {
                productList: this.productList,
                planDetailList: this.planDetailList,
                curProduct: this.curProduct,
                curEnv: this.curEnv,
                totalCharges: this.totalCharges,
                promotionCodeId: this.promotionCodeId
            }
            window.sessionStorage.removeItem("choiceData")
            window.sessionStorage.setItem("choiceData",JSON.stringify(choiceData))
        },
        // 监听用户点击图片
        handlerClickImg(path){
            window.imgLayer.add(path)
        },
        // 创建图片图层
        imgLayer(){
            let dom;
            return (function(){
                let manager = {
                    add(path){
                        dom = dom || this.createDom()
                        this.showDom(path)
                    },
                    createDom(path){
                        let dom = document.createElement('div')
                        let img = document.createElement('img')

                        dom.classList.add('choice-img-wrap')
                        dom.style.position = 'fixed'
                        dom.style.background = '#000'
                        dom.style.zIndex = '30'
                        dom.style.top = '0'
                        dom.style.left = '0'
                        dom.style.width = '100%'
                        dom.style.height = '100%'
                        dom.style.overflow = 'none'
                        dom.style.display = 'flex'
                        dom.style.justifyContent = 'center'
                        dom.style.alignItems = 'center'
                        img.style.width = '80%'
                        img.style.height = '90%'
                        dom.append(img)
                        return dom
                    },
                    removeDom(){
                        dom.remove()
                    },
                    showDom(path){
                        dom.children[0].src = path
                        document.body.append(dom)
                        dom.onclick = this.removeDom
                    },
                    initImg(){
                        console.log(dom)
                        console.log(dom.children[0].naturalWidth,dom.children[0].naturalHeight)
                    }
                }
                return manager
            })();
        }
    },

    computed:{
        //   计算总价
        totalCharges(){
            let m = 0
            this.planDetailList.forEach(item => {
                m  += item.params.productPrice
            })
            return Math.round(m * this.curProduct.discount)
        }
    },
    // 生命周期==>挂载完毕
    created(){
        // 获取之前保留过的参数
        let choiceData = JSON.parse(window.sessionStorage.getItem("choiceData"))
        console.log(choiceData)
        if(choiceData != null && typeof choiceData != "null"){
            console.log("用sestion数据",this.$route.params)
            console.log(choiceData,"在线选择页面收到了套餐数据")
                this.productList =  this.$route.params.product || choiceData.productList
                this.planDetailList = choiceData.planDetailList
                if(typeof this.$route.params.product != "undefined"){
                    this.curProduct = this.$route.params.product[0] 
                }else{
                    this.curProduct = choiceData.curProduct
                    console.log(choiceData)
                }
                this.curEnv =  choiceData.curEnv
                this.promotionCodeId = this.$route.params.promotionCodeId || choiceData.promotionCodeId
        // 代表初始进入界面
        }else{
            // 接受产品列表
            this.productList = this.$route.params.product
            // 接受优惠券代码
            this.promotionCodeId = this.$route.params.promotionCodeId
            console.log( this.$route.params.product,"初次进入")
            this.curProduct = this.$route.params.product[0] || {}
        }
        this.getProductDetails(this.curProduct.servicePlanId)
        window.imgLayer = this.imgLayer()
    },
    // 生命周期==>挂载完成
    mounted() {
        // 创建swiper轮播图实例
        this.mySwiper = Swiper('.swiper-container', {
            pagination:'.swiper-pagination',
            paginationClickable: true,
            slidesPerView: 1,
            observer:true
        });

        let that = this
        window.onbeforeunload = function(){
            console.log('刷新')
            that.updateChoiceData()
        }
    },
    // 生命周期==>销毁前
    beforeDestroy() {
        this.updateChoiceData()
    },
    updated() {
        //实例更新完成
        this.mySwiper.update()
    },
}
  </script>
  
  <style lang="less">
      #OnlineChoice{
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          .wrap{
                width: 1400px;
                padding-top: 100px;
                .hd{
                    text-align: center;
                    border-bottom: .0125rem solid rgba(232, 232, 255, 0.14);
                    padding-bottom: .25rem;
                    .title{
                        font-size: .6rem;
                        color: #fff;
                    }
                }
                .bd{
                    margin-top: 30px;
                    .flexbb();
                    height: 627px;
                    .left{
                        width: 52%;
                        .swipe{
                            position: relative;
                            width: 100%;
                            height: 445px;
                            border-radius: .3120rem;
                            background-color: #000;
                            margin-bottom: 55px;
                            &:hover{
                                cursor: pointer;
                            }
                            .swiper-container{
                                width: 100%;
                                height: 100%;
                                border-radius: .3120rem;
                                overflow: hidden;
                                .swiper-wrapper{
                                    .swiper-slide{
                                        width: 100%;
                                        height: 100%;
                                        border-radius: .3125rem;
                                        overflow: hidden;
                                        img{
                                            border-radius: .3120rem;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                                .swiper-pagination{
                                    text-align: left;
                                    padding-left: .1875rem;
                                    z-index: 30;
                                    bottom: .3125rem;
                                    width: 80%;
                                    .swiper-pagination-bullet{
                                        width: .125rem;
                                        height: .125rem;
                                        border: .0125rem solid #FFFFFF;
                                        border-radius: 50%;
                                        margin: 0 .15rem;
                                    }
                                    .swiper-pagination-bullet.swiper-pagination-bullet-active{
                                        background: #FFFFFF;
                                    }
                                }
                            }
                            .option{
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                padding: .25rem;
                                z-index: 20;
                                background: #000;
                                border-bottom-right-radius: .3120rem;
                                border-bottom-left-radius: .3120rem;
                                background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.5));
                                .flexjb();
                                .vedio,.vr,{
                                    width: .45rem;
                                    height: .45rem;
                                    line-height: .45rem;
                                    color: #fff;
                                    .heffect();
                                    margin-left: 12px;
                                    img{
                                        width: .45rem;
                                        height: .45rem;
                                    }
                                }
                            }
                            .tips{
                                margin-top: 22px;
                                text-align: left;
                                p{
                                    text-align: left;
                                    font-size: 14px;
                                    font-family: SourceHanSansCN-Regular;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    opacity: 0.5;
                                }
                            }
                        }
                        .section{
                            width: 100%;
                            .flexbc();
                            .choices{
                                width: 100%;
                                .flexcc();
                                justify-content: space-between;
                                .row{
                                    .flex();
                                    flex-wrap: wrap;
                                    width: 30%;
                                    height: 50px;
                                    margin-top: 26px;
                                    .label{
                                        color: #fff;
                                        font-size: .25rem;
                                        width: 100%%;
                                        text-align-last: justify;
                                        line-height: .625rem;
                                        opacity: .8;
                                    }
                                    .el-dropdown{
                                        width: 2.75rem;
                                        height: .625rem;
                                        .el-dropdown-menu{
                                            width: 100%;
                                        }
                                        .el-button{
                                            background: rgba(232, 232, 232, 0.3);
                                            border: .0125rem solid rgba(232, 232, 255, 0.14);
                                            border-radius: .1875rem;
                                            width: 100%;
                                            height: 100%;
                                            span{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                color: rgba(237, 130, 32, 1);
                                                font-size: .25rem;
                                                .el-icon--right{
                                                    color: #fff;
                                                    font-size: 25px;
                                                }
                                            }
                                        }
                                    }
                                    .selected{
                                        position: relative;
                                        color: #fff;
                                        width: 2.75rem;
                                        height: .625rem;
                                        background: rgba(255, 255, 255, 0.05);
                                        border: .0125rem solid rgba(232, 232, 255, 0.14);
                                        border-radius: .1875rem;
                                        .content{
                                            .flexbc();
                                            width: 100%;
                                            height: 100%;
                                            .heffect();
                                            .arrow{
                                                margin-right: .1875rem;
                                                i{
                                                    animation: arrowDown .6s forwards;
                                                    &:hover{
                                                        animation: arrowUp .6s forwards;
                                                    }
                                                }
                                            }
                                            .value{
                                                color: rgba(237, 130, 32, 1);
                                                font-size: .25rem;
                                                margin-left: .1875rem;
                                            }
                                        }
                                        .selection{
                                            position: absolute;
                                            padding: 0 .1875rem;
                                            width: 100%;
                                            overflow: hidden;
                                            height: 0rem;
                                            transition: all .6s;
                                            text-align: left;
                                            line-height: .375rem;
                                            border-radius: .0625rem;
                                            opacity: .8;
                                            background-color: rgba(232, 232, 232, 1);
                                            z-index: 50;
                                            li{
                                                font-size: .225rem;
                                                margin: .025rem 0;
                                                color: #000;
                                                height: .375rem;
                                                line-height: .375rem;
                                                .heffect();
                                            }
                                        }
                                        .selection.passsive{
                                            height: 100px;
                                            height: 0px !important;
                                        }
                                    }
                                    .selected:hover{
                                        cursor: pointer;
                                    }
                                }
                                .row:last-child{
                                    .selected{
                                        &:hover{
                                            cursor: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .right{
                        .flexjb();
                        flex-wrap: wrap;
                        width: 46%;
                        .table{
                            width: 100%;
                            height: 445px;
                            border: 0.0125rem solid rgba(232, 232, 255, 0.14);
                            background: rgba(255, 255, 255, 0.05);
                            border-radius: .25rem;
                            padding: .375rem 15px;
                            .hd{
                                display: flex;
                                align-items: center;
                                margin-bottom: .4375rem;
                                .title{
                                    display: flex;
                                    align-items: flex-end;
                                    text-align: left;
                                    color: rgba(237, 130, 32, 1);
                                    font-size: .3rem;
                                    margin-left: 15px;
                                    margin-right: 15px;
                                }
                                .address{
                                    margin-top:     5px;
                                    .value{
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                    }
                                }
                            }
                            .myTable{
                                color: #fff;
                                font-size: .225rem;
                                width: 100%;
                                border-collapse:separate;
                                border-spacing:0px;
                                line-height: .375rem;
                                .tr{
                                    width: 100%;
                                    display: flex;
                                }
                                .th{
                                    /* .flexcb(); */
                                    font-size: 19px;
                                    font-weight: 500;
                                    opacity: .8;
                                    font-family: 'SourceHanSansCN-Medium';
                                }
                                .td,.th{
                                    border-bottom: 0.0125rem solid rgba(232, 232, 255, 0.14);
                                    margin: 0;
                                    padding: 0;
                                    width: 22%;
                                    padding: .125rem 0;
                                    text-align: center;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                                .th:last-child{
                                    width: 34%;
                                }
                                .td{
                                    text-align: center;
                                    font-size: 14px;
                                    font-family: 'SourceHanSansCN-Light';
                                    white-space: nowrap;
                                }
                                .td:last-child{
                                    position: relative;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 34%;
                                    overflow: inherit;
                                    .description{
                                        width: 85%;
                                        white-space:nowrap;
                                        overflow:hidden;
                                        text-overflow:ellipsis;
                                        text-align: center;
                                        font-size: 14px;
                                        font-family: 'SourceHanSansCN-Light';
                                    }
                                    .iconfont{
                                        width: 10%;
                                        .heffect();
                                    }
                                    .textAleart{
                                        position: absolute;
                                        right: -100%;
                                        width: 200px;
                                        border: 0.0125rem solid rgba(232, 232, 255, 0.14);
                                        box-shadow: 2px 2px 2px rgba(232, 232, 255, 0.14);
                                        padding: 8px;
                                        text-indent: 10px;
                                        span{
                                            white-space: normal;
                                        }
                                    }
                                }
                            }
                        }
                        .bottom{
                            .flexjbe();
                            .HKD{
                                text-align: end;
                                .flexle();
                                color: rgba(237, 130, 32, 1);
                                .label{
                                    color: #fff;
                                    margin-right: .25rem;
                                    font-size: .25rem;
                                    font-weight: 500;
                                }
                                .type{
                                    margin-right: .0625rem;
                                    font-size: .25rem;
                                }
                                .price{
                                    font-size: .375rem;
                                    line-height: .4375rem;
                                }
                            }
                            .addOrder{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: fit-content;
                                padding: 10px 20px;
                                font-weight: 500;
                                color: #FFFFFF;
                                height: .875rem;
                                background: rgba(237, 130, 32, 0.6);
                                border-radius: .25rem;
                                margin-left: .625rem;
                                .heffect();
                                text-align: center;
                                p{
                                    font-size: .25rem;
                                }
                            }
                        }
                    }
                }
            }
            #Foot{
                position:initial;
                z-index: 0;
                margin-top: 30px;
            }
      }
      .el-dropdown-menu{
        width: 215px;
        border-radius: .0625rem;
        background-color: rgba(232, 232, 232, .8) !important;
        border: none !important;
        .popper__arrow{
            display: none !important;
        }
        .el-dropdown-menu__item{
            padding: 0;
            text-align: left;
            line-height: .375rem;
            span{
                display: block;
                font-family: SourceHanSansCN-Regular;
                width: 100%;
                padding: 0 20px;
                font-size: .225rem;
                color: #000;
            }
        }
        .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
            color: @colorMain;
        }
    }
  </style>
  