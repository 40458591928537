<template>
    <div id="order" :style="`background-image: url( ${bgImg});`">
        <div :class="['wrap', 'pubw', isShowClauseAlert || showAddAlert? 'myBlur' : '']">
            <div class="hd">
                    <h1 class="title">{{$t('shop.orderDetails.title')}}</h1>
            </div>
            <div class="bd mobile-max-height">
                <div class="installInfo">
                    <h2 class="title">{{$t('shop.orderDetails.install.title')}}
        
                    </h2>
                        <div class="row">
                            <p class="label">{{$t('shop.orderDetails.install.name')}}： </p>
                            <p class="value"> {{memberInfo.memberName}}</p>
                            <div class="add" @click="showAddAlert = true" v-if="!hasConPersonTow && !isOO" >
                                <img src="../../../assets/imgs/icon/addConper.png">
                                <span>{{$t('shop.orderDetails.install.addConperTow')}}</span>
                            </div>
                            <div class="details" @click="showAddAlert = true" v-if="isOO ? conPersonTwo.phone != '' && conPersonTwo.name != '' : false">
                                <img src="../../../assets/imgs/icon/cheConper.png">
                                <span>{{$t('shop.orderDetails.install.checkConperTow')}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <p class="label"> {{$t('shop.orderDetails.install.phone')}}：</p>
                            <p class="value numL"> {{memberInfo.memberMobil}}</p>
                        </div>
                        <div class="row">
                            <p class="label"> {{$t('shop.orderDetails.install.email')}}：</p>
                            <p class="value numL"> {{memberInfo.memberEmail}}</p>
                        </div>
                        <div class="row adsRow">
                            <p class="label"> {{$t('shop.orderDetails.install.address')}}：</p>
                            <p class="value adsVo" v-show="!iptShow">{{address}}</p>
                            <span class="el-icon-close icon"  @click="clearAddress" v-show="iptShow"></span>
                            <input type="text" v-model="address" v-show="iptShow" class="adsIpt" @blur="handlerEditAddress">
                            <div id="edit" v-model="address" v-show="!iptShow" @click="handlerEditAddress">
                                <img src="../../../assets/imgs/edit.png" alt="">
                            </div>
                        </div>
                        <!-- <div class="labelt">{{$t('shop.orderDetails.install.measureDate')}}：
                            <span v-if="isOO">({{$t('shop.orderDetails.install.tip')}})</span>
                        </div> -->
                        <!-- <p class="label">{{$t('shop.orderDetails.install.measureDateSP')}}：</p> -->
                        <div class="row two">
                            <p class="label">{{$t('shop.orderDetails.install.measureDate')}}：</p>
                            <div class="right">
                                <date
                                :disable="choiceData.paymentStatus == '1' ? true : isOO && choiceData.orderStatus != '9' ? true:false"
                                :placeholder="$t('shop.orderDetails.install.main')"
                                timeStep = "2:00"
                                :defaultTime="measureDefaultTime"
                                @updateDate="handlerChoiceMeasureDate"
                                :startDateDisabel="meaStartDateDisabel"/>   
                                <date
                                v-if="isOO ? choiceData.orderStatus == '9': true"
                                :disable="choiceData.paymentStatus == '1' ? true : false"
                                :placeholder="$t('shop.orderDetails.install.minor')"
                                timeStep = "2:00"
                                :defaultTime="measureSpareDefaultTime"
                                @updateDate="handlerChoiceMeasureSPDate"
                                :startDateDisabel="meaStartDateDisabel"/>
                            </div>
                        </div>
                        <div class="row" v-if="isOO ">
                            <p class="label">{{$t('shop.orderDetails.install.deyDate')}}：</p>
                                <date
                                :disable="true"
                                :placeholder="$t('shop.orderDetails.install.untime')"
                                timeStep = "2:00"
                                :defaultTime="deliveryDefaultTime"
                                :isclear="true"
                                @updateDate="handlerChoiceDeliveryDate"
                                :startDateDisabel="nextMonthDisabel || new Date()"/>
                        </div>
                         <div class="row" v-if="isOO">
                            <p class="label">{{$t('shop.orderDetails.install.installDate')}}：</p>
                                <date
                                :disable="true"
                                :placeholder="$t('shop.orderDetails.install.untime')"
                                timeStep = "2:00"
                                :isclear="true"
                                :defaultTime="installDefaultTime"
                                @updateDate="dateObj=>{installDate = dateObj.start,installDateEnd = dateObj.end}"
                                :startDateDisabel="afterDisabel || new Date()"/>
                        </div>
                        <div class="dateTip" v-if="isOO && choiceData.paymentStatus == '1'">
                            * {{$t('shop.orderDetails.install.tip')}}。
                        </div>
                </div>
                <div class="planInfo">
                    <div class="table">
                        <div class="hd">
                            <h2 class="title">{{!isOO?choiceData.curProduct[_i18n.locale == 'en' ? 'planNameEn' : 'planNameCn'] : choiceData.curProduct[_i18n.locale == 'en' ? 'productTypeEn' : 'productTypeCn']}}</h2>
                            <div class="refresh" @click="prev" v-if="!isOO">
                                <img src="../../../assets/imgs/refresh.png" alt="">
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div class="myTable">
                            <ul class="tr">
                                <li class="th">{{$t('shop.choice.tabel.location')}}</li class="th">
                                    <li class="th">{{$t('shop.choice.tabel.color')}}</li class="th">
                                    <li class="th">{{$t('shop.choice.tabel.type')}}</li class="th">
                                <li class="th">{{$t('shop.choice.tabel.si')}}</li class="th">
                            </ul>
                            <ul class="tr" v-for="(item,index) in choiceData.planDetailList" :key="item.productId">
                                <li class="td">{{item[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}</li >
                                <li class="td">{{!isOO?item.params[_i18n.locale == 'en' ? 'colorNameEn' : 'colorNameCn'] : item[_i18n.locale == 'en' ? 'colorNameEn' : 'colorNameCn']}}</li >
                                <li class="td">{{!isOO?item[_i18n.locale == 'en' ? 'productTypeEn' : 'productTypeCn'] : item[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}</li >
                                <li class="td">
                                    <span class="description">{{!isOO?item.params[_i18n.locale == 'en' ? 'productDescriptionsEn' : 'productDescriptionsCn'] : item[_i18n.locale == 'en' ? 'productDescriptionsEn' : 'productDescriptionsCn']}}</span>
                                    <span class="iconfont icon-description" @click="alertText(index)"></span>
                                    <div class="textAleart" v-show="curDesIndex === index"  @click="alertText(index)">
                                            <span>{{!isOO?item.params[_i18n.locale == 'en' ? 'productDescriptionsEn' : 'productDescriptionsCn']: item[_i18n.locale == 'en' ? 'productDescriptionsEn' : 'productDescriptionsCn']}}</span>
                                    </div>
                                </li >
                            </ul>
                        </div>
                    </div>
                    <div class="b">
                        <!-- <div class="clause" v-if="!isOO">
                            <img src="../../../assets/imgs/icon/checked-o.png" alt="" v-show="agreeClause" @click="agreeClause = false">
                            <div class="checkbox" v-show="!agreeClause" @click="agreeClause = true"></div> 
                            <p >
                                {{$t('shop.orderDetails.clause')}}
                                <span @click="showClauseAlert">
                                    {{$t('shop.orderDetails.clause2')}}
                                </span>
                            </p>
                        </div> -->
                        <div ></div>
                        <div class="price">
                            <span>{{$t('shop.orderDetails.totalCost')}}：</span>
                            <div class="hdk">
                                <span>HDK</span>
                                <span class="total numFont">{{formatNum(choiceData.totalCharges + '')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="options">
                    <div class="prev btn" @click="$router.go(-1)" v-if="isOO">
                        <p>{{$t('shop.orderDetails.prevBtn')}}</p>
                    </div>
                    <div class="prev btn" @click="prev" v-if="!isOO">
                        <p>{{$t('shop.orderDetails.prevBtn')}}</p>
                    </div>
                    <div class="pay btnMain" @click="showClause" v-if="choiceData.paymentStatus == '0' || !isOO">
                        <p>{{$t('shop.orderDetails.placeBtn')}}</p>
                    </div>
                    <div class="pay btnMain unpaid" @click="edit" v-if="isOO && choiceData.paymentStatus == '0'">
                        <p>{{$t('shop.orderDetails.editBtn')}}</p>
                    </div>
                    <div class="pay btnMain pay paid"  v-if="isOO && choiceData.paymentStatus == '1'">
                        <p class="">{{$t('shop.orderDetails.paidBtn')}}</p>
                    </div>    
                </div>
            </div>


        </div>
        <!-- 第二联系人弹窗 -->
        <div class="alret" v-show="showAddAlert">
            <div class="mask" @click="showAddAlert = false"></div>
            <div class="container">
                <p class="tips">{{$t("shop.orderDetails.conPersonTow")}}
                    <!-- <span class="opt">{{$t("shop.orderDetails.opt")}}</span> -->
                </p>
                <div class="bd">
                    <el-form :model="conPersonTwo" :rules="conPersonTwoRule" ref="conPersonTwoForm" class="form">
                        <el-form-item :label="$t('shop.orderDetails.install.name') + ':'" prop="name">
                            <el-input v-model="conPersonTwo.name" ></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('shop.orderDetails.install.phone') + ':'" prop="phone">
                            <el-input v-model="conPersonTwo.phone" ></el-input>
                        </el-form-item>
                    </el-form>
                    <!-- <div class="row">
                        <p class="label"> {{$t('shop.orderDetails.install.email')}}：</p>
                        <p class="value numL"> 
                            <el-input  v-model="conPersonTwo.email"></el-input>
                        </p>
                    </div>
                    <div class="row adsRow">
                        <p class="label"> {{$t('shop.orderDetails.install.address')}}：</p>
                        <p class="value adsVo" v-show="!iptShow">{{conPersonTwo.adddress}}</p>
                        <span class="el-icon-close icon"  @click="clearAddress" v-show="iptShow"></span>
                        <input type="text" v-model="conPersonTwo.adddress" v-show="iptShow" class="adsIpt" @blur="handlerEditAddress">
                        <div id="edit"  v-show="!iptShow" @click="handlerEditAddress">
                            <img src="../../../assets/imgs/edit.png" alt="">
                        </div>
                    </div> -->
                </div>
                <div class="options" v-if="!isOO">
                    <div class="complate pubBtnOne btn" @click="showAddAlert = false">
                        <p>{{$t("member.cancel")}}</p>
                    </div>
                    <div class="complate pubBtnOne btnMain" @click="complateAddConPer">
                        <p>{{$t("member.confirm")}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 条款规则弹窗 -->
        <div class="alret clause" v-show="isShowClauseAlert">
            <!-- <div class="mask" @click="isShowClauseAlert = false"></div> -->
            <div class="container">
                <p class="tips"> {{$t('shop.orderDetails.clause2')}}</p>
                <div class="box">
                    <div class="b">
                        <ul class="clause" v-html="clausePageData[_i18n.locale == 'en' ? 'termsEn' : 'termsCn']">
            
                        </ul>

                        <div class="options">
                            <div class="btn pubBtnOne" @click="isShowClauseAlert = false;agreeClause = false">
                                <p>{{$t('shop.orderDetails.refuse')}}</p>
                            </div>
                            <div class="btnMain pubBtnOne" @click="handlerAgreeClause">
                                <p>{{$t('shop.orderDetails.agree')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Foot/>
    </div>
</template>

<script>
// 引入格式化时间文件
import formatDate from "@/assets/js/formatDate.js"
// 引入loding组件
import { Loading } from 'element-ui';
// 引入预约时间组件
import date from "@/components/Date"
import Foot from '@/components/Foot'
export default {
    name: "order",
    props:["formatNum"],
    components: {
        date,
        Foot
    },
    data(){
            // 验证电话正则
            let checkMobile = (rule,value,callback)=>{
                const regMobile= /^\d{8}|\d{11}$/
                // const  regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                if(!regMobile.test(value)) return callback(new Error(this.$t('register.phoneErr')))
                return callback()
            }
        return{
            // 是否为旧订单 从用户中心跳转过来的
            isOO: false,
            // 背景图片
            bgImg: process.env.BASE_URL + "bgImg/shop.png",
            // 修改地址ipt是否展示
            iptShow: false,
            // 地址
            address: "",
            // 用户信息
            memberInfo:"",
            // 订单列表
            orderList: [
                {
                    id: "0",
                    number: "0020018",
                    status: "等待交付...",
                    planName: '服務計劃A',
                    productType: "斑馬簾",
                    color: "（白色，黃色，紅色）",
                    price: 3600
                }
            ],
            // 测量日期
            measureDate:"",
            measureDateEnd: "",
            measurementStartSpare: "",
            measurementEndSpare: "",
            measureDefaultTime: undefined,
            measureSpareDefaultTime: undefined,
            meaStartDateDisabel: new Date(), //测量日期可选择的开始时间
            // 交付日期
            deliveryDate:"",
            deliveryDateEnd: "",
            deliveryDefaultTime: undefined,
            // 安装日期
            installDate:"",
            installDateEnd: "",
            installDefaultTime: undefined,
            // 交付日期
            nextMonthDisabel: '',
            // 安装日期
            afterDisabel: '',
            // 当前正在展示产品描述的下标
            curDesIndex: '',
            // 用户之前选择的产品数据
            choiceData: JSON.parse(window.sessionStorage.getItem("choiceData")),
            // 禁用时间
            disabelTime: {
                start: '8:00',
                end: "12:00"
            },
            // 是否展示添加用户弹窗
            showAddAlert: false,
            // 第二联系人信息
            conPersonTwo: {
                name: '',
                email: '',
                phone: '',
                address: ''
            },
            // 第二联系人表单规则
            conPersonTwoRule: {
                phone: {
                    required: true,
                    validator: checkMobile,
                    trigger: "blur",
                },
            },
            // 是否同意协议
            agreeClause: false,
            // 是否展示条款
            isShowClauseAlert: false,
            // 是否有第二联系人
            hasConPersonTow: false,
            // 条款细则数据
            clausePageData: {},
        }
    },
    methods:{
        // 获取会员信息
        async getMemberInfo(){
            
            let res = await this.$http.get("/web/member")
            if(res.status != 200) return this.$message.error(this.$t('member.getInfoErr'))
            this.memberInfo = res.data

            this.address = this.choiceData.deliveryAddress || this.memberInfo.address
        },  
        // 监听用户修改地址
        async handlerEditAddress(){
                if(this.address == ''){
                    this.address = this.memberInfo.address
                }
            // if(this.iptShow){
            //     if(this.address == ''){
            //         this.address = this.memberInfo.address
            //     }
            //     // 需要修改地址
            //     let res = await this.$http.put("/web/member/edit",{
            //         ...this.memberInfo
            //     })
            //     this.$message(res.data.msg)
            //     console.log(res,"修改地址结果")
            // }
            this.iptShow = !this.iptShow
        },
        // 获取订单
        async getOrderList(){
            let res = await this.$http.get("/web/order/list")
            console.log(res,"订单列表")
        },
        // 下单
        async addOrder(orderVo){
            let loadingInstance = Loading.service();
            let res = await this.$http.post("/web/order/add/pay",{
                ...orderVo
            })
            console.log(res,"下单结果")
            if(res.data.msg == "success"){
                loadingInstance.close();
                res.data.href += '?timestamp=' + Math.random() //兼容ios安全||超时 的处理
                if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
                    location.href = res.data.href //此处判断是否为ios后,使用location.href跳转
                }else{
                    window.open(res.data.href)
                }
                // this.$router.push("/member")
            }else{
                loadingInstance.close();
                this.$message.error(res.data.msg)
            }
        },
        // 监听用户查看订单
        handlerShowOrder(item){
            console.log(item)
        },
        // 监听用户点击上一步
        prev(){
            // 保存现在选择的数据到session
            let date = JSON.stringify({
                // 测量日期
                measureDate: this.measureDate || '',
                // 交付日期
                deliveryDate: this.deliveryDate || '',
                // 安装日期
                installDate: this.installDate || ''
            })
            window.sessionStorage.setItem("date",date)

            this.$router.push("/Shop/OnlineChoice")
        },
        // 监听用户点击立付款
        pay(){
            
            console.log(this.measureDate  == '' , this.deliveryDate  == '')
        //    if(this.measureDate  == '' || this.deliveryDate  == '' || this.installDate == '' || this.measureDateEnd  == '' || this.deliveryDateEnd  == '' || this.installDateEnd == ''){
           if(!this.agreeClause) {
                this.$message.error(this.$t("shop.orderDetails.clauseAlert"))
                return
            }
            // 处理信息
            let orderVo = {}
            let choiceData = this.choiceData
            // 订单基础信息
            orderVo.webOrder = {
                    "deliveryAddress": this.address,
                    //  "deliveryEnd": formatDate(this.deliveryDateEnd).spriteDate,
                    //  "deliveryStart": formatDate(this.deliveryDate).spriteDate,
                    //  "installationEnd": formatDate(this.installDateEnd).spriteDate,
                    //  "installationStart": formatDate(this.installDate).spriteDate,
                    "measurementEnd": formatDate(this.measureDateEnd).spriteDate,
                    "measurementStart": formatDate(this.measureDate).spriteDate,
                    "measurementStartSpare": formatDate(this.measurementStartSpare).spriteDate,
                    "measurementEndSpare": formatDate(this.measurementEndSpare).spriteDate,
                    "email": this.memberInfo.email,
                    emailSpare: this.conPersonTwo.email,
                    "name": this.memberInfo.name,
                    "nameSpare": this.conPersonTwo.name,
                    "phone": this.memberInfo.phone,
                    phoneSpare: this.conPersonTwo.phone,
                    "promotionCodeId": choiceData.curProduct.promotionCodeId,
                    "servicePlanId": choiceData.curProduct.servicePlanId,
                    "totalCharges": choiceData.totalCharges
                }
                // 订单套餐物品信息
                let webOrderDetails = []
                choiceData.planDetailList.forEach( item=> {
                    let obj = {}
                    obj.serviceDetailId = item.servicePlanDetailId
                    obj.productDetailId = item.params.productDetailId
                    webOrderDetails.push(obj)
                })
                // 订单套餐物品信息
            orderVo.webOrderDetails = webOrderDetails
            console.log(JSON.stringify(orderVo),orderVo)
            // 下单
            this.addOrder(orderVo)
        },
        // 清空地址
        clearAddress(){
            this.address = ''
        },
        // 监听用户选择测量日期
        handlerChoiceMeasureDate(dateObj){
            let {start, end , nextMonthDisabel} = dateObj
            this.measureDate = start
            this.measureDateEnd = end
            this.nextMonthDisabel = nextMonthDisabel
            console.log(nextMonthDisabel, start, "下个月的禁用时间")
            if(this.isOO){
                // isOO代表是从会员中心点击订单过来的,回填日期
                this.measureDefaultTime.date = formatDate(start).spritDate,
                this.measureDefaultTime.time = formatDate(start).time
            }
           
            // 清空交付日期和安装日期
            this.deliveryDateEnd = ''
            this.deliveryDate = ''
            this.installDate = ''
            this.installDateEnd = ''
            this.deliveryDefaultTime = ''
            this.installDefaultTime = ''
            this.$bus.$emit("clearDate")
        },
        // 监听用户选择备用测量日期
        handlerChoiceMeasureSPDate(dateObj){
            console.log(dateObj, 'date')
            let {start, end , nextMonthDisabel} = dateObj
            this.measurementStartSpare = start
            this.measurementEndSpare = end
        },
        // 监听用户选择交付日期
        handlerChoiceDeliveryDate(dateObj){
            let {start, end , afterDisabel} = dateObj
            this.deliveryDate = start
            this.deliveryDateEnd = end
            this.afterDisabel = afterDisabel

            this.installDate = ''
            this.installDateEnd = ''
            this.installDefaultTime = ''
        },
        // 监听用户点击产品详情信息
        alertText(index){
            if(this.curDesIndex === index){
                this.curDesIndex = ''
            }else{
                this.curDesIndex = index
            }
        },
        // 判断文本是否溢出
        textOverflow(){
            
        },
        // 监听用户点击修改订单
        edit(){
            console.log(this.measureDate  == '' , this.deliveryDate  == '')
           if(this.measureDate  == '' || this.deliveryDate  == '' || this.installDate == '' || this.measureDateEnd  == '' || this.deliveryDateEnd  == '' || this.installDateEnd == ''){
                // 信息不完整
                this.$message.error(this.$t('tips.plsComplateDate'))
           }else{
                // 处理信息
                let orderVo = {}
                let choiceData = this.choiceData
                    // 订单基础信息
                orderVo = {
                    // 地址
                    "deliveryAddress": this.address,
                    nameSpare: this.conPersonTwo.name,
                    phoneSpare: this.conPersonTwo.phone,
                    // 时间日期
                    // "deliveryEnd": formatDate(this.deliveryDateEnd).spriteDate,
                    // "deliveryStart": formatDate(this.deliveryDate).spriteDate,
                    // "installationEnd": formatDate(this.installDateEnd).spriteDate,
                    // "installationStart": formatDate(this.installDate).spriteDate,
                    "measurementEnd": formatDate(this.measureDateEnd).spriteDate,
                    "measurementStart": formatDate(this.measureDate).spriteDate,
                    "measurementStartSpare": formatDate(this.measurementStartSpare).spriteDate,
                    "measurementEndSpare": formatDate(this.measurementEndSpare).spriteDate,
                    //  订单id
                    "orderId": this.choiceData.orderId
                }
                console.log(JSON.stringify(orderVo))
                // 修改
                this.editOrder(orderVo)
           }
        },
        // 提交修改订单
        async editOrder(orderVo){
            let loadingInstance = Loading.service();
            let res = await this.$http.post("/web/order/edit",{
                ...orderVo
            })
            console.log(res,"下单结果")
            if(res.data.msg == "success" || res.data.msg == "修改成功！" || res.data.code == 0){
                loadingInstance.close();
                this.$message.success(this.$t('shop.orderDetails.editDone'))
                this.$router.replace("/member")
            }else{
                loadingInstance.close();
                this.$message.error(res.data.msg)
            }
        },
        // 监听用户点击付款(旧订单)
        async payO(){
            console.log(this.choiceData.orderId, 'orderID')
            let loadingInstance = Loading.service();
            let res = await this.$http.post("/web/order/pay?orderId=" + this.choiceData.orderId)
            console.log("下单结果", res)
            if(res.data.msg == "success"){
                loadingInstance.close();
                res.data.href += '?timestamp=' + Math.random() //兼容ios安全||超时 的处理
                if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
                    location.href = res.data.href //此处判断是否为ios后,使用location.href跳转
                }else{
                    window.open(res.data.href)
                }
            }else{
                loadingInstance.close();
                this.$message.error(res.data.msg)
            }
        },
        // 监听用户点击显示条款规则
        showClauseAlert(){
            this.isShowClauseAlert = true
        },
        // 获取条款细则页面数据
        async getclausePageData(){
            let res = await this.$http.get('/web/terms')
            this.clausePageData = res.data.tdTermsPage
        },
        // 监听用户点击确定添加联络人
        complateAddConPer(){
            this.$refs.conPersonTwoForm.validate(async valid => {
                if(!valid) return;
                this.hasConPersonTow = true
                this.showAddAlert = false
            })
        },
        // 监听用户点击同意条款
        handlerAgreeClause(){

            this.agreeClause = true
            this.isOO? this.payO() : this.pay()
        },
        // 显示条款
        showClause(){
            if(this.measureDate  == '' || this.measureDateEnd  == ''){
                // 信息不完整
                this.$message.error(this.$t("tips.plsComplateDate"))

                return
           }
           this.isShowClauseAlert = true
        }
    },
    computed: {
    },
    // 生命周期==>创建完成
    created(){
        this.getMemberInfo()
        this.getclausePageData()
        let date = JSON.parse(window.sessionStorage.getItem("date"))
        // 更新之前选过的日期时间
        if(date !== null){
            // 测量日期
            this.measureDate = date.measureDate 
            // 交付日期
            this.deliveryDate = date.deliveryDate
            // 安装日期
            this.installDate = date.installDate
        }

        let orderInfo = this.$route.params.orderInfo || JSON.parse(window.sessionStorage.getItem('memberOrderInfo'))

        if(orderInfo){
            this.isOO = true
            // 更新订单数据
            this.choiceData = {
                deliveryAddress: orderInfo.deliveryAddress,
                curProduct: {
                    productTypeCn: orderInfo.planNameCn,
                    productTypeEn: orderInfo.planNameEn,
                    roomTypeNameCn: orderInfo.productTypeCn,
                    roomTypeNameEn: orderInfo.productTypeEn,
                    servicePlanId: orderInfo.servicePlanId,
                },
                planDetailList: orderInfo.orderColors,
                totalCharges: orderInfo.totalCharges,
                paymentStatus: orderInfo.paymentStatus,
                orderId: orderInfo.orderId,
                orderStatus: orderInfo.orderStatus
            }
            // 更新日期时间

            this.measureDefaultTime = {
                    date: formatDate(orderInfo.measurementStart).spritDate,
                    time: formatDate(orderInfo.measurementStart).time,
                    endTime: formatDate(orderInfo.measurementEnd).time
            }
            this.measureSpareDefaultTime = {
                date: formatDate(orderInfo.measurementStartSpare).spritDate,
                time: formatDate(orderInfo.measurementStartSpare).time,
                endTime: formatDate(orderInfo.measurementEndSpare).time
            }
            if(orderInfo.deliveryStart){

                this.deliveryDefaultTime = {
                        date: formatDate(orderInfo.deliveryStart).spritDate,
                        time: formatDate(orderInfo.deliveryStart).time,
                        endTime: formatDate(orderInfo.deliveryEnd).time
                }
            }
            if(orderInfo.installationStart){

                this.installDefaultTime = {
                        date: formatDate(orderInfo.installationStart).spritDate,
                        time: formatDate(orderInfo.installationStart).time,
                        endTime: formatDate(orderInfo.installationEnd).time
                }
            }
                // 设置日期
            this.measureDate = orderInfo.measurementStart
            this.measureDateEnd = orderInfo.measurementEnd
            this.measurementStartSpare = orderInfo.measurementStartSpare
            this.measurementEndSpare = orderInfo.measurementEndSpare
            this.deliveryDate = orderInfo.deliveryStart
            this.deliveryDateEnd = orderInfo.deliveryEnd

            this.installDate = orderInfo.installationStart
            this.installDateEnd = orderInfo.installationEnd

            this.conPersonTwo.name = orderInfo.nameSpare
            this.conPersonTwo.phone = orderInfo.phoneSpare
            console.log(this.$route.params, '接受订单参数')
        }else{
             let date = formatDate(new Date()).spritDate
             let dateArr = date.split('/')
             dateArr[2] = (Number(dateArr[2] ) + 3) + ''
             let nDate = dateArr.join('/') // 加3天后的日期

             this.meaStartDateDisabel = new Date(nDate)
        }

    },
    beforeDestroy() {
        // 如果是从订单详情传过来的, 要清除保存在本地的订单数据
        if(this.isOO){
            window.sessionStorage.removeItem('memberOrderInfo')
        }
    },
}
</script>
    
<style lang="less">
    #order{
        width: 100%;
        height: 100%;
        background-color: #aaa;
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        .wrap{
            width: 1400px;
            height: 100%;
            .hd{
                /* margin-bottom: .625rem; */
                border-bottom: none;
                .title{
                    font-size: .6rem;
                }
            }
            .bd{
                height:540px;
                background: rgba(255, 255, 255, 0.05);
                border: 0.0125rem solid rgba(255, 232, 255, 0.14);
                border-radius: .325rem;
                padding: .625rem 0;
                .flex();
                .planInfo, .installInfo{
                    position: relative;
                    border-right: .0125rem solid rgba(232, 232, 255, 0.14);
                    padding: 0 .6rem;
                    width: 40%;
                    h2.title{
                        display: flex;
                        justify-content: flex-start;
                        text-align: left;
                        color: rgba(237, 130, 32, 1);
                        font-size: .3rem;
                        margin-bottom: .4375rem;
                        border-left: 5px solid @colorMain;
                        padding-left: 10px;
                        height: 24px;
                        line-height: 24px;
                    }
                }
                .installInfo{
                    display: flex;
                    align-items: space-between;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .labelt{
                        font-size: .25rem;
                        margin-right: 13PX;
                        white-space: nowrap;
                        color: #ed8220;
                        height: 20px;
                        line-height: 20px;
                        padding-left: 10px;
                        border-left: 0.0625rem solid #ED8220;
                    }
                    .row{
                        position: relative;
                        .flexlc();
                        padding: .125rem 0; 
                        text-align: left;
                        width: 97%;
                        height: fit-content;
                        .label{
                            font-size: .25rem;
                            margin-right: 13PX;
                            white-space: nowrap;
                        }
                        .value{
                                font-size: .25rem;
                                font-weight: 580;
                                text-align: right;
                            *{
                                display: block;
                                width: 100%;
                                font-size: .25rem;
                                font-weight: 580;
                                text-align: right;
                                margin-left: .0625rem;
                            }
                        }
                        .adsIpt{
                            box-sizing: content-box !important;
                            width: 100%;
                            height: 100%;
                            outline: none;
                            border: 1px solid rgba(232, 232, 255, 0.14);
                            border-radius: .0625rem;
                            padding: .0625rem;
                            font-size: .225rem;
                            background: none;
                            color: #fff;
                            box-sizing: border-box;
                            padding-right: 25px;
                            white-space:nowrap;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            text-align: left;
                            &:hover{
                                cursor: pointer;
                            }
                        }
                        .icon{
                            right: 10px;
                            position: absolute;
                            color: rgba(232, 232, 255, 5);
                            font-weight: 550;
                            .heffect();
                            font-size: 18px;
                        }
                        .el-icon-close {
                            font-size: 25px;
                        }
                        #edit{
                            position: absolute;
                            right: 0.125rem;
                            img{
                                .heffect();
                            }
                        }
                        #Date{
                            margin-bottom: -5px;
                            margin-left: -3px;
                            height: 100%;
                            width: 100%;
                        }
                        .details, .add{
                            position: absolute;
                            right: 0px;
                            .heffect;
                            display: flex;
                            align-items: center;
                            img{
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                    .row.two{
                        align-items: flex-start;
                        .right{
                            width: 90%;
                            #Date:first-child{
                                margin-bottom: 15px;
                            }
                        }
                    }
                    .row:last-child{
                        padding-bottom: 0px;
                    }
                    .adsRow{
                        padding-right: 50px;
                        .adsVo{
                            text-align: left;
                            overflow: hidden;
                            text-overflow:ellipsis;
                        }
                    }
                    .dateTip{
                        /* position: absolute;
                        bottom: -12px; */
                        color: @colorMain;
                        margin-top: 2px;
                    }
                }
                .planInfo{
                    width: 60%;
                    .hd{
                        .flexlc();
                        align-items: flex-start;
                        margin-bottom: 19px;
                        .title{
                            margin-left: 15px;
                            margin-bottom: 0;
                            margin-right: 25px;
                        }
                        .refresh{
                            .heffect();
                            margin-top: -6px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .table{
                        width: 100%;
                        .myTable{
                            color: #fff;
                            font-size: .225rem;
                            width: 100%;
                            border-collapse:separate;
                            border-spacing:0px;
                            line-height: .375rem;
                            .tr{
                                width: 100%;
                                display: flex;
                            }
                            .th{
                                /* .flexcb(); */
                                font-size: 19px;
                                font-weight: 500;
                                opacity: .8;
                                font-family: 'SourceHanSansCN-Medium';
                            }
                            .td,.th{
                                border-bottom: 0.0125rem solid rgba(232, 232, 255, 0.14);
                                margin: 0;
                                padding: 0;
                                width: 22%;
                                padding: .125rem 0;
                                text-align: center;
                                font-weight: 300;
                                color: #fff;
                                font-size: .25rem;
                                line-height: .625rem;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .th:last-child{
                                width: 34%;
                            }
                            .td:last-child{
                                position: relative;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 34%;
                                overflow: inherit;
                                .description{
                                    width: 85%;
                                    white-space:nowrap;
                                    overflow:hidden;
                                    text-overflow:ellipsis;
                                    text-align: center;
                                    font-size: 0.25rem;
                                    font-family: 'SourceHanSansCN-Light';
                                }
                                .iconfont{
                                    width: 10%;
                                    .heffect();
                                }
                                .textAleart{
                                    position: absolute;
                                    right: -90%;
                                    width: 200px;
                                    border: 0.0125rem solid rgba(232, 232, 255, 0.14);
                                    box-shadow: 2px 2px 2px rgba(232, 232, 255, 0.14);
                                    padding: 8px;
                                    text-indent: 10px;
                                    line-height: 25px;
                                    span{
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                    .b{
                        display: flex;
                        position: absolute;
                        bottom: .0625rem;
                        justify-content: space-between;
                        padding-right: .6rem;
                        width: 95%;
                        align-items: flex-end;
                        .price{
                            display: flex;
                            align-items: flex-end;
                            span{
                                font-size: .25rem;
                            }
                            .hdk{
                                display: flex;
                                align-items: flex-end;
                                color: @colorMain;
                                .total{
                                    margin-left: .25rem;
                                    font-size: .45rem;
                                }
                            }
                        }
                        .clause{
                            display: flex;
                            align-items: center;
                            position: relative;
                            .checkbox{
                                border-radius: 5px;
                                border: 1px solid #999;
                                .heffect;
                                padding: 6px;
                                margin-top: 2px;
                                position: absolute;
                                left: 0;
                                top: 0px;
                            }
                            img{
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                .heffect;
                                left: -8px;
                                top: -6px;
                            }
                            p{
                                margin-left: 20px;
                                span{
                                    display: inline-block;
                                    color: @colorMain;
                                    border-bottom: 1px solid @colorMain;
                                    .heffect;
                                }
                            }
                        }
                    }
                }
                .order{
                    .details{
                        .row{
                            .flexb();
                        }
                    }
                }
            }
            .bottom{
                .options{
                    .flexcc();
                    .prev,.pay{
                        margin: .625rem 125px;
                        .heffect();
                        p{
                            font-size: .25rem;
                        }
                    }
                    .prev{
                        color: #000;
                    }
                }
            }
        }
        .alret{
            .container{
                width: 591px;
                height: fit-content;
                padding: 50px 30px;
                background: rgba(255, 255, 255, 0.1);
                .tips{
                    margin-top: 0px;
                    font-size: 25px;
                }
                .bd{
                    width: 100%;
                    margin-top: 20px;
                        .el-form-item{
                            position: relative;
                            .flexlc();
                            padding: .125rem 0; 
                            text-align: left;
                            width: 95%;
                            height: fit-content;
                            justify-content: flex-end;
                        }
                        .el-form-item__label{
                            display: flex;
                            justify-content: flex-end;
                            font-size: .25rem;
                            margin-right: 13PX;
                            white-space: nowrap;
                            color: #fff;
                            width: 15%;
                            text-align: right;
                        }
                        *{
                            display: block;
                            font-size: .25rem;
                            font-weight: 580;
                            text-align: left;
                            margin-left: .0625rem;
                        }
                        .el-input__inner{
                            width: 83%;
                            background: none;
                            border: none;
                            border-bottom: 1px solid #ccc;
                            border-radius: 0;
                            color: #fff;
                        }
                        .adsIpt{
                            box-sizing: content-box !important;
                            width: 82%;
                            height: 100%;
                            outline: none;
                            border: 1px solid rgba(232, 232, 255, 0.14);
                            border-radius: .0625rem;
                            padding: .0625rem;
                            font-size: .225rem;
                            background: none;
                            color: #fff;
                            box-sizing: border-box;
                            padding-right: 25px;
                            white-space:nowrap;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            text-align: left;
                            &:hover{
                                cursor: pointer;
                            }
                        }
                        .icon{
                            right: 10px;
                            position: absolute;
                            color: rgba(232, 232, 255, 5);
                            font-weight: 550;
                            .heffect();
                            font-size: 18px;
                        }
                        .el-icon-close {
                            font-size: 25px;
                        }
                        #edit{
                            position: absolute;
                            right: 0.125rem;
                            img{
                                .heffect();
                            }
                        }
                    .adsRow{
                        width: 85%;
                        overflow: hidden;
                        padding-right: 50px;
                        .adsVo{
                            padding-left: 23px;
                            text-align: left;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .options{
                    margin-top: 30px;
                    padding-right: 0.3125rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                    white-space: nowrap;
                }
            }
        }
        .clause{
            .container{
                padding: 50px 35px;
                width: 50%;
                .box{
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 400px;
                    .b{
                        
                        height: 100%;
                        overflow-y: auto;
                        margin-top: 20px;
                        margin-right: -17px;
                        padding-right: 2px;
                        padding-bottom: 40px;
                        width: 100%;
                        ol{
                            text-indent: 20px;
                            font-size: 16px;
                            margin-bottom: 10px;
                        }
                        *{
                            font-size: 18px;
                            text-align: left;
                        }
                    }
                    .b::-webkit-scrollbar {
                        display: none;
                    }
                }
                .options{
                    justify-content: space-around;
                    .btn{
                        margin: 0;
                    }
                }
            }
        }
    }

    .el-picker-panel{
        .el-date-picker__time-header{

        }
        .el-picker-panel__body{

        }
        .el-picker-panel__footer{
            .el-button.el-picker-panel__link-btn.el-button--text.el-button--mini{
                display: none;
           }
           .el-button.el-picker-panel__link-btn.el-button--default.el-button--mini.is-plain{
                width: 1.4125rem;
                height: .5rem;
                background: #ED8220;
                border: .0125rem solid #ED8220;
                border-radius: .125rem;
                color: #fff;
           }
        }
    }
    .el-time-spinner__item{
        text-align: center;
    }
</style>
    