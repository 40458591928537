<template>
    <div id="Sample">
        <div class="top-mask" :class="showSelect?'active':'passive'"></div>
        <div class="bottom-mask" :class="showSelect?'active':'passive'"></div>
        <el-carousel indicator-position="none" :autoplay="false" @change="swiperChange" ref="mySwiper" :initial-index="curSwiperIdx">
            <el-carousel-item v-for="(item,index) in swiperList" :key="item">
                <div  v-if="checkIsMp4(item) === false" class="img">
                    <img :src="`${baseUrl + item}`" alt="">
                </div>
                <div v-if="checkIsMp4(item) === true">
                    <video :id="'video' + index" class="video" :src="`${baseUrl + item}`" width="100%" height="100%" @pause="videoPause" @play="videoPlay" @ended="videoEnded">
                    </video>
                    <div class="options" :class=" !pageLazy ? 'active':'passive'">
                        <img src="../../../assets/imgs/play.png" alt="play" @click="playVideo(index)" v-if="videoStatus == 'paused'">
                        <img src="../../../assets/imgs/pause.png" alt="pause" @click="pauseVideo(index)" v-else-if="videoStatus == 'playing'">
                        <img src="../../../assets/imgs/replay.png" alt="replay"  @click="replayVideo(index)" v-else-if="videoStatus == 'ended'">
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="select" :class=" showSelect ? 'active':'passive'">
            <div class="floorName">
                <div class="label">{{$t('window.floorLabel')}}：</div>
                <el-dropdown trigger="click">
                    <el-button type="primary">
                     <span> {{curFloor[_i18n.locale == 'en' ? 'locationNameEn' : 'locationNameCn']}}</span><i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item  v-for="item in sampleList" :key="item.locationNameCn"><span @click="handlerSelectFloor(item)">{{item[_i18n.locale == 'en' ? 'locationNameEn' : 'locationNameCn']}}</span></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="room">
                <div class="label">{{$t('window.roomLabel')}}：</div>
                <el-dropdown trigger="click">
                    <el-button type="primary">
                      {{curRoom[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(item,index) in curFloor.webRoomTypes" :key="item.roomTypeNameCn"> <span @click="handlerSelectRoom(item)">{{item[_i18n.locale == 'en' ? 'roomTypeNameEn' : 'roomTypeNameCn']}}</span> </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="complate" @click="complate">
                <p>{{$t('window.btn')}} <span v-if="!isMobile">></span></p>
            </div>
        </div>
        <!-- 移动端控制轮播图栏 -->
        <div class="mobile-swiper-options" v-if="isMobile && $refs.mySwiper">
            <img src="../../../assets/imgs/icon/mobile-arrow-left.png" alt="" @click="mobileSwitch('prev')">
            <div class="count">
                <span class="numL">{{curSwiperIdx + 1}}</span> / <span class="numL">{{swiperList.length}}</span>
            </div>
            <img src="../../../assets/imgs/icon/mobile-arrow-right.png" alt="" @click="mobileSwitch('next')">
        </div>
        <!-- 展开箭头 -->
        <div class="arrowUp" :class=" !showSelect ? 'active':'passive'">
            <img src="../../../assets/imgs/arrowUp.png" alt="arrowUp" @click="handlerShowSelect">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Sample',
        data(){
            return {
                // 样品数据
                sampleList: [],
                // 轮播列表
                swiperList: [],
                // 当前楼盘
                curFloor: {},
                // 当前房间
                curRoom: {},

                // 视频状态
                videoStatus: 'paused',
                // 页面状态
                pageLazy: false,
                // 当前播放视频的标识
                curVideoIndex: undefined,
                // 下拉框是否展示
                showSelect: true,
                // 当前内容轮播图下标
                curSwiperIdx: 0,
            }
        },
        methods: {
            // 获取样品数据
           async getSampleData(){
                const res = await this.$http.get("/web/show/cast")
                console.log(res,"橱窗页面数据")
                this.sampleList = res.data

                this.curFloor = this.sampleList[0]
                this.curRoom = this.curFloor.webRoomTypes[0]
                // 创建轮播图列表
                this.actionsSwiper(this.curRoom.webShowCastUrls)
            },
            // 加工出轮播图数据
            actionsSwiper(dataList){
                this.swiperList = []
                let imgArr = []
                let videoArr = []
                dataList.forEach(item => {
                    for(let key in item){
                        if(!item[key]) return
                        if(this.checkIsMp4(item[key])){
                            videoArr.push(item[key])
                        }else{
                            imgArr.push(item[key])
                        }
                    }
                });
                this.swiperList = [...imgArr, ...videoArr]
            },
            // 校验路径是否为视频路径
            checkIsMp4(path){
                if(!path){
                    return null
                }
                return path.slice(path.length-3,path.length) == 'mp4' ? true : false
            },
            // 监听用户选择楼
            handlerSelectFloor(floor){
                console.log(floor)
                this.curFloor = floor
                this.curRoom = floor.webRoomTypes[0]
            },
            // 监听用户选择房间
            handlerSelectRoom(room){
                console.log(room)
                this.curRoom = room
            },
            // 监听视频暂停
            videoPause(){
                console.log("暂停")
            },
            // 视频播放
            videoPlay(){
                JSON.stringify()
                console.log("播放")
            },
            // 监听视频暂停
            videoPause(){
                console.log("暂停")
            },
            // 用户点击确定
            complate(){
                this.actionsSwiper(this.curRoom.webShowCastUrls)
            },
            // 播放视频
            playVideo(index){
                this.videoStatus = 'playing'
                this.curVideoIndex = index
                document.getElementById("video" + index).play()
                this.showSelect = false
            },
            // 暂停视频
            pauseVideo(index){
                console.log(index, '该暂停视频了')
                this.videoStatus = 'paused'
                this.curVideoIndex = index
                document.getElementById("video" + index).pause()
                this.showSelect = true
            },
            // 重播视频
            replayVideo(index){
                this.curVideoIndex = index
                document.getElementById("video" + index).load()
                document.getElementById("video" + index).play()
                this.videoStatus = 'playing'
            },
            // 监听视频播放完毕
            videoEnded(){
                console.log('播放完毕')
                this.videoStatus = 'ended'
            },
            // 监听轮播图切换
            swiperChange(idx){
                this.curSwiperIdx = idx
                 
               if(typeof this.curVideoIndex == 'number' ){
                    this.pauseVideo(this.curVideoIndex)
               }
            },
            // 监听用户点击显示下拉框
            handlerShowSelect(){
                this.showSelect = true
            },
            // 监听鼠标滑动改变页面展示状态
            handlerMouseMove(){
                let valve = false
                let tiemr = undefined
                window.addEventListener('mousemove',(e) => {
                    if(valve) return

                    valve = true
                    this.pageLazy = false
                    clearTimeout(tiemr)
                    setTimeout(() => {
                        this.pageLazy = false
                        valve = false
                        tiemr = setTimeout(()=>{
                            this.pageLazy = true
                            if(this.showSelect == true){
                                this.showSelect = false
                            }
                        },1000)
                    },1000)
                })
            },
             // 移动端swiper切换
            mobileSwitch(action){
                this.$refs.mySwiper[action]()
            }
        },
        // 生命周期==》创建完成
        created() {
            this.getSampleData()
            
            !this.isMobile && this.handlerMouseMove()
        },
        // 生命周期==>挂载完成
        mounted() {

        },
    }
</script>

<style lang="less">
    #Sample{
        position: absolute;
        width: 100%;
        height: 100%;
        color: #fff;
        overflow: hidden;
        &>.el-carousel{
            width: 100%;
            height: 100%;
            .el-carousel__arrow{
                width: .775rem;
                height: .775rem;
                i{
                    font-size: .3125rem;
                }
                &:hover{
                    color: @colorMain;
                }
            }
            .el-carousel__container{
                width: 100%;
                height: 100%;
                .img{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .video{
                    position: fixed;
                    right: 0;
                    bottom: 0;
                    min-width: 100%;
                    min-height: 100%;
                    height: auto;
                    width: auto;
                }
                .options{
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    img{
                        width: 1.25rem;
                        height: 1.25rem;
                        .heffect();
                    }
                }
                .options.active{
                    animation: show .6s forwards;
                }
                .options.passive{
                    animation: close .6s forwards;
                }
            }
        }
        .top-mask,.bottom-mask{
            position: absolute;
            width: 100%;
            z-index: 20;
        }
        .top-mask{
            top: 0;
            height: 2.5rem;
            background:linear-gradient(rgba(0,0,0,1),rgba(0,0,0,0));
        }
        .bottom-mask{
            bottom: 0;
            height: 5.325rem;   
            background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
        }
        .top-mask.active,.bottom-mask.active{
            animation: show .6s forwards;
        }
        .top-mask.passive,.bottom-mask.passive{
            animation: close .6s forwards;
        }
        .select{
            position: absolute;
            width: 100%;
            bottom: 1.25rem;
            z-index: 30;
            .flexcc();
            .floorName,.room{
                .flexcc();
                .label{
                    margin-right: .125rem;
                    font-size: .3rem;
                    font-weight: 500;
                }
                .el-dropdown{
                    width: 320px;
                    height: 70px;
                    .el-dropdown-menu{
                        width: 100%;
                    }
                    .el-button{
                        background: rgba(232, 232, 232, 0.3);
                        border: .0125rem solid rgba(232, 232, 255, 0.14);
                        border-radius: .1875rem;
                        width: 100%;
                        height: 100%;
                        span{
                            width: 100%;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: rgba(237, 130, 32, 1);
                            font-size: .25rem;
                            .el-icon--right{
                                color: #fff;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
            .room{
                margin: 0 .625rem;
            }
            .complate{
                width: 2rem;
                height: .875rem;
                line-height: .875rem;
                background: rgba(237, 130, 32, 0.6);
                border-radius: .25rem;
                .heffect();
                p{
                    font-size: .25rem;
                }
            }
        }
        .select.active{
            animation: join .6s forwards;
        }
        .select.passive{
            animation: remove .6s forwards;
        }
        .arrowUp{
            position: fixed;
            left: 47.5%;
            transform: translateX(-50%);
            bottom: .375rem;
            z-index: 100;
            img{
                .heffect();
            }
        }
        .arrowUp.active{
            animation: join .6s forwards;
        }
        .arrowUp.passive{
            animation: remove .6s forwards;
        }
    }
    .el-scrollbar{
        .el-select-dropdown__item{
            span{
                font-size: .25rem;
            }
        }
        .el-select-dropdown__item.selected{
            span{
                color: @colorMain;
            }
        }
    }
    #Foot{
        z-index: 99;
    }
    .el-dropdown-menu{
        .el-dropdown-menu__item{
            span{
                width: 100%;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
        }
    }
    @keyframes join{
        0%{
            opacity: 0;
            transform: translateY(300px);
        }
        100%{
            opacity: 1;
            transform: translateY(0px);
        }
    }
    @keyframes remove{
        0%{
            opacity: 1;
            transform: translateY(0px);
        }
        100%{
            opacity: 0;
            transform: translateY(300px);
            display: none;
        }
    }
</style>

<style scoped>
    .el-dropdown-menu{
        width: 320px;
    }
</style>