<template>
    <div id="Product">
        <el-carousel indicator-position="none" :autoplay="false" @change="swiperChange" :class="showDetails ? 'vague':''" ref="mySwiper" :initial-index="curSwiperIdx">
            <el-carousel-item v-for="(item,index) in swiperList" :key="index">
                <div class="top-mask" :class="showSelect?'active':'passive'"></div>
                <div class="bottom-mask" :class="showSelect?'active':'passive'"></div>
                <div  v-if="typeof item == 'object'" class="details mobile-max-height mobileH600"  :class=" showSelect ?'blur':''">
                    <div class="img" v-if="item.productDescripImg != null">
                        <img :src="`${baseUrl + item.productDescripImg}`" alt="">
                    </div>
                    <div class="introduce">
                        <div class="title" v-html="item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']"></div>
                        <div class="text" v-html="item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
                        <img class="arrow" src="../../assets/imgs/arrow.png"  @click="showDetailsBlock(item)"></nuxt-link>
                    </div>
                    <div class="know" @click="showDetailsBlock(item)">
                        <span>{{$t('product.product.detailsBtn')}}</span>
                    </div>
                  </div>
                <div v-if="checkIsMp4(item)">
                    <video :id="'video' + index" class="video" :src="`${baseUrl + item}`" width="100%" height="100%" @ended="videoEnded" v-show="curSwiperIdx == index">
                    </video>

                    <div class="options" :class=" !pageLazy ? 'active':'passive'">
                        <img src="../../assets/imgs/play.png" alt="play" @click="playVideo(index)" v-if="videoStatus == 'paused'">
                        <img src="../../assets/imgs/pause.png" alt="pause" @click="pauseVideo(index)" v-else-if="videoStatus == 'playing'">
                        <img src="../../assets/imgs/replay.png" alt="replay"  @click="replayVideo(index)" v-else-if="videoStatus == 'ended'">
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="selection" :class=" showSelect && !showDetails? 'active':'passive'">
            <div class="wrap">
                <swiper :options="swiperOption" ref="productSwiper">
                    　<swiper-slide v-for="(item, index) in productSwiperList" :key="index" :class="curProductIndex == index ?'is-active':''">
                        <img :src="`${baseUrl + item.productDisplayImg}`" alt="" @click.self="choiceProcut(item,index)">
                        <div class="label">
                            <span>{{item[_i18n.locale == 'en' ? 'productTypeEn' : 'productTypeCn']}}</span>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper-button-prev"  @click="productSwiper.slidePrev() "></div>
            <div class="swiper-button-next"  @click="productSwiper.slideNext() "></div>
        </div>

        <!-- 展开箭头 -->
        <div class="arrowUp" :class=" !showSelect && !showDetails? 'active':'passive'">
            <img src="../../assets/imgs/arrowUp.png" alt="arrowUp" @click="handlerShowSelect">
        </div>
        
        <!-- 移动端控制轮播图栏 -->
        <div class="mobile-swiper-options" v-if="isMobile && $refs.mySwiper">
            <img src="../../assets/imgs/icon/mobile-arrow-left.png" alt="" @click="mobileSwitch('prev')">
            <div class="count">
                <span class="numL">{{curSwiperIdx + 1}}</span> / <span class="numL">{{swiperList.length}}</span>
            </div>
            <img src="../../assets/imgs/icon/mobile-arrow-right.png" alt="" @click="mobileSwitch('next')">
        </div>

        <!-- 详情内容 -->
        <div class="details" :class="showDetails?'active':'passive'">
            <div class="content pubw">
                <div class="back">
                    <span @click="showDetails = false"> <span v-if="!isMobile"><</span> {{$t('product.product.backBtn')}}</span>
                </div>
                <div class="title" v-html="details[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']" @click=""></div>
                <div class="description" v-html="details[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
            </div>
        </div>
        <Foot/>
    </div>
</template>

<script>
        import Foot from '@/components/Foot'
export default {
    name: 'Product',
    components: {
            Foot
    },
    data(){
        return {
            // 页面数据
            pageData: [],
            // 当前产品
            curProduct: {},
            // 轮播列表
            swiperList: [],
            // 视频状态
            videoStatus: 'paused',
            // 页面状态
            pageLazy: false,
            // 当前播放视频的标识
            curVideoIndex: undefined,
            // 产品轮播图列表
            productSwiperList: [],
            // 当前产品下标
            curProductIndex: 0,
            // 下拉框是否展示
            showSelect: true,
            // 详情数据
            details: '',
            // 是否展示详情块
            showDetails: false,
            // 产品轮播图配置
            swiperOption: {
                slidesPerView: 'auto',
                centeredSlides: false,
                slidesOffsetBefore: 0,
                freeMode : this.isMobile ? true : false,//是否滑动
                paginationClickable: true,
                spaceBetween: this.isMobile ? 15 : 50,//间距
                observer:true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true, //修改swiper的父元素时，自动初始化swiper
            },
            // 当前内容轮播图下标
            curSwiperIdx: 0,
        }
    },
    methods: {
        // 监听鼠标滑动改变页面展示状态
        handlerMouseMove(){
            let valve = false
            let tiemr = undefined
            window.addEventListener('mousemove',(e) => {
                if(valve) return
    
                valve = true
                this.pageLazy = false
                clearTimeout(tiemr)
                setTimeout(() => {
                    this.pageLazy = false
                    valve = false
                    tiemr = setTimeout(()=>{
                        this.pageLazy = true
                        // if(this.showSelect == true && this. videoStatus == 'playing'){
                        //     this.showSelect = false
                        // }
                    },1000)
                },1000)
            })
        },
        // 获取页面数据
        async getPageData(){
            const res = await this.$http.get("/web/product/display")
            console.log("产品页面数据",res)
            this.curProduct = res.data[0]
            this.pageData = res.data
            // 创建产品轮播图
            this.productSwiperList =  res.data
            if(!this.$route.params.product){
                this.actionsSwiper([this.pageData [0]])
            }
        },
        // 加工出轮播图数据
        actionsSwiper(dataList){
            console.log(dataList)
            this.swiperList = []
            let imgArr = []
            let videoArr = []
            dataList.forEach(item => {
                if(typeof item.productDisplayVideo == 'string'){
                    videoArr.push(item.productDisplayVideo)
                }
                item.webProductDescrips.forEach(subItem => {
                    imgArr.push(subItem)
                })
            });
            this.swiperList = [...imgArr, ...videoArr]
        },
        // 校验路径是否为视频路径
        checkIsMp4(path){
            if(typeof path != 'string') return
            return path.slice(path.length-3,path.length) == 'mp4' ? true : false
        },
        // 监听轮播图切换
        swiperChange(idx){
            this.curSwiperIdx = idx
            if(typeof this.curVideoIndex == 'number' ){
                this.pauseVideo(this.curVideoIndex)
            }
            if(this.showSelect){
                this.showSelect = false
            }
        },
        // 播放视频
        playVideo(index){
            this.videoStatus = 'playing'
            this.curVideoIndex = index
            document.getElementById("video" + index).play()
            this.showSelect = false
        },
        // 暂停视频
        pauseVideo(index){
            this.videoStatus = 'paused'
            this.curVideoIndex = index
            document.getElementById("video" + index).pause()
            this.showSelect = true
        },
        // 监听视频播放完毕
        videoEnded(){
          console.log('播放完毕')
          this.videoStatus = 'ended'
          this.showSelect = true
        },
        // 重播视频
        replayVideo(index){
            this.curVideoIndex = index
            document.getElementById("video" + index).load()
            document.getElementById("video" + index).play()
            this.videoStatus = 'playing'
        },
        // 监听用户选择产品 
        choiceProcut(product,index){
            if(!product.productDisplayVideo && !product.webProductDescrips.length){
                return this.$message.info(this.$t("tips.notFound"))
            }
            console.log(product)
            this.actionsSwiper([product])
            this.curProductIndex = index
             this.videoStatus = 'paused'
        },
        // 监听用户点击详情页面
        showDetailsBlock(item){
            this.details = item
            this.showDetails = true
        },
        // 监听用户点击显示下拉框
        handlerShowSelect(){
                this.showSelect = true
            },
        // 创建产品分组轮播图数据列表
        createSwieprListByProduct(dataList,step){
            let resList = []
            const groupN = Math.ceil(dataList.length / step)
            let index = 0
            for(let i = 0;i < groupN;i++){
                let arr = []
                for(let k = 0;index < dataList.length && k < step;k++){
                    arr.push(dataList[index])
                    index++
                }
                resList.push(arr)
            }
            return resList
        },
        // 切换产品轮播图
        switchProductSwiep(type){
            switch (type) {
                case 'prev':
                    break;
                case 'next':
                    this.$refs.productSwiper.setActiveItem(this.productSwiperIndex++)
                    break;
            }
        },
        // 移动端swiper切换
        mobileSwitch(action){
            this.$refs.mySwiper[action]()
        }
    },
    // 计算属性
    computed: {
        productSwiper() {
            return this.$refs.productSwiper.$swiper
        }
    },
    // 生命周期==>创建完成
    created() {
        this.getPageData()
        this.handlerMouseMove()
    },
    // 生命周期==>挂载完成
    mounted() {
        if(this.$route.params.product){
            this.actionsSwiper([this.$route.params.product])
            this.productSwiper.slideTo(this.$route.params.index)
            this.curProductIndex = this.$route.params.index
        }else{
            this.productSwiper.slideTo(0)
            this.curProductIndex = 0
        }
    },
}
</script>

<style lang="less">
    #Product{
        position: absolute;
        width: 100%;
        height: 100%;
        color: #fff;
        overflow: hidden;
        .top-mask,.bottom-mask{
            position: absolute;
            width: 100%;
            z-index: 20;
        }
        .top-mask{
            top: 0;
            height: 130px;
            background:linear-gradient(rgba(50,50,50,.8),rgba(0,0,0,0));
        }
        .bottom-mask{
            bottom: 0;
            height: 300px;   
            background:linear-gradient(rgba(50,50,50,0),rgba(0,0,0,.8));
        }
        .top-mask.active,.bottom-mask.active{
            animation: show .6s forwards;
        }
        .top-mask.passive,.bottom-mask.passive{
            animation: close .6s forwards;
        }
        &>.el-carousel{
            width: 100%;
            height: 100%;
            .el-carousel__arrow{
                width: .775rem;
                height: .775rem;
                i{
                    font-size: .3125rem;
                }
                &:hover{
                    color: @colorMain;
                }
            }
            .el-carousel__container{
                width: 100%;
                height: 100%;
                .details{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    .img{
                        width: 100%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .introduce{
                        position: absolute;
                        top: 20%;
                        left: 12%;
                        width: 697px;
                        height: fit-content;
                        max-height: 32%;
                        text-overflow: ellipsis;
                        background: rgba(0,0,0,.2);
                        color: #fff;
                        padding: 30px;
                        overflow: hidden; 
                        .title{
                            position: relative;
                            height: 38px;
                            line-height: .85rem;
                            margin-bottom: 20px;
                            font-size: 40px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 38px;
                            text-align: left;
                            margin-bottom: 40px;
                            &::before{
                                content: '';
                                display: block;
                                height: 38px;
                                width: .0625rem;
                                left: -14px;
                                position: absolute;
                                background-color: @colorMain;
                                border-radius: 6px;
                                width: 6px;
                            }
                        }
                        .text{
                            overflow: hidden;
                            text-overflow:ellipsis;
                            text-align: left;
                            height: 68%;
                            line-height: 35px;
                            * /deep/{
                                overflow: hidden !important;
                                font-size: 24px !important;
                                font-family: Source Han Sans CN !important;
                                font-weight: 500 !important;
                                color: #FFFFFF !important;
                                text-align: left !important; 
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                display: -moz-box;
                                -moz-line-clamp: 2;
                                -moz-box-orient: vertical;
                                word-wrap: break-word;
                                word-break: break-all;
                                white-space: normal;   
                            }
                        }
                    }
                    .know{
                        position: absolute;
                        top: 60%;
                        left: 12%;
                        width: 2rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        height: 0.875rem;
                        background: rgba(237, 130, 32, 0.6);
                        border-radius: 0.25rem;
                        line-height: 0.875rem;
                        text-align: center;
                        .heffect();
                        font-family: 'SourceHanSansCN-Medium';
                        span{
                            font-size: 20px;
                        }
                    }
                }
                .details.blur{
                    filter: brightness(0.8);
                }
                .video{
                    position: fixed;
                    right: 0;
                    bottom: 0;
                    min-width: 100%;
                    min-height: 100%;
                    height: auto;
                    width: auto;
                }
                .options{
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    img{
                        width: 1.25rem;
                        height: 1.25rem;
                        .heffect();
                    }
                }
                .options.active{
                    animation: show .6s forwards;
                }
                .options.passive{
                    animation: close .6s forwards;
                }
            }
        }
        &>.el-carousel.vague{
            filter: blur(.25rem);
        }
        .selection{
            position: fixed;
            left: 16%;
            width: 1300px;
            transform: translateX(-52%);
            bottom: 50px;
            height: 3rem;
            z-index: 90;
            .wrap{
                overflow: hidden;
                &>.swiper-container{
                width: 100%;
                height: 2rem;
                background: #FFFFFF;
                background-color: rgba(255, 255, 255, 0.15);
                border-radius: 20px;
                overflow: initial;
                .swiper-wrapper{
                    padding-top: 10px;
                    width: 100%;
                    height: 100%;
                    .swiper-slide{
                        .flex();
                        width: 100%;
                        height: 138px;
                        position: relative;
                        width: 1.725rem;
                        margin: 0 70px;
                        img{
                            border-radius: .25rem;
                            .heffect();
                            width: 100%;
                            height: 100%;
                        }
                        .label{
                            position: absolute;
                            bottom: -0.875rem;
                            width: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            text-align: center;
                            width: fit-content;
                            span{
                                font-size: .3rem;
                                color: #fff;
                                white-space: nowrap;
                                font-family: 'SourceHanSansCN-Light';
                            }
                        }
                    }
                    .is-active{
                        border: 5px solid @colorMain;
                        border-radius: 20px;
                        .label{
                            font-family: 'SourceHanSansCN-Medium';
                        }
                    }
                }
            }
            }
            .swiper-button-next,.swiper-button-prev{
                .heffect();
                transform: translateY(-70%);
            }
            .swiper-button-next{
                width: 50px !important;
                height: 50px !important;
                background-size: 50px 50px !important;
                right: -6%;
            }
            .swiper-button-prev{
                width: 50px !important;
                height: 50px !important;
                background-size: 50px 50px !important;
                left: -6%;
            }
            .el-carousel--horizontal{
                overflow-x: initial !important;
            }
        }
        .selection.active{
            animation: join .6s forwards;
        }
        .selection.passive{
            animation: remove .6s forwards;
        }
        .arrowUp{
            position: fixed;
            left: 47.5%;
            transform: translateX(-50%);
            bottom: .375rem;
            z-index: 100;
            img{
                .heffect();
            }
        }
        .arrowUp.active{
            animation: join .6s forwards;
        }
        .arrowUp.passive{
            animation: remove .6s forwards;
        }
        .details{
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,.3);
            .content{
                margin-top: 100px;
                height: 100%;
                .back{
                    margin-bottom: 40px;
                    span{
                        font-size: 20px;
                        font-family: 'SourceHanSansCN-Medium';
                        font-weight: 500;
                        .heffect();
                    }
                }
                .title{
                    text-align: center;
                    color: #fff;
                    font-size: 40px;
                    font-family: 'SourceHanSansCN-Medium';
                    font-weight: 500;
                    color: #FFFFFF;
                }
                .description{
                    margin-top: 20px;
                    font-size: 24px;
                    font-family: 'SourceHanSansCN-Light';
                    font-weight: 300;
                    color: #FFFFFF;
                    height: 50%;
                    overflow: auto;
                    *{
                        text-align: left !important;
                        font-size: 24px !important;
                        font-family: 'SourceHanSansCN-Light' !important;
                        font-weight: 300 !important;
                        color: #FFFFFF !important;
                    }
                }
                .description::-webkit-scrollbar {display:none}
            }
        }
        .details.active{
            animation: show .6s forwards;
        }
        .details.passive{
            animation: close .6s forwards;
        }
    }
    #Foot{
        z-index: 99;
    }
    @keyframes join{
        0%{
            opacity: 0;
            transform: translateY(300px);
        }
        100%{
            opacity: 1;
            transform: translateY(0px);
        }
    }
    @keyframes remove{
        0%{
            opacity: 1;
            transform: translateY(0px);
        }
        100%{
            opacity: 0;
            transform: translateY(300px);
            display: none;
        }
    }
</style>