import axios from 'axios'
import Vue from "vue"
import router from "../router"
import {Message} from "element-ui"


// 创建实例

console.log(Vue.baseUrl, 'vue')
const http = axios.create({
  baseURL: 'https://hd-eshop.com.hk/hunter-douglas-api',
})



// 配置请求头
http.interceptors.request.use(config =>{
  config.headers.token = window.sessionStorage.getItem('token')
  config.headers.lang = window.localStorage.getItem('language')
  return config
})

// 响应拦截器
http.interceptors.response.use(req=>{
  
  if(req.data.notoken){
    // token过期 要求重新登录
    console.log('token失效')
    Message.info(req.data.message)
    router.push("/Login")
  }
  return req
});

console.log(Vue.$http)
// 创建全局http请求对象
Vue.prototype.$http = http


