<template>
    <div class="edit forgotPsw" :style="`background-image: url( ${bgImg} )`">
      <div class="content">
        <h1 class="title">{{$t('forgetPsd.title')}}</h1>
        <el-form :model="form" ref="loginForm" class="login-form" :rules="formRule">
          <el-form-item prop="email" :label='$t("forgetPsd.email") + ":"'>
              <el-input :placeholder="$t('forgetPsd.emailPls')"  v-model="form.email" ></el-input>
          </el-form-item>
          <el-form-item class="password" prop="password"  label='新密碼:'>
            <el-input type="password" v-model="form.password" placeholder="密碼"
            ></el-input>
          </el-form-item>
          <el-form-item class="actions">
            <el-button class="register" @click="()=>{$router.go(-1)}">{{$t('forgetPsd.prve')}}</el-button>
            <el-button class="edit" @click="edit">{{$t('forgetPsd.edit')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
    // 引入loding组件
import { Loading } from 'element-ui';
  export default {
    name: "Login",
    data() {

        // 验证邮箱正则
        let checkEmail = (rule,value,callback)=>{
            // const regEmail=/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
            // 自定义正则
            const regEmail = /^[A-Za-z0-9]+@+[A-Za-z0-9*]+[/.A-Za-z0-9]*$/
            if(!regEmail.test(value)) return callback(new Error(this.$t('login.emailErr')))
            return callback()
        }
      return {
        // 背景图片
        bgImg: process.env.BASE_URL + "bgImg/member.png",
        // 表单信息
        form: {
            email: '',
            password: '',
        },
        // 表单规则
        formRule: {
          email: [
            {
              required: true, message: this.$t('login.emailErr')
            },
            {
                    required: true,
                    validator: checkEmail,
                    trigger: "blur",
                    },
          ],
          password: [
            {
                required: true, message: this.$t('login.psdErr')
            }
          ]
        }
      }
    },
    methods: {
        // 修改密码
        edit(){
          this.$refs.loginForm.validate(async valid => {
            if(!valid) return 
            let loadingInstance = Loading.service();
              const res = await this.$http.post('/web/member/forget/password',null,{
                params: {...this.form}
              })
  
              console.log(res, '修改密码结果')
              if(res.data.msg != 'success'){
                loadingInstance.close();
                return this.$message.info(res.data.msg)
              }
              loadingInstance.close();
              this.$message.success(this.$t("tips.vifitySus"))
              this.$router.go(-1)
          })
        }
    },
    mounted() {
        document.onkeydown = (e) => {
        if(e.code=='Enter'){
          this.edit()
        }
      }
    },
  }
  </script>
  
  <style lang="less">
  .edit {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    .content {
      width: 530px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      .title {
        text-align: center;
        font-size: 48px;
        color: white;
        margin-bottom: 80px;
      }
  
  
      .el-select {
        width: 100%;
      }
  
      .actions {
        padding-top: 50px;
  
        .el-button {
          height: 70px;
          border-radius: 20px;
          font-size: 20px;
          border: 0;
          text-align: center;
          font-family: 'SourceHanSansCN-Medium';
          &.register {
            color: #333333;
            .heffect();
            background-color: rgba(255, 255, 255, 0.6);
            *{
              font-family: 'SourceHanSansCN-Medium' !important; 
            }
          }
          span{
            font-size: .25rem;
          }
          &.edit {
            background: rgba(237, 130, 32, 0.6);
            color: #fff;
            .heffect();
            margin-left: 80px;
           *{
            font-family: 'SourceHanSansCN-Medium' !important;
           }
          }
        }
      }
  
    }
  }
  
  </style>
  <style lang="less">
    .el-input__icon{
        height: auto !important;
    }
  .edit  {
    .el-input__inner {
      border: 0;
      border-bottom: 1px solid rgba(255,255,255,.3);
      border-radius: 0;
      font-size: .3rem;
      padding-left: .625rem;
      margin-bottom: .375rem;
      color: #fff;
      background: none !important;
    }
    .el-input__prefix {
      font-size: .325rem;
    }
    .el-input__icon,.el-icon-user{
        font-size: .25rem;
    }
    .el-input__suffix {
      height: auto;
    }
    .actions .el-form-item__content {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    .el-form-item,.el-form-item__content{
      position: relative;
      height: 60px;
      .el-input{
        height: 100%;
        .el-input__inner{
          height: 100%;
          margin-bottom: 0;
          font-size: 24px;
          padding: 0 20px;
        }
      }
    }
    .el-form-item__label{
          position: absolute;
          top: 12px;
          left: -90px;
          color: #fff;
          font-size: 24px;
          text-align: right;
          width: 100px;
          font-family: 'SourceHanSansCN-Light';
          white-space: nowrap;
        }
  }
  input:-internal-autofill-previewed,
  input:-internal-autofill-selected {
    -webkit-text-fill-color: #FFFFFF !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
  .forgetPsd{
    text-align: right;
    span{
      color: #fff;
      font-size: 18px;
      font-family: 'SourceHanSansCN-Light';
      .heffect();
    }
  }
  </style>
  