// 英文文件


module.exports = {
    // 主页
    home: {

    },
    // 头部
    header: {
        navList: {
            shop: 'Online store',
            home: 'Main Page',
            product: 'Product',
            window: 'Showcase',
            member: 'Member Centre',
            contactus: 'Contact Us'
        },
        navBlock: {
            freeMeasure: 'Free Measurement',
            productP: 'Product Features',
            intellect: 'Smart Curtain',
            news:'Latest News',
            freeConsultion: 'Free Consultation'
        }
    },
   // 商城
   shop:{
    coupon: {
        label: 'Exclusive Code',
        success: 'Successful inquiry!',
        error: 'Search successful'
    },
    choice: {
        title: "Online selection",
        tips: 'The picture is for reference only. The picture may have color difference due to different screen settings. The actual product color shall prevail.',
        options: {
            plan: 'Package plan',
            curenv: 'Blinds location',
            color: 'Color selection'
        },
        tabel: {
            location: 'Installation location',
            type: 'Product type',
            color: 'Product color',
            si: 'Product specifications'
        },
        palnNodata: 'There is no VR picture for this product',
        planErr: 'No data for this service plan',
        plsLogin: 'Please login first',
        noVr: 'Not applicable for the time being',
        addOrder: 'Confirm order'
    },
    orderDetails: {
        title: 'Order details',
        install: {
            title: 'Customer Information',
            name: 'Customer Name',
            phone: 'Contact number',
            email: 'E-mail',
            address: 'Installation address',
            measureDate: 'Date of measurement',
            installDate: 'Installation date',
            measureDateSP: 'Spare measurement date',
            deyDate: 'Delivery date',
            plsInstallD: 'Please select installation date and time first',
            plsDeyD: 'Please select delivery date and time first',    
            plsMeasureD: 'Please select measuremen date and time first',
            main: 'First Choice',
            minor: 'Second Choice',
            tip: 'If you need to change the date, please contact us',
            rename: 'Name of consignee',
            addConperTow: 'Add a Contact',
            checkConperTow: 'view contact',
            untime: 'Undetermined'
        },
        tabel: {
            location: 'Installation location',
            type: 'Product type',
            color: 'Product color',
            si: 'Product specifications'
        },
        prevBtn: 'Go Back',
        preBtn: 'Previous',
        placeBtn: 'Pay Now',
        editBtn: 'Change Order',
        paidBtn: 'Paid Already',
        editDone: 'Successfully modified!',
        totalCost: 'Total Cost',
        conPersonTow: 'Second Contact',
        opt: '(*optional）',
        clause: 'I have read and agree to abide by the ',
        clause2: 'Terms and conditions',
        clauseAlert: 'Please read and agree to abide by the terms and conditions',
        agree: 'Agree',
        refuse: 'Refuse'
    },
},
    // 会员中心
    member: {
        memberInfo: {
            title: 'memberInfo',
            name: 'Member name',
            phone: 'Contact number',
            email: ' E-mail',
            address: 'Default address',
            edit: 'Click edit',
            cpEdit: 'Confirm the changes'
        },
        orderList: {
            title: 'Order list',
            orderNumber: 'Order number',
            staus0: 'To be paid',
            step0: 'Waiting for measurement',
            step1: 'Waiting for delivery',
            step2: 'Waiting for installation',
            step3: 'Order completed',
            step4: 'Waiting for delivery and installation',
            step5: 'Confirmed measurement date',
            step6: 'Confirm delivery date',
            step7: 'Confirm installation date',
            step8: 'Confirm the date of delivery and installation',
            step9: 'order submitted',
            orderStatusL: 'Order status'
        },
        deleteOrder: 'Are you sure to delete the order?',
        confirm: 'Confirm',
        cancel: 'Cancel',
        deleteSu: 'Order deleted successfully!',
        loginout: 'Loginout',
        loginoutCon: 'Are you sure you want to log out?',
        getInfoErr: 'Failed to get user information',
        empty: 'No Order...'
    },
    // 登录
    login: {
        title: 'Member Login',
        forget: 'Forgot password',
        register: 'Register',
        login: 'Sign in',
        emailErr: 'Please enter the correct email format!',
        psdErr: 'Please enter the correct password',
        email: 'E-Mail',
        psd: 'Password'
    },
    // 忘记密码
    forgetPsd: {
        title: 'Retrieve password',
        forget: 'Forgot password',
        register: 'Register',
        email: 'Mail',
        login: 'Login',
        emailErr: 'Please enter your valid email',
        emailPls: 'Email/phone number',
        psdPls: 'Password',
        prve: 'Return',
        edit: 'Confirm the changes'
    },
    // 注册
    register: {
        title: 'Sign Up',
        tips: '*Thank you for using our service. Please fill in the information below so that we can process your order. If you already have an account, please click “Member Login” directly.',
        name: 'Name',
        phone: 'Cell-Phone',
        email: 'Mail',
        psd: 'Password',
        comfirmPsd: 'Confirm password',
        address: 'Receiving address',
        prevBtn: 'Previous Page',
        loginBtn: "Member Login",
        registerBtn: 'Sign up now',
        nameErr: 'Please fill in your name!',
        phoneErr: 'Please enter the correct phone format!',
        emailErr: 'Please enter the correct email format!',
        psdErr: 'Please fill in the password!',
        psdComfirmErr: 'The two passwords are inconsistent!',
        addressErr: 'Please enter the correct phone Receiving address!',
        emailAlert: {
            tips: 'We have sent an email to your mailbox. Please go to your mailbox to verify your identity.',
            cancel: 'Cancel',
            complate: 'I have confirmed'
        },
        plsEntryName: 'please fill in your name',
        psdLen: 'password length is between 6 and 20',
        psdAtypism: 'the two passwords are inconsistent',
        nameLen: 'The name is between 1 and 50 in length'
    },
    // 轮播图内容
    swiperShow: {
        banner: {
            detailsBtn: 'Learn more'
        },
        measure: {
            title: 'Free on-site measurment service',
            subTitle: 'Free on-site measuring ruler service',
            detailsBtn: 'Click to participate',
            ruleLabel: 'Exchange rules',
            numberLabel: 'Remaining Quot'
        },
        intellect: {
            title: 'Smart curtain'
        },
        productP: {
            title: 'Product advantages'
        },
        news: {
            title: 'Latest news'
        },
        consultion: {
            title: 'Free consultation',
            tips: 'You can contact us for more information about our products and we will get back to you as soon as possible.',
            name: 'Name',
            email: 'E-mail',
            phone: 'Telephone number',
            proposal: 'Your suggestion',
            btn: 'Contact us',
        }
    },
    // 产品
    product: {
        style: 'Product style',
        product: {
            detailsBtn: 'Learn more',
            backBtn: 'Return'
        }
    },
    // 橱窗
    window: {
        floorLabel: 'Property name',
        roomLabel: 'Room',
        btn: 'Confirm'
    },
    // 子页面内容
    subPage: {
        measure: {
            title: 'Free on-site measurment service',
            numberLabel: 'Remaining quota',
            timeLabel: 'Activity time',
            addressLabel: 'Redemption location',
            ruleLabel: 'Exchange rules',
            emailLabel: 'E-mail'
        },
        intellect: {
            title: 'Smart curtain',
            btn: "Learn more"
        },
        productP: {
            title: 'Product advantages',
        },
        fac: {
            program: {
                title: 'POWERVIEW® application',
                text: 'Smart home life prepared for you. Through the application, you can open and close the curtains with the flick of your finger; you can also set different scene modes and times, and the window decorations will automatically adjust on time, which is simple and easy to use.',
                download: 'Download the PowerView app',
                android: 'Android download',
                ios: 'IOS download'
            },
            room: {
                title: 'Full House Coverage',
                roomList: [
                    {
                        id: '0',
                        titleEn: 'PowerView® wireless extender',
                        title: 'PowerView® 無線伸延器',
                        descriptionEn: 'The small signal enhancer can strengthen the signal sent by the main control box to ensure that the commands issued by the main control box can be transmitted to the whole house smoothly.',
                        description: '細小的訊號加強器，可加強總控盒發出的訊號，確保總控盒發出的指令能順利傳送至全屋。'
                    },
                    {
                        id: '1',
                        titleEn: 'Pebble® and Surface scene system',
                        title: 'Pebble®及Surface場景制',
                        descriptionEn: 'With the use of PowerView® master control box and applications, you can quickly select and activate your preset scenes. Scene system can choose portable Pebble® or wall-mounted',
                        description: '配合PowerView®總控盒及應用程式使用，可快速選擇及啟用你已預設的場景。場景制可選擇手提式的Pebble®或安裝於牆身的'
                    },
                    {
                        id: '2',
                        titleEn: 'PowerView® App application',
                        title: 'PowerView® App 應用',
                        descriptionEn: 'The PowerView application gives full play to the advanced functions of this system. Through your mobile phone or tablet, you can operate single or whole house curtains, preset scenes and time at your fingertips',
                        description: 'PowerView應用程式將此系統的先進功能完全發揮。透過手機或平板電腦，彈指間即可操作單一或全屋窗簾、預設場景及時間，甚至利'
                    },
                    {
                        id: '3',
                        titleEn: 'Pebble® and Surface remote control',
                        title: 'Pebble®及Surface 遙控器',
                        descriptionEn: 'The remote control can be equipped with a portable shell or installed on the wall. The design is simple and easy to use. Just tap the remote control to control the opening and closing of the curtains and adjust the angle of the curtains. You can also control one or a group of curtains as needed.',
                        description: '遙控器可配手提式外殼或安裝於牆上，設計簡單易用，只需輕按遙控器，即可控制窗簾開合和調校簾片角度，更可按需要操控一幅或一組窗簾。'
                    },
                    {
                        id: '4',
                        titleEn: 'PowerView® Master Control Box',
                        title: 'PowerView® 總控盒',
                        descriptionEn: 'As the central control point of the whole house PowerView window decoration, the master control box will connect to the WiFi network to store the settings of each curtain and activate the scene mode to help you remotely control the whole house curtain.',
                        description: '作為全屋PowerView窗飾的中央控制點，總控盒會連接WiFi網絡，儲存各幅窗簾的設定及啟動場景模式，助你遙控全屋窗簾。'
                    },
                ]
            },
            combaination: {
                title: 'Master control box combination',
                text1: 'The newly designed master control box can be connected to the WiFi network at home, as the central control point of the PowerView window covering of the whole house, storing the settings of each curtain and starting the scene mode, helping you remotely',
                text2: 'The small signal enhancer can strengthen the signal sent by the main control box to ensure that the commands issued by the main control box can be transmitted to the whole house smoothly.',
                text3: 'Connect Network'
            },
            useInfo: {
                title: 'Instructions for use',
                text1: 'Read the instruction manual to help you easily set up PowerView window decorations at home. You can also watch a short video demonstration.Instructions for use: PowerView® Motorization Guides',
                text2: 'Instructions for use: PowerView® Motorization Guides',
                text3: 'Frequently Asked Questions: FAQ'
            },
            orther: {
                title: 'Compatible with other smart home systems',
                text1: 'Compatible with smart home system',
                text2: 'Compatible with other smart home systems, allowing users to enjoy an integrated high-quality smart home experience.',
                text3: 'Each smart home system has different integration methods. Please refer to the instructions and system requirements.',
                text4: 'smart life',
                text5: 'The intelligent electric system allows you to operate the curtains easily and conveniently through applications, remote controls, or even voice control * at any time.',
                btn: 'Compatible with other smart home systems, allowing users to enjoy an integrated high-quality smart home experience.'
            },
            curTain: {
                title: 'Buy Xinshui window decorations',
                text: 'Contact special dealers to experience the characteristics of various window decoration products and select the curtains that best fit your needs.',
                btn1: 'Address of authorized dealer',
                btn2: 'Powerview brochure'
            }
        },
        consultion: {
            plsName: 'Please type in your name',
            plsEmail: 'Please input your email',
            plsCEmail: 'Please enter your valid email',
            plsPhone: 'Please enter your phone number',
            plsCPhone: 'Please enter the correct phone number',
            plsIs: 'Please enter your suggestion',
            sendSu: 'Message sent successfully!'
        },
        contactus: {
            title: 'Shop Location',
            phone: 'Tel',
            fax: 'Fax'
        },
        pamphlet: {
            title: 'Pamphlets',
            subTitle: 'Pamphlets',
            download: 'Download'
        },
        maintain: {
            title: 'Maintenance',
            subTitle: 'Maintenance'
        },
        problem: {
            title: "FAQ",
            subTitle: "Common Problem"
        }
    },
    // 底部
    footer: [
        {
            id: '0',
            title: 'Contact Us',
            children: [
            {
                id: '0',
                text: 'Product'
            },
            {
                id: '1',
                text: 'About Us'
            },
            {
                id: '2',
                text: 'Management Team'
            },
            {
                id: '3',
                text: 'Sales  Agency'
            },
            {
                id: '4',
                text: 'Mission and Vision'
            }
            ]
        },
        {
        id: '1',
        title: 'Resources',
        children: [
            {
            id: '0',
            text: 'Hunter Douglas集團'
            },
            {
            id: '1',
            text: 'Hunter Douglas亞洲酒店'
            },
            {
            id: '2',
            text: 'Hunter Douglas建築公司'
            },
            {
            id: '3',
            text: '設計師團隊'
            }
        ]
        },
        {
        id: '2',
        title: 'Contact Us',
        children: [
                {
                id: '0',
                text: 'Hunter Douglas櫥窗時裝'
                },
                {
                id: '1',
                text: '電話：（852）26358399'
                },
                {
                id: '2',
                text: '傳真：（852）26378611'
                },
                {
                id: '3',
                text: '電子郵件：service_hk@hunterdoungias.asia'
                },
                {
                id: '4',
                text: '方針&地址：九龍旺角大宇工業大廈12樓1123室'
                }
            ]
        }
    ],
    // 提示
    tips: {
        notTime: "Please select a full date",
        pls: 'Please choose',
        to: 'Arrive',
        within: 'Within time',
        vifitySus: "Send the confirmation message successfully. Please confirm within half an hour",
        loginSus: "Sign in successfully",
        infoErr: "Failed to obtain user information",
        fristLogin: "Please login first",
        notFound: "No detailed information about this product",
        registerSus: "registration success",
        plsComplateDate: "Please select full date"
    },
    // 日期
    date: {
        plsTime: 'Please select the time interval',
        comfirm: 'Comfirm',
        year: '-',
        month: '',
        day: 'day',
        Mon: 'Mon',
        Tues: 'Tue',
        Wed: 'Wed',
        Thur: 'Thu',
        Fri: 'Fri',
        Sat: 'Sat',
        Sun: 'Sun'
    },
    // 搜索
    search: {
        pleaseEnter: 'Please enter keyWords',
        search: 'Search',
        guessYouLike: 'Hot Words',
        empty: 'empty...',
    }
}