<template>
    <div id="Pamphlet">
        <div class="banner top">
            <div class="floting">
                <div class="title">
                    <p>{{$t('subPage.pamphlet.title')}}</p>
                </div>
                <div class="bm"></div>
                <!-- <div class="subtitle">
                    <p class="text">{{$t('subPage.pamphlet.subTitle')}}</p>
                </div> -->
            </div>
        </div>
        <div class="warp pubw">
            <div class="bd blocks">
                <div class="item" v-for="item in pageData">
                     <img :src="`${baseUrl +  item.imgUrl}`" alt="">
                     <div class="floating">
                         <div class="button" @click="download(item.bookletFile)">
                            <div class="txt">{{$t('subPage.pamphlet.download')}}</div>
                            <div class="size">{{item.bookletFileSize}}</div>
                         </div>
                     </div>
                     <div class="bottom">
                        <div class="title">
                            {{item[_i18n.locale == 'en' ? 'mainTitleEn' : 'mainTitleCn']}}
                        </div>
                        <div class="subTiele">
                            {{item[_i18n.locale == 'en' ? 'subTitleEn' : 'subTitleCn']}}
                        </div>
                     </div>
                 </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pamphlet',
        data(){
            return {
                // 页面数据
                pageData: [],
            }
        },
        methods: {
            async getPageData(){
                const res = await this.$http.get('/web/booklet')
                this.pageData = res.data.tdBookletPage
                console.log(res, '小册子页面数据')
            },
            //   监听用户点击下载
            download(path){
                window.open(this.baseUrl + path)
            },
        },
        created() {
            this.getPageData()
        },
    }
</script>

<style lang="less">
#Pamphlet{
    overflow-x: hidden;
    padding-bottom: 150px;
     .banner.top{ 
        height: fit-content !important;
        width: fit-content;
        margin: 0 auto;
        margin-top: 80px;
        p{
            font-size: 44px;
        }
        .subtitle{
            p{
                font-size: 18px;
            }
        }
        .bm{
            width: 2.725rem;
            height: 0.1125rem;
            background: #ED8220;
            border-radius: 0.05rem;
            margin: 0.0625rem auto;
            margin-top: -15px;
        }
    }
      .warp{
          margin-top: 101px;
          .blocks {
              display: flex;
              flex-wrap: wrap;
              .item{
                  position: relative;
                  width: 32%;
                  height: 600px;
                  margin-bottom: 39px;
                  margin-right: 2%;
                  img{
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                  }
                  .floating{
                      display: flex;
                      position: absolute;
                      top: 0;
                      left: 0;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                      height: 100%;
                      transition: all .6s;
                      opacity: 0;
                      background: rgba(0, 0, 0, .8);
                      z-index: 10;
                      .button{
                          color: #fff;
                          width: 160px;
                          height: 70px;
                          border: 1px solid #FFFFFF;
                          border-radius: 20px;
                          .heffect;
                        *{
                            text-align: center;
                        }
                        .txt{
                            font-size: 24px;
                            margin-top: 7px;
                        }
                        .size{
                            font-size: 18px;
                        }
                      }
                  }
                  &:hover .floating{
                    opacity: 1;
                  }
                  .mask-active{
                    background: rgba(0, 0, 0, .5);
                  }
                  .bottom{
                    position: absolute;
                    top: 510px;
                    left: 0px;
                    z-index: 10;
                    color: #fff;
                    background: linear-gradient(rgba(0,0,0,.0),rgba(0,0,0,.4));
                    width: 100%;
                    bottom: 0;
                    .title{
                        position: relative;
                        font-size: 30px;
                        height: fit-content;
                        padding-left: 30px;
                        text-align: left;
                        &::before{
                            position: absolute;
                            left: 20px;
                            display: block;
                            content: "";
                            height: 80%;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 6px;
                            background-color: @colorMain;
                        }
                    }
                    .subTiele{
                        font-size: 18px;
                        color: #fff;
                        padding-left: 20px;
                    }
                  }
              }
              .item:nth-child(3n){
                  margin-right: 0;
              }
          }
      }
    }
</style>