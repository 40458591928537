<template>
    <div id="Header" :class="[showNav?'navActive':'',haedStatus?'light':'dark']">
        <!-- 容器 -->
        <div class="wrap pubw">
            <div class="aside">
                <div class="search">
                    <img :src="require(haedStatus?'./img/searchLight.png':'./img/searchDark.png')" alt="serach" @click="showSearch=true;showNav=false">
                </div>
                <div class="language">
                    <span class="" >{{langageData[currentLangage][isMobile ? 'mobileLabel' : 'label']}}</span>
                    <span class="iconfont icon-xiangxiajiantou"></span>
                    <ul class="select">
                        <li  v-for="(item, idx) in langageData"  :key="item.value" 
                        @click="changeLange(item, idx)" :class="[idx == currentLangage ?'active-color' : '']">
                            {{item[isMobile ? 'mobileLabel' : 'label']}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="logo" @click="$router.push('/')">
                <img src="./img/logoLight.png" v-show="haedStatus"/></img>
                <img src="./img/logoDark.png" v-show="!haedStatus"></img>
            </div>
            <div class="nav">
                <img v-if="!showNav" :class="showNav && !isMobile?'disabel':'normal'" :src="require(haedStatus?'./img/navLight.png':'./img/navDrak.png')" alt="nav" @click="handlerShowNav">
                <img v-if="isMobile && showNav" :src="require('./img/navActive.png')" alt="nav" @click="handlerShowNav" >
            </div>
        </div>
        <!-- 导航页面 -->
        <div id="Nav" @click.stop="handlerShowNav" ref="nav" v-show="haedStatus" v-if="!isMobile">
            <div class="mask"></div>
            <section class="content">
            <router-link class="shape6 special" :to="{path:'/SwiperShow', query:{page: 3}}" tag="div">{{$t('header.navBlock.productP')}}</router-link>
            <section class="other">
                <router-link class="shape6" :to="{path:'/SwiperShow', query:{page: 1}}" tag="div">{{$t('header.navBlock.freeMeasure')}}</router-link>
                <router-link class="shape6" :to="{path: '/SwiperShow', query: {page: 2}}" tag="div">{{$t('header.navBlock.intellect')}}</router-link>
            </section>
            <section class="other">
                <router-link class="shape6" :to="{path:'/SwiperShow', query:{page: 4}}" tag="div">{{$t('header.navBlock.news')}}</router-link>
                <router-link class="shape6" :to="{path:'/SwiperShow', query:{page: 5}}" tag="div">{{$t('header.navBlock.freeConsultion')}}</router-link>
            </section>
            </section>
        </div>
        <div class="nav-mask" ref="navMask" @click="handlerShowNav" v-if="showNav"></div>
        <!-- 右侧导航栏 -->
        <nav @click="showSearch && (showSearch = false)">
            <div class="hd" v-if="!isMobile">
                <img :src="require('./img/navActive.png')" alt="nav" @click="handlerShowNav">
            </div>
            <el-menu
                class="nav"
                default-active="2"
                :default-openeds="navActiveList"
                >
                <el-submenu v-for="(item, index) in navList" :key="index" :index="index + ''">
                    <template slot="title">
                        <router-link class="navItem" active-class="active" tag="li" :to='item.page' ><p @click="handlerShowNav">{{$t(item.name)}}</p></router-link>
                    </template>
                    <el-menu-item-group v-for="(subItem, index) in item.child" :key="index" v-if="subItem.display">
                        <span @click="subItem.handler" class="subItem">{{$t(subItem.name)}}</span>
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </nav>

        <!-- 搜索 -->
        <Search v-show="showSearch" :shutSearch="()=>{showSearch=false}"/>

        <!-- 退出登录弹窗 -->
        <div class="alret" v-show="showLoginout">
            <div class="container">
                <p class="tips">{{$t("member.loginoutCon")}}</p>
                <div class="options">
                    <div class="cancel btn" @click="showLoginout = false">
                        <p>{{$t("member.cancel")}}</p>
                    </div>
                    <div class="complate btnMain" @click="loginout">
                        <p>{{$t("member.confirm")}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Search from "./Search/index.vue"
export default {
  name: 'Header',
  components: {
    Search
  },
  data () {

    return {
        // 控制导航栏的展示
        showNav: false,
        // nav背景图片路径 
        bgImg:"/img/intellect.png",
        // 头部状态  1为亮 0为暗
        haedStatus: 1,
        // 控制搜索窗口的展示
        showSearch: false,
        // 当前语言
        currentLangage: 0,
        // 语言列表
        langageData:[
            {
                value: 'hk',
                label: '繁體中文',
                mobileLabel: '繁'
            }, {
                value: 'en',
                label: 'English',
                mobileLabel: 'En'
            }
        ],
        // 导航列表
        navList: [
            {
                name: 'header.navList.shop',
                page: '/Shop'
            },
            {
                name: 'header.navList.home',
                page: {name:'SwiperShow', query:{page: 0},params: {isH: true}},
            },
            {
                name: 'header.navList.product',
                page: this.isMobile ? '/Product' : '/ProductStyle',
            },
            {
                name: 'header.navList.window',
                page: '/Window'
            },
            {
                name: 'header.navList.contactus',
                page: '/Subpage/ContactUs'
            },
            {
                name: 'header.navList.member',
                page: '/Member',
                child: [
                    {
                        name: 'member.loginout',
                        handler: this.handlerLoginout,
                        display: window.sessionStorage.getItem('token')
                    }
                ]
            }
        ],
        // 是否展示退出登录弹窗
        showLoginout: false,
    }
  },
  computed: {
    navActiveList(){
        let arr = []
        for(let i in this.navList){
            arr.push(i)
        }

        return arr
    }
  },
  methods: {
    // 监听用户点击显示导航栏
    handlerShowNav () {
      this.showNav = !this.showNav
      if(this.showNav){
            this.navList[this.navList.length-1].child[0].display = window.sessionStorage.getItem('token')
            console.log( this.navList[this.navList.length-1].child[0].display)
      }
    },
    // 跳转
    goNavgation(path){
        this.handlerShowNav()
        this.$router.push(path)
    },
    // 处理页面模糊透明
    pageAction(value){
        let wrap = document.querySelector(' #app>.wrap')
    //  如果展示就让页面容器的孩子调整透明度以及模糊
        if(value){
                wrap.style.transition = "all .6s"
                wrap.style.opacity = "1"
                wrap.style.filter = "blur(0.1875rem)"
            // 背景遮罩
            // document.querySelector(" #Nav .mask").style.backgroundColor = "rgba(0, 0, 0, 0.4)"
            if(!this.isMobile){
                document.querySelector(" #Nav .mask").style.transition = "0"
            }
        }else{
            wrap.style.transition = "all .6s"
            wrap.style.opacity = "1"
            wrap.style.filter = "blur(0)"
            // 背景遮罩
            // document.querySelector(" #Nav .mask").style.backgroundColor = "rgba(0, 0, 0, 0.2)"
            if(!this.isMobile){
                document.querySelector(" #Nav .mask").style.transition = "0"
            }
        }
    },
    //   选择语言
    changeLange(value, idx){
        if(!value){
            let lang = window.localStorage.getItem('language')
            lang == 'en' ? lang = 'cn' : lang = 'en'
            window.localStorage.setItem('language', lang)
            this.$i18n.locale = lang
            return
        }
        this.currentLangage = idx
        window.localStorage.setItem("language",value.value)
        this.$i18n.locale = value.value
    },
    // 监听用户点击退出登录
    handlerLoginout(){
        this.showNav = false
        this.showLoginout = true
    },
    // 退出登录
    loginout(){
        window.sessionStorage.clear()
        this.$router.push('/login')
        this.showLoginout = false
    },
  },
  // 生命周期==>挂载
  mounted () {
    // 注册一个获取背景图片的事件
      this.$bus.$on("setNavBg",(src) => {
        this.bgImg = src
    })
    
    // 注册一个修改头部明暗状态的事件
    this.$bus.$on("setHeaderStatus",(value)=>{
        if(value === 1){
            console.log("有组件修改了头状态-",value)
        }
        this.haedStatus = value
    })
    this.$bus.$on()
    // 更新语言label
    this.langageData.forEach((item, idx) => {
        if(item.value == window.localStorage.getItem("language")){
            this.currentLangage = idx
        }
    })
  },
  watch:{
        //  监视路由
        $route(route){
            let name = route.name
            let bgImg =  process.env.BASE_URL + `bgImg/${name}.png`
            if(this.$refs.nav){
                this.$refs.nav.style.backgroundImage = `url(${bgImg})`
            }
        },
        //  监视控制展示nav的flag
        showNav(value){
            this.pageAction(value)
        },
        showSearch(value){
            this.pageAction(value)
        },
    }
}
</script>

<style lang="less" scoped>
    #Header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        color: #fff;
        z-index: 999;
        .wrap{
            position: absolute;
            width: 100%;
            padding: .125rem 1.25rem;
            top: .1875rem;
            height: .45rem;
            z-index: 999;
            .flexbc();
            .logo{
                    width: 3rem;
                    height: .49rem;
                    .heffect();
                img{
                    width: 3rem;
                    height: .49rem;
                    object-fit: contain;
                    
                }
            }
            .aside{
                width: 10%;
                .flexlc();
                .search{
                    margin-right: .25rem;
                    img{
                        width: .275rem;
                        height: .2875rem;
                        .heffect();
                    }
                }
                .language{
                    position: relative;
                    padding:0 .125rem;
                    .heffect();
                    .iconfont{
                        display: inline-block;
                        animation: arrowDown .6s forwards;
                        margin-left: .0625rem;
                    }
                    span{
                        font-size: .225rem;
                    }
                    .select{
                        position: absolute;
                        top: .25rem;
                        left: 0rem;
                        overflow: hidden;
                        transition: all 0.3s;
                        width: 100%;
                        padding:0 .125rem;
                        padding-top: .25rem;
                        height: 0rem;
                        li{
                            font-size: .225rem;
                            height: .375rem;
                        }
                    }
                    &:hover{
                        .iconfont{
                            animation: arrowUp .6s forwards;
                        }
                        .select{
                            height: fit-content;
                            line-height: .3125rem;
                            li{
                                .heffect();
                            }
                        }
                    }
                }
            }
            .nav{
                text-align: right;
                width: 20%;
                margin-top: .025rem;
                width: 10%;
                img{
                    width: .375rem;
                    height: 100%;
                    transition:all .6s;
                    font-size: .275rem;
                    &:hover{
                        .heffect();
                    }
                }
                .disabel{
                    display: none;
                    animation: show .6s forwards;
                }
                .normal{
                    animation: show 2.5s forwards;
                }
            }
        }
        nav{
            position: fixed;
            top: 0;
            right: 0rem;
            height: 100%;
            background: rgba(0,0,0,.3);
            width: 0rem;
            transition:all .6s;
            text-align: left;
            color: #fff;
            z-index: 998;
            .hd{
                margin-left: .25rem;
                margin-top: .3rem;
                img{
                    color: @colorMain;
                    .heffect();
                }
            }
            .nav{
                margin-top: .625rem;
                li{
                    margin-bottom: .25rem;
                    p{
                        .heffect();
                        font-size: .25rem;
                        height: .875rem;
                        line-height: .875rem;
                        width: 100%;
                        height: 100%;
                        text-align: left;
                        font-family: "SourceHanSansCN-Light";
                        margin-left: 18px;
                    }
                }
                .active{
                    border-left: .05rem solid @colorMain;
                    color: @colorMain;
                    p{
                        margin-left: 14px;
                        font-family: "SourceHanSansCN-Medium";
                    }
                }
            }
        }
        .el-menu{
            background: none;
            overflow: hidden;
            border: none;
            .navItem{
                color:  #fff;
            }
            /deep/.el-submenu__title{
                padding-left: 20px !important;
            }
            /deep/.el-submenu__title:hover{
                background: none;
            }
            /deep/.el-submenu__icon-arrow{
                display: none;
            }
            /deep/.el-menu{
                width: 100%;
                background: none;
                /* margin-left: 10px; */
                overflow: hidden;
                padding: 0 0.25rem;
                padding-left: 20px;
                display: flex !important;
                .el-menu-item-group{
                    width: 100%;
                    margin-left: 0.25rem;
                }
                .el-menu-item-group__title{
                    padding-left: 0px !important;
                    margin-left: 14px;
                }
                span{
                    color: #fff;
                    .heffect;
                    font-size: 18px;
                }
            }
        }
        #Nav {
            position: fixed;
            width: 0;
            height: 0rem  ;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            opacity: 0;
            z-index: 0;
            overflow: hidden;
            .flexcc();
            z-index: 10;
            .mask{
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0, 20%);
            }
            .content {
                position: absolute;
                width: 50%;
                height: 50%;
                margin-top: -1.25rem;
                .other {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-bottom: .225rem;

                    .shape6:first-child {
                        margin-right: 2.6125rem;
                    }
                }

                .special {
                    margin: 0 auto;
                    position: absolute;
                    left: 50%;
                    top: 2.12rem;
                    transform: translateX(-50%);
                }
            }
            .shape6 {
                position: relative;
                background-image: url("./img/shape6.png");
                background-position: center center;
                background-size: 100%;
                background-repeat: no-repeat;
                width: 4.6625rem;
                height: 4.0375rem;
                color: #fff;
                font-size: .5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-family: 'SourceHanSansCN-Medium';
            }

         }
         .nav-mask{
             position: fixed;
             width: 100%;
             height: 100%;
             top: 0;
             left: 0;
         }
    }
    #Header.navActive{
        width: 100%;
        padding: 0;
        font-size: .1875rem;
        nav{
            width: 3.75rem;
        }
        #Nav{
            width: 100%;
            height: 100%;
            animation: gradual .6s forwards;
        }
        .wrap{
        }
    }
    /* 光 */
    #Header.light{
        color: #fff;
    }
    /* 暗 */
    #Header.dark{
        position: fixed;
        top: 0;
        color: #000 ;
        height: 80px;
        background-color: #fff;
        .wrap{
            position: relative;
            height: 100%;
            top: 0;
        }
    }
    /* 渐变动画 */
    @keyframes gradual{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
</style>
