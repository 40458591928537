function formatDate(date){
    date = String(date).replace(/-/g,"/")
    date = new Date(date)
    const opt = {
        "date": date.getFullYear().toString() +'-'+ (date.getMonth() + 1).toString()  +'-'+ date.getDate().toString(),
        "spritDate": date.getFullYear().toString() +'/'+ (date.getMonth() + 1).toString()  +'/'+ date.getDate().toString(),
        "time": date.getHours().toString()  +':'+ (date.getMinutes().toString().length == 1? "0" + date.getMinutes().toString() : date.getMinutes().toString()), 
        'complate':date.getFullYear().toString() +'/'+ (date.getMonth() + 1).toString()  +'/'+ date.getDate().toString()+'/'+ date.getHours().toString()  +'/'+ date.getMinutes().toString()  +'/'+ date.getSeconds().toString(),
        'spriteDate':date.getFullYear().toString() +'-'+ (date.getMonth() + 1).toString()  +'-'+ date.getDate().toString()+' '+ date.getHours().toString()  +':'+ date.getMinutes().toString()  +':'+ date.getSeconds().toString()
    };
    return opt
}

// 计算+n个月的时间
formatDate.addMonth = function(date, months) {
    var datearr = date.split("/");
    var y = parseInt(datearr[0]);
    var m = parseInt(datearr[1][0] == 0 ? datearr[1][1] : datearr[1]) - 1;
    var d = parseInt(datearr[2][0] == 0 ? datearr[2][1] : datearr[2]);

    y += Math.floor((m + months) / 12); //计算年
    m = Math.floor((m + months) % 12) + 1; //计算月
    var d_max = new Date(y + "/" + (m + 1) + "/0").getDate();  //获取计算后的月的最大天数
    if (d > d_max) {
        d = d_max;
    }
    return (y + "/" + (m < 10 ? ("0" + m) : m) + "/" + (d < 10 ? ("0" + d) : d));
}

// 计算+n小时的时间
formatDate.addHour = function(time, hour){
    
}

export default formatDate
