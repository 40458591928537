<template>
    <div id="ProductStyle" :style="`background-image: url( ${bgImg});`">
          <!-- 容器 -->
          <div class="wrap pubw">
            <!-- 头部 -->
            <div class="hd">
                <h1 class="title">{{$t('product.style')}}</h1>
            </div>
        </div>
        <!-- 主体 -->
        <div class="bd">
            <swiper :options="swiperOption" ref="producrSwiper">
                　<swiper-slide v-for="(item,index) in pageData" :key="index">
                    <img :src="`${baseUrl + item.productDisplayImg}`" alt="" @click="goProduct(item,index)">
                    <div class="title">
                        <span>{{item[_i18n.locale == 'en' ? 'productTypeEn' : 'productTypeCn']}}</span>
                    </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev" @click="$refs.producrSwiper.$swiper.slidePrev() "></div>
                <div class="swiper-button-next" slot="button-next" @click="$refs.producrSwiper.$swiper.slideNext() "></div>
            </swiper>
        </div>
        
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProductStyle',
    components: {
    },
    data(){
      return {
        //   轮播图配置
        swiperOption: {
            slidesPerView: 'auto',
            centeredSlides: true,
            freeMode : true,//是否滑动
            paginationClickable: true,
            spaceBetween: 50,//间距
            observer:true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents:true//修改swiper的父元素时，自动初始化swiper
        },
        // 背景图片
        bgImg: process.env.BASE_URL + "bgImg/freeConsul.png",
        // 页面数据
        pageData: {},
      }
    },
    methods: {
        // 获取页面数据
        async getPageData(){
            const res = await this.$http.get("/web/product/display")
            console.log("产品页面数据",res)
            this.pageData = res.data
        },
        // 跳转产品页面
        goProduct(item,index){
            if(!item.productDisplayVideo && !item.webProductDescrips.length){
                return this.$message.info(this.$t('tips.notFound'))
            }
            this.$router.push({
                name: 'Product',
                params: {product: item, index}
            })
        }
    },
    // 生命周期==》挂载wancheng
    mounted() {
        this.getPageData()
    },

  }
  </script>
  
  <style lang="less">
    #ProductStyle{
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        overflow: hidden;
        .wrap{
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            text-align: left;
            height: 1rem;
            margin-bottom: .75rem;
            .hd{
                text-align: center;
                border: none;
                .title{
                    font-size: .6rem;
                }
            }

        }
        .bd{
            width: 100%;
            .swiper-container{
                width: 100%;
                height: 100%;
                .swiper-button-next{
                    right: 20px;
                    margin-top: -34px;
                }
                .swiper-button-prev{
                    left: 20px;
                    margin-top: -34px;
                }
                .swiper-wrapper{
                    .swiper-slide{
                        width: 7.175rem;
                        margin-right: 60px;
                        img{
                            width: 100%;
                            height: 5.75rem;
                            border-radius: .375rem;
                            .heffect();
                        }
                        .title{
                            margin-top: .375rem;
                            span{
                                margin: 20px auto;
                                font-size: .45rem;
                                font-family: 'SourceHanSansCN-Medium';
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

    }
  </style>
  