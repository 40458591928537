<template>
    <div id="measureDetails">
        <div class="banner">
            <div class="imgMask"></div>
            <img src="../../../assets/imgs/details.png" alt="">
            <!-- <img :src="`${baseUrl+pageData.pageDetailsImg}`" alt=""> -->
            <div class="floting">
                <div class="title">
                    <p>{{$t('subPage.measure.title')}}</p>
                </div>
                <div class="subtitle">
                    <p class="text">Free on-site measuring ruler service</p>
                </div>
            </div>
        </div>
        <div class="content pubw">
            <div class="title">
                <p>{{pageData.labelCn}}</p>
            </div>
            <div class="number">
                <p>{{$t('subPage.measure.numberLabel')}}: <span class="number" v-html="pageData[_i18n.locale == 'en' ? 'remainingPlacesEn' : 'remainingPlacesCn']"></span></p>
            </div>
            <div class="activeitydate">
                <p>{{$t('subPage.measure.timeLabel')}}：{{pageData[_i18n.locale == 'en' ? 'activityTimeEn' : 'activityTimeCn']}}</p>
            </div>
            <div class="address">
                <p>{{$t('subPage.measure.addressLabel')}}：</p>
                <div class="addressList">
                    <div class="item" v-for="(item, index) in pageData.redempAddress" :key="index">
                        <div class="left">
                            <p>{{item.name}}</p>
                            <p class="ads">{{item.address}}</p>
                        </div>
                        <div class="icon">
                            <img src="../../../assets/imgs/searchBrown.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="rule">
                <p class="h">{{$t('subPage.measure.ruleLabel')}}：</p>
                <div class="cent">
                    <div v-html="pageData[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
                </div>
            </div>
            <div class="email">
                <p>{{$t('subPage.measure.emailLabel')}}： <span class="numL">{{pageData.email}}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'measureDetails',
        data(){
            return {
                // 页面数据
                pageData: {},
            }
        },
        methods: {
            // 获取页面数据
            async getPageData(){
                let res = await this.$http.get("/web/measure/page")
                return res.data
            },
        },
        async mounted() {
            // 接收页面数据
            this.pageData = this.$route.params.pageData || JSON.parse(window.sessionStorage.getItem('MeasureDetailsPageData'))
            if(!this.pageData){
                this.pageData = await this.getPageData()
            }
            this.pageData.redempAddress = eval(this.pageData.redempAddress)
            window.sessionStorage.setItem('MeasureDetailsPageData',JSON.stringify(this.pageData))

        },
    }
</script>

<style lang="less">
    #measureDetails{
        color: #000;
        padding-bottom: 150px;
        .banner{
            position: relative;
            width: 100%;
            height: 600px;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
            .floting{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                .title{
                    p{
                        font-size: 1.175rem;
                    }
                }
                .subtitle{
                    font-family: 'Roboto-Light';
                    p{
                        font-family: 'Roboto-Light';
                        font-size: .45rem;
                    }
                }
            }
        }
        .content{
            padding: .625rem 0;
            line-height: .625rem;
            p{
                text-align: left;
                font-size: .3rem;
                font-weight: 500;
            }
            .address{
                .addressList{
                    padding-left: 10%;
                    .item{
                        padding: .1875rem .125rem;
                        border-bottom: .0125rem solid rgba(233, 233, 233, .5);
                        .flexbc();
                        .left{
                            display: flex;
                            justify-content: space-between;
                            width: 70%;
                        }
                        .ads{
                            font-size: .225rem;
                            color: rgba(51, 51, 51, 1);
                            font-family: 'SourceHanSansCN-Light';
                        }
                        .icon{
                            font-size: .2875rem;
                            color: @colorMain;
                            .heffect();
                        }
                    }
                }
            }
            .number{
                .number{
                    font-size: .3rem;
                    span{
                        color: @colorMain;
                        margin-right: .0625rem;
                        font-size: .3rem;
                        font-family: 'D-DIN-Bold';
                    }
                }
            }
            .rule{
                .cent{
                    padding-left: 10%;
                    *{
                        font-family: 'SourceHanSansCN-Light';
                    }
                }
            }
            .email{
                span{
                    font-size: .3rem;
                }
            }
        }
        .content>div{
            line-height: .4375rem;
            margin-bottom: .4375rem;
        }
    }
</style>