<template>
  <div id="ProductP">
    <div class="banner" id="banner">
      <div class="imgMask"></div>
      <img src="../../../../public/bgImg/details.png" alt="">
      <div class="floting">
          <div class="title">
              <p>{{$t('subPage.productP.title')}}</p>
          </div>
          <div class="subtitle">
              <p class="text">Product advantages</p>
          </div>
      </div>
  </div>
      <!-- 容器 -->
      <div class="wrap pubw">
          <!-- 头部 -->
          <!-- <div class="hd">
              <h1 class="title">產品優點</h1>
              <div class="bm"></div>
              <div class="subtitle">Product advantages</div>
          </div> -->

          <!-- 主体 -->
          <div class="bd">
              <!-- 瀑布流容器 -->
              <div class="container" ref="container">
                  <div class="block" v-for="item in pageData" :key="item.meritPageId">
                    <h2>{{item[_i18n.locale == 'en' ? 'labelEn' : 'labelCn']}}</h2>
                    <div class="img">
                      <img :src="`${baseUrl + item.pageImg}`"alt="">
                    </div>
                    <div class="txt">
                        <div class="text" v-html="item[_i18n.locale == 'en' ? 'contentEn' : 'contentCn']"></div>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
name: 'ProductP',
data () {
  return {
    // 页面数据
    pageData: [],
  
  }
},
methods: {
  
},
// 生命周期==>挂载完成
  mounted () {


      // 接收页面数据
        const pageData = this.$route.params.pageData || JSON.parse(window.sessionStorage.getItem('ProductPPageData'))
        
        window.sessionStorage.setItem('ProductPPageData',JSON.stringify(pageData))
        this.pageData = pageData
  },
  // 生命周期销毁了
  beforeDestroy() {
  },
}
</script>

<style lang="less">
  #ProductP{
    padding-bottom: 150px;
    overflow-x: hidden;
      .banner{
            /* position: absolute; */
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .floting{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
                .title{
                    p{
                        font-size: 1.175rem;
                    }
                }
                .subtitle{
                    p{
                        font-size: .45rem;
                        
                    }
                }
            }
        }
      .wrap{
          .hd{
              text-align: center;
              .title{
                  font-size: .6rem;
                  color: #000;
              }
              .bm{
                  width: 2.725rem;
                  height: .1125rem;
                  background: @colorMain;
                  border-radius: .05rem;
                  margin: .0625rem auto;
                  margin-top:-0.1rem;
              }
              .subtitle{
                  font-size: .225rem;
                  font-weight: 400;
                  color: rgba(179, 179, 179, 1);
              }
          }
          .bd{
              margin-top:1.25rem;
              .container{
                  .flexb();
                    width: 100%;
                      .block{
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        margin-bottom: 1rem;
                        .img{
                              width: 100%;
                              position: relative;
                          img{
                              width: 100%;
                          }
                        }
                        h2{
                            font-size: .3rem;
                            padding-left: .25rem;
                            border-left: .0625rem solid @colorMain;
                            margin-bottom: .25rem;
                            height: .3rem;
                            line-height: .3rem;
                            text-align: left;
                            margin-top: 30px;
                        }
                        .txt{
                            position: relative;
                            text-align: left;
                            color: #000;
                            .text{
                                *{
                                  margin-top: .1875rem !important;
                                  line-height: .4375rem  !important;
                                  font-size: .225rem  !important;
                                  color: #666666 !important;
                                  text-align: justify  !important;
                                  font-family: "Roboto-Light"  !important;
                                }
                            }
                        }
                  }
              }
          }
      }
  }
</style>
